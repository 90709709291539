import { Box } from '@mui/material'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

export function TestEmbedPage() {
  const { esppId } = useParams()

  const BASE_URL = `${window.location.protocol}//${window.location.host}`

  useEffect(() => {
    // Function to handle the message event
    function handleMessage(event) {
      console.log({ event, data: event.data, type: event.data.type })

      // Check if the event data has the type 'REDIRECT'
      if (event.data.type === 'REDIRECT') {
        const url = event.data.url

        // Perform the redirection to the desired route
        window.location.href = `${BASE_URL}/${url}`
      }
    }

    // Add the event listener
    window.addEventListener('message', handleMessage)

    // Cleanup function to remove the event listener when the component unmounts
    return () => window.removeEventListener('message', handleMessage)
  }, [])

  return (
    <Box sx={{ display: 'flex', height: '100vh', flexDirection: 'column' }}>
      <h1>Test Embeddable Calculator</h1>
      <iframe
        src={`${BASE_URL}/embeddable-calculator/${esppId}`}
        width='100%'
        height='100%'
        style={{ flexGrow: 1 }}
      ></iframe>
    </Box>
  )
}
