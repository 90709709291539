import { useEffect } from 'react'
import { Location, useLocation } from 'react-router-dom'
import { getCookie } from '../helpers/cookieHelper'
import { bennyApi } from '../utils/bennyApi'
import { getIPAddress } from '../utils/getIPAddress'

interface UTMParams {
  [key: string]: string | undefined
}

const constructUrl = (location: Location): string => {
  const { pathname, search } = location
  return `${process.env.REACT_APP_FRONTEND_URL}${pathname}${search}`
}

const extractUtms = (search: string): UTMParams => {
  const searchParams = new URLSearchParams(search)
  const params = {}

  for (const [key, value] of searchParams) {
    params[key] = value
  }

  return params
}

const extractApplicationId = (pathname: string): string | null => {
  const applicationIdMatch = pathname.match(/\/application\/([0-9a-fA-F-]+)/)

  if (applicationIdMatch && applicationIdMatch.length > 1) {
    return applicationIdMatch[1]
  }

  return null
}

const sendEvent = async ({
  event_type,
  url,
  utms,
  ip_address,
  customer_id,
  application_id,
  event_name,
}: {
  event_type: 'navigation' | 'click'
  url: string
  utms: UTMParams
  ip_address: string
  customer_id: string | undefined
  application_id: string | null
  event_name: string
}) => {
  try {
    await bennyApi.post('/espp/create-event/', {
      event_type,
      event_name,
      utms,
      customer_id,
      application_id,
      url,
      ip_address,
      timestamp: new Date(),
    })
  } catch (error) {
    console.warn('Error sending navigation event: ', error)
  }
}

export const useEventNavigation = (): void => {
  const location = useLocation()

  useEffect(() => {
    const createEvent = async () => {
      const url = constructUrl(location)
      const utms = extractUtms(location.search)
      const ip_address = await getIPAddress()
      const customer_id = getCookie('customerId')
      const application_id = extractApplicationId(location.pathname)
      const event_name = location.pathname

      sendEvent({
        event_type: 'navigation',
        event_name,
        application_id,
        customer_id,
        url,
        utms,
        ip_address,
      })
    }

    createEvent()
  }, [location])
}

interface EventClickHook {
  createEvent: ({ name }: { name: string }) => Promise<void>
}

export const useEventClick = (): EventClickHook => {
  const location = useLocation()

  const createEvent = async ({ name }: { name: string }) => {
    const url = constructUrl(location)
    const utms = extractUtms(location.search)
    const ip_address = await getIPAddress()

    sendEvent({
      event_type: 'click',
      url,
      utms,
      ip_address,
      event_name: name,
      customer_id: getCookie('customerId'),
      application_id: null,
    })
  }

  return { createEvent }
}
