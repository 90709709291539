import { Box, Button, Container, Grid, Snackbar } from '@mui/material'
import { Typography } from 'Components/Typography'
import { useState } from 'react'
import { formatToDollars } from 'utils/commons'
import { ICustomerApplications } from '../useGetCustomerApplications'

interface ReferralProps {
  referralCode: ICustomerApplications['referral_code']
}

export const Referral = ({ referralCode }: ReferralProps) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false)

  if (!referralCode?.active) return null

  const copyReferralLinkToClipboard = () => {
    const referralLink = `https://heybenny.com/refer/${referralCode?.code}`
    navigator.clipboard.writeText(referralLink).then(
      () => {
        console.log('Referral link copied to clipboard!')
        setSnackbarOpen(true)
      },
      (err) => {
        console.error('Could not copy referral link: ', err)
      },
    )
  }

  return (
    <Grid container mb={{ xs: '20px', md: '64px' }}>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        message='Referral code copied successfully!'
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      />
      <Container maxWidth='xl'>
        <Grid container gap={'12px'}>
          <Typography inheritColor variant='h2'>
            Earn more with Referrals
          </Typography>

          <Grid container gap={'12px'}>
            <Box maxWidth={'322px'}>
              <Typography inheritColor>
                Did you know you can earn{' '}
                <strong>a {formatToDollars(referralCode?.sender_benefit)} gift card</strong> for
                every person you refer who uses Benny’s service?
              </Typography>
            </Box>

            <Box
              maxWidth={'400px'}
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'center'}
              gap={'12px'}
            >
              <Typography inheritColor>
                Simply share your referral code of <strong>{referralCode?.code}</strong> or send
                them this link:
              </Typography>

              <Box display={{ xs: 'none', md: 'block' }}>
                <Button
                  onClick={copyReferralLinkToClipboard}
                  disableRipple
                  sx={{
                    justifyContent: 'flex-start',
                    padding: 0,
                    minWidth: 0,
                    '&:hover': {
                      backgroundColor: 'transparent',
                      boxShadow: 'none',
                    },
                    '&:active': {
                      backgroundColor: 'transparent',
                      boxShadow: 'none',
                    },
                    '&:focus': {
                      outline: 'none',
                    },
                  }}
                  endIcon={
                    <svg width='17' height='17' viewBox='0 0 17 17' fill='none'>
                      <path
                        d='M11.6875 5.3125V7.96875C11.6875 10.625 10.625 11.6875 7.96875 11.6875H4.78125C2.125 11.6875 1.0625 10.625 1.0625 7.96875V4.78125C1.0625 2.125 2.125 1.0625 4.78125 1.0625H7.4375'
                        stroke='#A7A7A7'
                        stroke-width='1.5'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                      />
                      <path
                        d='M11.6875 5.3125H9.5625C7.96875 5.3125 7.4375 4.78125 7.4375 3.1875V1.0625L11.6875 5.3125Z'
                        stroke='#A7A7A7'
                        stroke-width='1.5'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                      />
                      <path
                        d='M15.9375 8.14575V10.802C15.9375 13.4583 14.875 14.5208 12.2188 14.5208H9.03125C6.375 14.5208 5.3125 13.4583 5.3125 10.802V7.6145C5.3125 4.95825 6.375 3.89575 9.03125 3.89575H11.6875'
                        fill='white'
                      />
                      <path
                        d='M15.9375 8.14575V10.802C15.9375 13.4583 14.875 14.5208 12.2188 14.5208H9.03125C6.375 14.5208 5.3125 13.4583 5.3125 10.802V7.6145C5.3125 4.95825 6.375 3.89575 9.03125 3.89575H11.6875'
                        stroke='#9747FF'
                        stroke-width='1.5'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                      />
                      <path
                        d='M15.9375 8.14575H13.8125C12.2188 8.14575 11.6875 7.6145 11.6875 6.02075V3.89575L15.9375 8.14575Z'
                        fill='white'
                        stroke='#9747FF'
                        stroke-width='1.5'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                      />
                    </svg>
                  }
                >
                  <Typography
                    sx={{
                      color: '#9747FF',
                    }}
                  >
                    {`https://heybenny.com/refer/${referralCode?.code}`}
                  </Typography>
                </Button>
              </Box>

              <Box display={{ xs: 'block', md: 'none' }}>
                <Typography
                  sx={{
                    color: '#9747FF',
                  }}
                >
                  {`https://heybenny.com/refer/${referralCode?.code}`}
                </Typography>
              </Box>

              <Button
                onClick={copyReferralLinkToClipboard}
                variant='contained'
                sx={{ display: { xs: 'block', md: 'none' } }}
              >
                Copy Referral Link
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  )
}
