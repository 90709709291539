import { Box, Grid } from '@mui/material'
import { Typography } from 'Components/Typography'

interface WelcomeProps {
  name: string
  email: string
}

export const Welcome = ({ name, email }: WelcomeProps) => {
  return (
    <Grid bgcolor={'#194339'}>
      <Box
        display={'flex'}
        flexDirection={{ xs: 'column', md: 'row' }}
        maxWidth={'1200px'}
        mx={'auto'}
        py={{ xs: '20px', md: '64px' }}
        px={'20px'}
        pb={{ xs: '20px', md: '104px' }}
        justifyContent={'space-between'}
        gap={{ xs: '10px', md: 0 }}
      >
        <Box
          display={'flex'}
          flexDirection={'column'}
          gap={'10px'}
          maxWidth={'650px'}
          order={{ xs: 1, md: 0 }}
        >
          <Typography variant='h3' inheritColor sx={{ color: '#F6FBF9' }}>
            Welcome {name ?? email},
          </Typography>

          <Typography inheritColor sx={{ color: '#F6FBF9' }}>
            We are excited to be a part of your ESPP journey. Below, you'll find your loan details
            supporting your ESPP participation.
          </Typography>
        </Box>

        {/* <Box>
          <Typography inheritColor sx={{ color: '#F6FBF9', opacity: 0.5 }}>
            Account Number: C973803
          </Typography>
        </Box> */}
      </Box>
    </Grid>
  )
}
