import { Typography, TypographyProps } from '@mui/material'
import React from 'react'

interface GradientTypographyProps extends TypographyProps {}

export const GradientTypography: React.FC<GradientTypographyProps> = (props) => {
  return (
    <Typography
      {...props}
      sx={{
        background: 'var(--Gradient-main, linear-gradient(282deg, #581BBB -0.5%, #0CA671 98.84%))',
        WebkitBackgroundClip: 'text',
        backgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        fontFamily: 'Garnett-bold',
        fontSize: { xs: '28px', md: '40px' },
        fontStyle: 'normal',
        fontWeight: 750,
        lineHeight: { xs: '45px', md: '140%' },
        ...props.sx,
      }}
    >
      {props.children}
    </Typography>
  )
}
