import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactNode, useEffect, useState } from 'react'
import ReactGA from 'react-ga'
import TagManager from 'react-gtm-module'
import { hotjar } from 'react-hotjar'
import { connect } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import './App.css'
import {
  checkAuthenticated as checkAuthenticatedAction,
  load_user as loadUserAction,
} from './Components/actions/auth'
import { ScrollToTop } from './Components/scrollToTop'
import { VisitTracker } from './Components/visitTraacker'
import { getCookie, setCookie } from './helpers/cookieHelper'

const queryClient = new QueryClient()

interface AppProps {
  checkAuthenticated: () => void
  load_user: () => void
  children: ReactNode
}

function App({ checkAuthenticated, load_user, children }: AppProps) {
  const [isCustomerIdSet, setIsCustomerIdSet] = useState<boolean>(false)

  useEffect(() => {
    checkAuthenticated()
    load_user()
  }, [])

  useEffect(() => {
    const tagManagerArgs = {
      gtmId: process.env.REACT_APP_GTM_ID,
    }

    if (process.env.REACT_APP_MODE !== 'Test') {
      ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID)
      TagManager.initialize(tagManagerArgs)
      hotjar.initialize(
        Number(process.env.REACT_APP_HOTJAR_ID),
        Number(process.env.REACT_APP_HOTJAR_SV),
      )
    }
  }, [])

  useEffect(() => {
    let customerId = getCookie('customerId')

    if (!customerId) {
      customerId = uuidv4()
      setCookie('customerId', customerId, 365)
    }
    setIsCustomerIdSet(true)
  }, [])

  return (
    <QueryClientProvider client={queryClient}>
      <ScrollToTop />
      <div className='App'>{isCustomerIdSet ? <VisitTracker>{children}</VisitTracker> : null}</div>
    </QueryClientProvider>
  )
}

export default connect(null, {
  checkAuthenticated: checkAuthenticatedAction,
  load_user: loadUserAction,
})(App)
