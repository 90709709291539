import * as mui from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import React from 'react'
import { Helmet } from 'react-helmet'

import Header from '../../Components/common/header'
import Footer from '../../Components/common/Footer'
import { EsppCalculator } from './EsppCalculator'
import { TaxesCalculator } from './TaxesCalculator'


function TabPanel(props: {
  children?: React.ReactNode
  index: number
  value: number
}) {
  return (
    <div
      role="tabpanel"
      hidden={props.value !== props.index}
      id={`simple-tabpanel-${props.index}`}
      aria-labelledby={`simple-tab-${props.index}`}
    >
      {props.value === props.index && (
        <mui.Box sx={{ p: 3 }}>
          {props.children}
        </mui.Box>
      )}
    </div>
  );
}


export function Calculator() {
  const [currentTab, setCurrentTab] = React.useState(0)

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Helmet>
        <link rel='canonical' href='https://heybenny.com/apply'/>
      </Helmet>
      <Header/>
      <mui.Box sx={{borderBottom: 1, borderColor: 'divider'}}>
        <mui.Tabs value={currentTab} onChange={(e, v) => setCurrentTab(v)} centered>
          <mui.Tab label="Contributions" id="simple-tab-0" aria-controls="simple-tabpanel-0"/>
          <mui.Tab label="Taxes" id="simple-tab-1" aria-controls="simple-tabpanel-1"/>
        </mui.Tabs>
      </mui.Box>
      <mui.Container
        sx={{minHeight: '100vh', pt: 6, pb: 10}}
        maxWidth="md"
      >
        <TabPanel value={currentTab} index={0}>
          <mui.Grid container item spacing={4}>
            <EsppCalculator/>
          </mui.Grid>
        </TabPanel>
        <TabPanel value={currentTab} index={1}>
          <mui.Grid container item spacing={4}>
            <TaxesCalculator/>
          </mui.Grid>
        </TabPanel>
      </mui.Container>
      <Footer/>
    </LocalizationProvider>
  )
}