import EastIcon from '@mui/icons-material/East'
import * as mui from '@mui/material'
import { useTheme } from '@mui/material/styles'
import axios from 'axios'
import { Crypt } from 'hybrid-crypto-js'
import * as React from 'react'
import { Helmet } from 'react-helmet'
import NumberFormat from 'react-number-format'
import { useNavigate, useSearchParams } from 'react-router-dom'

import 'swiper/css'
import 'swiper/css/effect-fade'
import 'swiper/css/pagination'

import Footer from '../../../Components/common/Footer'
import Header from '../../../Components/common/header'
import { Wizard, WizardRefMethods } from '../../../Components/Wizard'
import { getCookie } from '../../../helpers/cookieHelper'
import { useEventClick } from '../../../hooks/tracker'
import { formatToDollars, monthDescriptions } from '../../../utils/commons'

import { PopupModal } from 'react-calendly'
import { CustomButton } from '../../../Components/CustomButton'
import { bennyApi } from '../../../utils/bennyApi'
import { DiscoverEsppScreen } from './components/DiscoverEsppScreen'
import './newapplication.css'

const publicKey = `-----BEGIN PUBLIC KEY-----
MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCfm2uMTvb+gqXRFxWHnfCKcHfH
v7aMN6oiEqTJj0BixtTYBXH89N+xuYgoIBnfMXPXPIg/UNWEOZtAETsOVvya+YBo
ZZTquYJ2I0PaxtpUKkpCiEQ/bTCQIDAeUwHr0l4vUn/fmslD0rZ3+jo4Dsl8nX0O
pwZQ2grQaXLb347RQwIDAQAB
-----END PUBLIC KEY-----`

const filter = mui.createFilterOptions({ limit: 5 })

function NewApplicationForm() {
  const employerQuery = new URLSearchParams(window.location.search).get('employer')
  const [showCalendly, setShowcalendly] = React.useState<boolean>(false)
  const [showDiscover, setShowDiscover] = React.useState(true)
  const [initialStep, setInitialStep] = React.useState(0)

  const [annualIncome, setAnnualIncome] = React.useState('')
  const [codeBenefit, setCodeBenefit] = React.useState(null)
  const [codeFound, setCodeFound] = React.useState('')
  const [companies, setCompanies] = React.useState([])
  const [contribution, setContribution] = React.useState(10)
  const [disabledStatus, setDisabledStatus] = React.useState(true)
  const [dollarLimit, setDollarLimit] = React.useState(0)
  const [email, setEmail] = React.useState('')
  const [emailError, setEmailError] = React.useState(false)
  const [emailHelper, setEmailHelper] = React.useState('')
  const [employer, setEmployer] = React.useState(employerQuery || '')
  const [employerDisplay, setEmployerDisplay] = React.useState(false)
  const [employerOpen, setEmployerOpen] = React.useState(false)
  const [espp, setEspp] = React.useState<{
    contr_percent_limit: string
    purchase_period_length: number
    period_contr_dollar_limit: string
    discount: string
  }>({
    contr_percent_limit: '0',
    purchase_period_length: 0,
    period_contr_dollar_limit: '0',
    discount: '0',
  })
  const [esppDiscount, setEsppDiscount] = React.useState(null)
  const [esppLoading, setEsppLoading] = React.useState(false)
  const [name, setName] = React.useState('')
  const [nameError, setNameError] = React.useState(false)
  const [nameHelper, setNameHelper] = React.useState('')
  const [next, setNext] = React.useState(false)
  const [purchasePeriodLength, SetPurchasePeriodLength] = React.useState(1)
  const [referralCode, setReferralCode] = React.useState('')
  const [referralHelper, setReferralHelper] = React.useState('')
  const [searchParams, setSearchParams] = useSearchParams()
  const [showReferral, setShowReferral] = React.useState(false)
  const [state, setState] = React.useState([])
  const [states, setStates] = React.useState([])
  const [dates, setDates] = React.useState('')

  const [validStatus, setValidStatus] = React.useState({
    name: true,
    email: true,
    employer: !employerQuery,
    state: true,
  })

  const navigate = useNavigate()

  const { createEvent } = useEventClick()

  const refWizard = React.useRef<WizardRefMethods>()

  const theme = useTheme()
  const matchesMDUp = mui.useMediaQuery(theme.breakpoints.up('md'))

  const secret_key = encryptApiSecret()

  const checkReferralCode = async (referralCode: string) => {
    try {
      const { data } = await bennyApi.get<{
        referralCode: {
          active: boolean
          receiver_benefit: number
          receiver_percentage_benefit: number
          sender_benefit: number
        }
      }>(`/espp/referralCode/${referralCode}/`)

      if (data?.referralCode?.active) {
        setCodeFound('Valid')
        setCodeBenefit(
          data?.referralCode?.receiver_benefit ? data?.referralCode?.receiver_benefit : null,
        )
      } else {
        setCodeFound('Invalid')
      }
    } catch (error) {
      console.log(error?.response?.data?.referralCode)
      if (error?.response?.data?.referralCode === 'Not found') {
        setCodeFound('Not found')
      }
    }
  }

  React.useEffect(() => {
    isValidForm()
  }, [validStatus])

  React.useEffect(() => {
    document.title = 'Start Application | Benny'
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/espp/states/`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        const { data } = response ?? {}

        const options = data.map((state) => state?.full_name)
        const sortedData = options.sort()
        setStates(sortedData)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  React.useEffect(() => {
    const secret_key = encryptApiSecret()
    document.title = 'Start Application | Benny'
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/espp/companies/`, {
        headers: {
          'Content-Type': 'application/json',
          'benny-api-key': process.env.REACT_APP_CORE_API_KEY,
          'benny-api-secret': secret_key,
        },
      })
      .then((response) => {
        const { data } = response ?? {}
        const array = data.data
        const companyOptions = array.map((company) => company.company.name)
        const sortedCompanies = companyOptions.sort()
        setCompanies(sortedCompanies)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  React.useEffect(() => {
    if (!employer) return

    setNext(false)
    setEsppLoading(true)

    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/espp/espp_by_company/${employer}/`, {
        headers: {
          'Content-Type': 'application/json',
          'benny-api-key': process.env.REACT_APP_CORE_API_KEY,
          'benny-api-secret': secret_key,
        },
      })
      .then((response) => {
        const { data } = response

        setEspp(data)

        if (data.dates) {
          setDates(data.dates)
        }

        if (data.purchase_period_length) {
          SetPurchasePeriodLength(data.purchase_period_length)
        }

        if (data.contr_dollar_limit) {
          setDollarLimit(Number(data.contr_dollar_limit))
        }

        if (data.discount) {
          setEsppDiscount(Number(data.discount) * 100)
        }
        if (data.contr_percent_limit) {
          // setMaxContrib(Number(data.contr_percent_limit) * 100)
          setContribution(Number(data.contr_percent_limit) * 100)
        }
      })
      .catch((error) => {
        console.log('Error', error)
        setEsppDiscount(null)
      })
      .finally(() => {
        setNext(true)
        setEsppLoading(false)
      })
  }, [employer])

  React.useEffect(() => {
    if (!employer) return
    setInitialStep(1)
    setShowDiscover(false)
  }, [])

  if (searchParams.get('income') !== null && annualIncome !== searchParams.get('income')) {
    if (searchParams.get('income') !== '') {
      if (annualIncome !== null) {
        const income = searchParams.get('income')
        setAnnualIncome(income)
      }
    }
  }

  if (searchParams.get('referral') !== null) {
    if (searchParams.get('referral') !== '') {
      if (referralCode !== null) {
        if (referralCode.length === 0) {
          const prereferral = searchParams.get('referral')
          setReferralCode(prereferral)
          setShowReferral(true)
          checkReferralCode(prereferral)
        }
      }
    }
  }

  function appendAtomicResponse(query: string) {
    const secret_key = encryptApiSecret()
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/espp/search_atomic/${query}/`, {
        headers: {
          'Content-Type': 'application/json',
          'benny-api-key': process.env.REACT_APP_CORE_API_KEY,
          'benny-api-secret': secret_key,
        },
      })
      .then((response) => {
        const atomic_companies = response.data.companies
        atomic_companies.map((company: string) => {
          if (!companies.includes(company)) {
            setCompanies((current) => [...current, company])
          }
        })
      })
      .catch((error) => {
        console.log(error.response.data)
      })
  }

  function calculateBennyFund() {
    const cleanedAnnualIncome = annualIncome.replace(/[$,]/g, '')
    const numericAnnualIncome = parseFloat(cleanedAnnualIncome === '' ? '0' : cleanedAnnualIncome)
    const numericContributionPercentage = contribution / 100

    let estLoanAmount: number
    let loanAmount: number

    if (
      !numericContributionPercentage ||
      numericContributionPercentage > parseFloat(espp.contr_percent_limit)
    ) {
      estLoanAmount =
        (numericAnnualIncome * parseFloat(espp.contr_percent_limit) * espp.purchase_period_length) /
        12
    } else {
      estLoanAmount =
        (numericAnnualIncome * numericContributionPercentage * purchasePeriodLength) / 12
    }

    if (!espp.period_contr_dollar_limit) {
      if (estLoanAmount > 25000 * (1 - parseFloat(espp.discount))) {
        loanAmount = 25000 * (1 - parseFloat(espp.discount))
      } else {
        loanAmount = estLoanAmount
      }
    } else {
      if (estLoanAmount > parseFloat(espp.period_contr_dollar_limit)) {
        loanAmount = parseFloat(espp.period_contr_dollar_limit)
      } else {
        loanAmount = estLoanAmount
      }
    }

    return Math.round(loanAmount)
  }

  const EsppGain =
    calculateBennyFund() / (1 - parseFloat(`${esppDiscount / 100}`)) - calculateBennyFund()

  function CustomPaper(props: mui.PaperProps) {
    return (
      <mui.Paper
        sx={{ display: employerDisplay === true ? 'block' : 'block' }}
        elevation={8}
        {...props}
      />
    )
  }

  function emailValidation(email: string) {
    const regex = new RegExp(
      /^(("[\w-\s]+")|([\w-+]+(?:\.[\w-+]+)*)|("[\w-\s]+")([\w-+]+(?:\.[\w-+]+)*))(@((?:[\w-+]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?(25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.)((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})?$)/i,
    )
    return !(!email || regex.test(email) === false)
  }

  function encryptApiSecret() {
    // Perform our encryption based on our public key - only private key can read it!
    // Return encrypted string
    // @ts-ignore
    return new Crypt().encrypt(publicKey, process.env.REACT_APP_CORE_API_SECRET)
  }

  function goToStep(step: number) {
    if (!refWizard.current) return
    refWizard.current.goToStep(step)
  }

  function handleEmailChange(e) {
    if (e.target.value.length > 0) {
      if (emailValidation(e.target.value)) {
        setEmailError(false)
        setValidStatus((validStatus) => ({
          ...validStatus,
          email: false,
        }))
        setEmailHelper('')
        setEmail(e.target.value)
      } else {
        setValidStatus((validStatus) => ({
          ...validStatus,
          email: true,
        }))
        setEmailError(true)
        setEmailHelper('Please enter a valid format')
      }
    } else {
      setValidStatus((validStatus) => ({
        ...validStatus,
        email: true,
      }))
      setEmailError(true)
      setEmailHelper('This field is required')
    }
  }

  function handleNameChange(e) {
    if (e.target.value.length > 0) {
      setNameError(false)
      setNameHelper('')
      setName(e.target.value)
      setValidStatus((validStatus) => ({
        ...validStatus,
        name: false,
      }))
    } else {
      setValidStatus((validStatus) => ({
        ...validStatus,
        name: true,
      }))
      setNameError(true)
      setNameHelper('This field is required')
    }
  }

  function handleReferralChange(e) {
    if (e.target.value.length > 0) {
      setReferralCode(e.target.value)
      const { value } = e.target
      if (e.target.value.length === 7) {
        setReferralHelper('')
        checkReferralCode(value)
      } else {
        setReferralHelper('Please enter a 7 digit ReferralCode')
        setCodeFound('')
      }
    } else {
      setReferralCode('')
    }
  }

  function handleSubmit() {
    const customerId = getCookie('customerId')

    if (!disabledStatus) {
      let newincome = annualIncome.replace('$ ', '')
      newincome = newincome.replace(',', '')
      axios
        .post(
          `${process.env.REACT_APP_SERVER_URL}/espp/contact/`,
          {
            name,
            email,
            employer,
            state,
            income: Number(newincome),
            contribution_percentage: null,
            referral: codeFound === 'Valid' ? referralCode : '',
            customer_id: customerId ?? '',
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'benny-api-key': process.env.REACT_APP_CORE_API_KEY,
              'benny-api-secret': secret_key,
            },
          },
        )
        .then((response) => {
          createEvent({ name: 'ApplySubmit' }).then()
          const { data } = response
          if (data.status === 'succeed') {
            navigate(`/application/${data.applicationId}`)
          } else if (data.error === 'unqualified state') {
            navigate(`/application/${data.applicationId}`)
          } else {
            navigate(`/verificationError`)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  function isValidForm() {
    if (!validStatus.name && !validStatus.email && !validStatus.employer && !validStatus.state) {
      setDisabledStatus(false)
    } else {
      setDisabledStatus(true)
    }
  }

  function nextStep() {
    if (!refWizard.current) return
    refWizard.current.nextStep()
  }

  function showReferralText() {
    setShowReferral(!showReferral)
  }

  const setParam = (step: string) => {
    const newSearchParams = new URLSearchParams(searchParams)
    newSearchParams.set('step', step)
    setSearchParams(newSearchParams)
  }

  if (employerQuery && esppLoading)
    return (
      <mui.Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '100vh',
        }}
      >
        <mui.CircularProgress sx={{ color: '#4B288E' }} size={75} />
      </mui.Box>
    )

  if (showDiscover)
    return (
      <mui.Grid container direction='column' style={{ minHeight: '100vh' }}>
        <Helmet>
          <link rel='canonical' href='https://heybenny.com/apply' />
        </Helmet>
        {/* Header  */}
        <Header />

        <mui.Grid container direction='column' sx={{ minHeight: 'calc(100vh - 80px)' }}>
          {matchesMDUp && <mui.Grid item style={{ flex: 3 }}></mui.Grid>}
          <mui.Grid
            item
            container
            justifyContent='center'
            alignContent='center'
            sx={{ flexGrow: 1, background: '#fff', margin: '0 auto', px: { xs: '16px', md: 0 } }}
            style={{ maxWidth: '1200px' }}
          >
            <DiscoverEsppScreen
              onclick={() => {
                window.scrollTo(0, 0)
                setShowDiscover(false)
                setSearchParams
                setParam('company_name')
                createEvent({ name: 'DiscoverGetStarted' })
              }}
            />
          </mui.Grid>
          {matchesMDUp && <mui.Grid item style={{ flex: 7 }}></mui.Grid>}
        </mui.Grid>

        <Footer />
      </mui.Grid>
    )

  return (
    <mui.Grid container direction='column'>
      <Helmet>
        <link rel='canonical' href='https://heybenny.com/apply' />
      </Helmet>
      {/* Header  */}
      <Header />

      <mui.Grid container direction='column' sx={{ minHeight: '100vh' }}>
        {matchesMDUp && <mui.Grid item style={{ flex: 3 }}></mui.Grid>}
        <mui.Grid item xs>
          <Wizard
            initialStep={initialStep}
            ref={refWizard}
            steps={[
              {
                name: 'Company Name',
                param: 'company_name',
                title: 'First, which company do you work for?',
                subtitle: (
                  <>
                    Every company ESPP is unique.
                    <br />
                    Share your employer name and get the details you need to know.
                  </>
                ),
                content: (
                  <>
                    <mui.Autocomplete
                      fullWidth
                      id='employerInput'
                      value={employer}
                      PaperComponent={CustomPaper}
                      onChange={(event, newValue) => {
                        if (typeof newValue === 'string') {
                          setValidStatus((validStatus) => ({
                            ...validStatus,
                            employer: false,
                          }))
                          setEmployerOpen(false)
                          setEmployer(newValue)
                        } else if (newValue && newValue.inputValue) {
                          // Create a new value from the user input
                          setEmployer(newValue.inputValue)
                        } else {
                          setEmployer(newValue)
                        }
                      }}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params)

                        const { inputValue } = params
                        // Suggest the creation of a new value
                        const isExisting = options.some((option) => inputValue === option)
                        if (inputValue !== '' && !isExisting) {
                          filtered.push(inputValue)
                        }

                        return filtered
                      }}
                      onInputChange={(e) => {
                        if (e !== null) {
                          const value = (e.target as HTMLInputElement).value
                          if (value) {
                            if (value.length === 2) {
                              setEmployerDisplay(false)
                              setEmployerOpen(true)
                            } else if (value.length > 2) {
                              appendAtomicResponse(value)
                              setEmployerOpen(true)
                              setEmployerDisplay(true)
                              setValidStatus((validStatus) => ({
                                ...validStatus,
                                employer: false,
                              }))
                            } else {
                              setValidStatus((validStatus) => ({
                                ...validStatus,
                                employer: true,
                              }))
                              setEmployerOpen(true)
                            }
                          }
                          setEmployer(value)
                        }
                      }}
                      onClose={() => {
                        setEmployerOpen(false)
                      }}
                      open={employerOpen}
                      selectOnFocus
                      handleHomeEndKeys
                      options={[...new Set(companies)]}
                      renderOption={(props, option) => <li {...props}>{option}</li>}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          background: 'white',
                          py: 0,
                          borderRadius: '8px',
                          '& > fieldset': { borderColor: '#E2E8F0' },
                        },
                        '& .MuiOutlinedInput-root.Mui-focused': {
                          '& > fieldset': {
                            borderColor: '#4B288E',
                          },
                        },
                        '& .MuiInputLabel-root': { color: '#818080' },
                      }}
                      freeSolo
                      renderInput={(params) => (
                        <mui.TextField placeholder='Current Employer’s Name' {...params} />
                      )}
                    />
                  </>
                ),
                cta: (
                  <>
                    <CustomButton
                      title='See ESPP Details'
                      style={{ padding: '16px 40px' }}
                      buttonProps={{
                        id: 'stepOneButton',
                        endIcon: esppLoading && <mui.CircularProgress size={20} />,
                      }}
                      variant={!validStatus.employer && next ? 'primary' : 'disabled'}
                      onClick={() => {
                        if (!validStatus.employer && next) {
                          nextStep()
                          createEvent({ name: 'ApplyEmploymentNext' })
                        }
                      }}
                    />
                  </>
                ),
              },
              {
                name: 'About Your ESPP',
                param: 'about_your_espp',
                title: `${
                  !esppDiscount
                    ? 'Benny ESPP'
                    : `Here\'s what you need to know about ${employer}\'s ESPP`
                }`,
                subtitle: !esppDiscount && <>Let’s calculate your ESPP contribution amount.</>,
                content: (
                  <>
                    <NumberFormat
                      placeholder='Your annual income'
                      customInput={mui.TextField}
                      variant='outlined'
                      id='incomeInput'
                      type='tel'
                      thousandSeparator
                      prefix='$ '
                      value={annualIncome}
                      fullWidth
                      sx={{
                        backgroundColor: 'white',
                        borderRadius: '8px',
                        '& .MuiOutlinedInput-root': {
                          py: '0px',
                          '& > fieldset': { borderColor: '#E2E8F0' },
                        },
                        '& .MuiOutlinedInput-root.Mui-focused': {
                          '& > fieldset': {
                            borderColor: '#4B288E',
                          },
                        },
                        '& .MuiOutlinedInput-input': {
                          py: '0.5em',
                          color: '#194339',
                          fontWeight: '400',
                        },
                      }}
                      onChange={(e) => setAnnualIncome(e.target.value)}
                      autoComplete='off'
                    />
                  </>
                ),
                extraContent: esppDiscount && (
                  <>
                    <mui.Grid container item xs={12} mb={4}>
                      <mui.Grid container item xs={12} sx={{ gap: { md: 4 } }}>
                        <mui.Grid
                          item
                          xs={12}
                          md={4}
                          sx={{
                            borderBottom: '1px solid #E9E9E9',
                            borderTop: { xs: '1px solid #E9E9E9', md: 'none' },
                            py: '10px',
                          }}
                        >
                          <mui.Typography fontSize={'17px'}>
                            You can buy {employer} stock at a <b>{esppDiscount}% discount.</b>
                          </mui.Typography>
                        </mui.Grid>

                        <mui.Grid
                          item
                          xs={12}
                          md={5}
                          sx={{ borderBottom: '1px solid #E9E9E9', py: '10px' }}
                        >
                          <mui.Typography fontSize={'17px'}>
                            You can enroll <b>{(12 / purchasePeriodLength).toFixed(0)}</b> times a
                            year prior to each ESPP participation period.
                          </mui.Typography>
                        </mui.Grid>
                      </mui.Grid>

                      <mui.Grid container item xs={12} sx={{ gap: { md: 4 } }}>
                        <mui.Grid
                          item
                          xs={12}
                          md={4}
                          sx={{ borderBottom: '1px solid #E9E9E9', py: '10px' }}
                        >
                          <mui.Typography fontSize={'17px'}>
                            You can <b>contribute up to {esppDiscount}%</b> of your salary with a
                            max of {formatToDollars(dollarLimit)} per year.
                          </mui.Typography>
                        </mui.Grid>

                        <mui.Grid
                          item
                          xs={12}
                          md={5}
                          sx={{ borderBottom: '1px solid #E9E9E9', py: '10px' }}
                        >
                          <mui.Typography fontSize={'17px'}>
                            Your Participation Periods run <b>{dates}</b>.
                          </mui.Typography>
                        </mui.Grid>
                      </mui.Grid>
                    </mui.Grid>

                    <mui.Grid container item xs={12} py={2}>
                      <mui.Grid container>
                        <mui.Typography
                          color='#194339'
                          fontSize={{ xs: '21px', md: '24px' }}
                          fontWeight={600}
                          lineHeight={'normal'}
                        >
                          To see your ESPP gain potential, enter your estimated annual income.
                        </mui.Typography>
                      </mui.Grid>
                    </mui.Grid>
                  </>
                ),
                cta: (
                  <>
                    <CustomButton
                      title='See My ESPP Details'
                      style={{ padding: '16px 40px' }}
                      buttonProps={{ id: 'stepTwoButton' }}
                      variant={!validStatus.employer && annualIncome ? 'primary' : 'disabled'}
                      onClick={() => {
                        if (!validStatus.employer && annualIncome) {
                          nextStep()
                          createEvent({ name: 'ApplyContributionsNext' })
                        }
                      }}
                    />
                  </>
                ),
              },
              ...(!!esppDiscount
                ? [
                    {
                      name: 'Your ESPP Potential',
                      param: 'your_espp_potential',
                      title: matchesMDUp ? 'Your ESPP Potential' : undefined,
                      // subtitle: 'Let’s get into it.',
                      extraContent: (
                        <>
                          <PopupModal
                            url='https://calendly.com/rootedandy/15-minute-espp-consult?month=2024-01'
                            rootElement={document.getElementById('root')}
                            onModalClose={() => setShowcalendly(false)}
                            open={showCalendly}
                          />
                          <mui.Grid container item xs={12} flexGrow={1}>
                            <mui.Grid container item xs={12}>
                              <mui.Grid
                                item
                                xs={12}
                                md={4}
                                pr={{ xs: 0, md: 3 }}
                                sx={{ borderRight: { xs: '', md: '1px solid #A7A7A7' } }}
                              >
                                <mui.Typography
                                  fontSize={'40px'}
                                  lineHeight={'normal'}
                                  fontWeight={'400'}
                                  sx={{ textWrap: 'balance' }}
                                  mb={4}
                                  textAlign={{ xs: 'center', md: 'left' }}
                                >
                                  You&nbsp;can&nbsp;gain *
                                  <a style={{ color: '#9747FF' }}>{formatToDollars(EsppGain)}</a>{' '}
                                  every&nbsp;{purchasePeriodLength}&nbsp;months.
                                </mui.Typography>

                                <mui.Typography
                                  fontSize={{ xs: '14px', md: '17px' }}
                                  fontWeight={'400'}
                                  textAlign={{ xs: 'center', md: 'left' }}
                                  mb={{ xs: 8, md: 0 }}
                                >
                                  *This is from the {esppDiscount}% discount alone. This doesn't
                                  include any gain from the lookback, if applicable.
                                </mui.Typography>
                              </mui.Grid>

                              <mui.Grid
                                item
                                xs={12}
                                md={8}
                                sx={{
                                  ...theme.typography.body1,
                                  fontSize: { xs: '17px', md: '24px' },
                                  pl: { xs: 0, md: 3 },
                                  lineHeight: 'normal',
                                  fontWeight: 400,
                                }}
                              >
                                <mui.Grid
                                  item
                                  sx={{
                                    borderBottom: '1px solid #E9E9E9',
                                    borderTop: '1px solid #E9E9E9',
                                    border: { md: 'none' },
                                    py: { xs: 2, md: 0 },
                                  }}
                                >
                                  {employer} purchases the discounted shares every{' '}
                                  <b>{monthDescriptions[purchasePeriodLength]} months</b>.
                                </mui.Grid>
                                <mui.Grid
                                  item
                                  sx={{
                                    borderBottom: '1px solid #E9E9E9',
                                    border: { md: 'none' },
                                    py: { xs: 2, md: 3 },
                                  }}
                                >
                                  To maximize your ESPP, you can{' '}
                                  <b>contribute {formatToDollars(calculateBennyFund())}</b> over
                                  this {monthDescriptions[purchasePeriodLength]} month period.
                                </mui.Grid>
                                <mui.Grid
                                  item
                                  sx={{
                                    borderBottom: '1px solid #E9E9E9',
                                    border: { md: 'none' },
                                    py: { xs: 2, md: 0 },
                                  }}
                                >
                                  With those contributions, you’ll receive{' '}
                                  <b>
                                    shares worth {formatToDollars(calculateBennyFund() + EsppGain)}
                                  </b>
                                  , resulting in a <b>gain of {formatToDollars(EsppGain)}</b>.
                                </mui.Grid>

                                <mui.Typography
                                  fontSize={'17px'}
                                  lineHeight={'normal'}
                                  fontWeight={'400'}
                                  mt={{ xs: 2, md: 3 }}
                                  textAlign={{ xs: 'center', md: 'left' }}
                                >
                                  Have questions?{' '}
                                  <a
                                    style={{ color: '#6F44B7', cursor: 'pointer' }}
                                    onClick={() => setShowcalendly(true)}
                                  >
                                    Schedule a free consult.
                                  </a>
                                </mui.Typography>
                              </mui.Grid>
                            </mui.Grid>

                            <mui.Grid
                              container
                              item
                              xs={12}
                              sx={{ borderTop: { xs: '', md: '1px dashed #A7A7A7' } }}
                              my={4}
                              py={4}
                            >
                              <mui.Typography
                                fontSize={'24px'}
                                color={'#194339'}
                                fontWeight={'700'}
                                lineHeight={'normal'}
                                mb={{ xs: 2, md: 3 }}
                                textAlign={{ xs: 'center', md: 'left' }}
                              >
                                Benny can cover your {formatToDollars(calculateBennyFund())}{' '}
                                contribution in full.
                              </mui.Typography>

                              <mui.Typography
                                fontSize={{ xs: '17px', md: '24px' }}
                                fontWeight={'400'}
                                lineHeight={'normal'}
                                textAlign={{ xs: 'center', md: 'left' }}
                              >
                                ESPP Contributions are made through payroll deductions. Benny covers
                                this cost each pay day so you can maximize your ESPP without
                                reducing your take-home pay.
                              </mui.Typography>

                              <mui.Grid
                                item
                                xs
                                sx={{
                                  alignItems: 'center',
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'center',
                                  mt: { xs: 6, md: 4 },
                                }}
                              >
                                <CustomButton
                                  title='Explore ESPP Funding'
                                  variant='primary'
                                  buttonProps={{ endIcon: <EastIcon /> }}
                                  onClick={() => {
                                    nextStep()
                                    createEvent({ name: 'ApplyYourESPPPotentialNext' })
                                  }}
                                />
                                <mui.Typography
                                  fontSize={'20px'}
                                  color={'#4F4F4F'}
                                  textAlign={'center'}
                                  lineHeight={'normal'}
                                  mt={2}
                                >
                                  No Credit Checks.
                                  <br />
                                  30-Day Free Cancellation.
                                </mui.Typography>
                              </mui.Grid>
                            </mui.Grid>
                          </mui.Grid>
                        </>
                      ),
                    },
                  ]
                : []),
              {
                name: 'Contact',
                param: 'contact',
                title: esppDiscount
                  ? `Explore Your ESPP Funding Offer of ${formatToDollars(calculateBennyFund())}.`
                  : 'Get Your Offer',
                subtitle: 'One last step to see your customized, no-obligation offer.',
                content: (
                  <>
                    <mui.FormControl fullWidth sx={{ fontWeight: '400', fontSize: '20px' }}>
                      <mui.TextField
                        id='nameInput'
                        sx={{
                          backgroundColor: 'white',
                          mt: '0.3em',
                          mb: '0.5em',
                          borderRadius: '8px',
                          '& .MuiOutlinedInput-root': {
                            py: '0px',
                            '& > fieldset': { borderColor: '#E2E8F0' },
                          },
                          '& .MuiOutlinedInput-root.Mui-focused': {
                            '& > fieldset': {
                              borderColor: '#4B288E',
                            },
                          },
                          '& .MuiInputBase-input': {
                            py: '0.5em',
                            color: '#194339',
                            fontWeight: '400',
                          },
                        }}
                        onChange={handleNameChange}
                        helperText={nameHelper}
                        error={nameError}
                        placeholder='Name'
                      />
                    </mui.FormControl>
                    <mui.FormControl fullWidth sx={{ fontWeight: '400', fontSize: '20px' }}>
                      <mui.TextField
                        id='emailInput'
                        sx={{
                          backgroundColor: 'white',
                          borderRadius: '8px',
                          mb: '0.5em',
                          '& .MuiOutlinedInput-root': {
                            py: '0px',
                            '& > fieldset': { borderColor: '#E2E8F0' },
                          },
                          '& .MuiOutlinedInput-root.Mui-focused': {
                            '& > fieldset': {
                              borderColor: '#4B288E',
                            },
                          },
                          '& .MuiOutlinedInput-input': {
                            py: '0.5em',
                            color: '#194339',
                            fontWeight: '400',
                          },
                        }}
                        onChange={handleEmailChange}
                        helperText={emailHelper}
                        type='email'
                        error={emailError}
                        placeholder='Email'
                      />
                    </mui.FormControl>

                    <mui.Autocomplete
                      id='stateInput'
                      disablePortal
                      onChange={(event, newValue) => {
                        if (newValue) {
                          setState(newValue)
                          setValidStatus((validStatus) => ({
                            ...validStatus,
                            state: false,
                          }))
                        } else {
                          setValidStatus((validStatus) => ({
                            ...validStatus,
                            state: true,
                          }))
                        }
                      }}
                      options={states}
                      fullWidth
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          background: 'white',
                          mt: '0.3em',
                          mb: '2em',
                          py: '0px',
                          borderRadius: '8px',
                          '& > fieldset': { borderColor: '#E2E8F0' },
                        },
                        '& .MuiOutlinedInput-root.Mui-focused': {
                          '& > fieldset': {
                            borderColor: '#4B288E',
                          },
                        },
                        '& .MuiInputLabel-root': { color: '#818080' },
                      }}
                      renderInput={(params) => <mui.TextField placeholder='State' {...params} />}
                    />

                    <mui.Grid container>
                      <mui.Typography
                        id='referralShowLink'
                        onClick={showReferralText}
                        sx={{
                          fontWeight: '400',
                          fontSize: '14px',
                          color: '#4B288E',
                          cursor: 'pointer',
                          display: showReferral ? 'none' : 'block',
                        }}
                      >
                        I have a referral code
                      </mui.Typography>
                      <mui.FormControl
                        fullWidth
                        sx={{
                          display: showReferral ? 'block' : 'none',
                          fontWeight: '400',
                          fontSize: '20px',
                        }}
                      >
                        <mui.TextField
                          placeholder='Referral code (optional)'
                          fullWidth
                          id='referralInput'
                          sx={{
                            backgroundColor: 'white',
                            borderRadius: '8px',
                            '& .MuiOutlinedInput-root': {
                              py: '0px',
                              '& > fieldset': { borderColor: '#E2E8F0' },
                            },
                            '& .MuiOutlinedInput-root.Mui-focused': {
                              '& > fieldset': {
                                borderColor: '#4B288E',
                              },
                            },
                            '& .MuiOutlinedInput-input': {
                              py: '0.5em',
                              color: '#194339',
                              fontWeight: '400',
                            },
                          }}
                          value={referralCode}
                          onChange={handleReferralChange}
                          type='text'
                        />
                        <mui.FormHelperText
                          id='referralHelper'
                          sx={{ color: 'red', margin: '0px' }}
                        >
                          {referralHelper}
                        </mui.FormHelperText>
                        <mui.Typography
                          id='referralSuccessHelper'
                          sx={{
                            fontWeight: '400',
                            fontSize: '16px',
                            display: codeFound === 'Valid' && codeBenefit > 0 ? 'block' : 'none',
                          }}
                        >
                          Great! ${codeBenefit} discount will be applied to your application.
                        </mui.Typography>
                        <mui.Typography
                          sx={{
                            fontWeight: '400',
                            fontSize: '16px',
                            display: codeFound === 'Invalid' ? 'block' : 'none',
                            color: 'red',
                          }}
                        >
                          {referralCode} is no longer valid. You can still apply without the code.
                        </mui.Typography>
                        <mui.Typography
                          id='referralFailureHelper2'
                          sx={{
                            fontWeight: '400',
                            fontSize: '16px',
                            display: codeFound === 'Not found' ? 'block' : 'none',
                            color: 'red',
                          }}
                        >
                          {referralCode} is not a valid code. Please check and try again. You can
                          still apply without the code.
                        </mui.Typography>
                      </mui.FormControl>
                    </mui.Grid>
                  </>
                ),
                cta: (
                  <>
                    <CustomButton
                      title='Get Your Funding Offer'
                      style={{ padding: '16px 40px' }}
                      buttonProps={{ id: 'stepTwoButton' }}
                      variant={!disabledStatus ? 'primary' : 'disabled'}
                      onClick={disabledStatus ? undefined : handleSubmit}
                    />
                  </>
                ),
              },
            ]}
          />
        </mui.Grid>
        {matchesMDUp && <mui.Grid item style={{ flex: 7 }}></mui.Grid>}
      </mui.Grid>

      <Footer />
    </mui.Grid>
  )
}

export function NewAppForm() {
  return <NewApplicationForm />
}
