import * as mui from '@mui/material'
import React from 'react'
import { useTheme } from '@mui/material/styles'

import { Typography } from 'Components/Typography'

import Logo from 'media/images/logos/newlogo.svg'


export function Layout(props: {
  children: React.ReactNode | React.ReactNode[]
}) {
  const theme = useTheme()
  const matchesMDUp = mui.useMediaQuery(theme.breakpoints.up('md'))

  return (
    <mui.Box sx={{width: '100%', height: '100vh', backgroundColor: '#E9E9E9'}}>
      <mui.Container component="main" maxWidth="xs">
        <mui.Grid container direction='column' sx={{ minHeight: '100vh' }}>
          {matchesMDUp && <mui.Grid item style={{ flex: 3 }}></mui.Grid>}
          <mui.Paper sx={{
            alignItems: 'center',
            backgroundImage: 'linear-gradient(282deg, #581BBB -0.5%, #0CA671 98.84%)',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'top',
            backgroundSize: '100% 8px',
            borderRadius: '8px',
            boxShadow: '0px 0px 24px 0px rgba(0, 0, 0, 0.08)',
            display: 'flex',
            flexDirection: 'column',
            p: 6,
          }}>
            {/*Logo*/}
            <img src={Logo} alt="logo" width="137" />
            <mui.Divider sx={{ backgroundColor: '#E9E9E9', mb: 6, mt: 4, width: '100%' }} />
            <mui.Box width="100%">
              {props.children}
            </mui.Box>
          </mui.Paper>
          <mui.Link
            href="/contact-us"
            sx={{
              color: '#1D1D1F',
              fontSize: '18px',
              opacity: 0.5,
              mt: 4,
              textAlign: 'center',
              textDecoration: 'none',
            }}
          >
            Need Help? Contact Support
          </mui.Link>
          {matchesMDUp && <mui.Grid item style={{ flex: 7 }}></mui.Grid>}
        </mui.Grid>
      </mui.Container>
    </mui.Box>
  )
}