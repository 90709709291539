/* eslint-disable */
import {
  Autocomplete,
  CircularProgress,
  createFilterOptions,
  FormControl,
  FormHelperText,
  Grid,
  OutlinedInput,
  Paper,
  TextField,
  Typography,
} from '@mui/material'
import Slider from '@mui/material/Slider'
import { styled } from '@mui/material/styles'
import 'animate.css'
import axios from 'axios'
import FuzzySet from 'fuzzyset.js'
import { Crypt } from 'hybrid-crypto-js'
import React from 'react'
import NumberFormat from 'react-number-format'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { getCookie } from '../../../helpers/cookieHelper'
import { useEventClick } from '../../../hooks/tracker'
import { useClientSelector } from '../../../store/client'
import { colors } from '../../../utils/colors'
import { formatToDollars } from '../../../utils/commons'
import './styles.css'

const BennySlider = styled(Slider)({
  color: '#1BCD90',
  height: 8,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-rail': {
    backgroundColor: '#88c7ff66',
  },
  '& .MuiSlider-thumb': {
    height: 26,
    width: 26,
    backgroundColor: '#fff',
    boxShadow: '0px 2px 3px rgba(83, 64, 100, 0.2), 0px 1px 2px rgba(83, 64, 100, 0.55)',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: '0px 2px 3px rgba(83, 64, 100, 0.2), 0px 1px 2px rgba(83, 64, 100, 0.85)',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.8,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: '#52af77',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
})

const esppDiscountLimit = (inputObj) => {
  const { value } = inputObj
  return value === 5 || (value <= 15 && value >= 5)
}

var publicKey = `-----BEGIN PUBLIC KEY-----
MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCfm2uMTvb+gqXRFxWHnfCKcHfH
v7aMN6oiEqTJj0BixtTYBXH89N+xuYgoIBnfMXPXPIg/UNWEOZtAETsOVvya+YBo
ZZTquYJ2I0PaxtpUKkpCiEQ/bTCQIDAeUwHr0l4vUn/fmslD0rZ3+jo4Dsl8nX0O
pwZQ2grQaXLb347RQwIDAQAB
-----END PUBLIC KEY-----`

export default function CalculatorV3(props) {
  const client = useClientSelector((state) => state.client)
  const [disabled, setDisabled] = React.useState(true)

  const { createEvent } = useEventClick()

  const [employerDisplay, setEmployerDisplay] = React.useState(false)

  const isOnlyCalculator = props?.isOnlyCalculator ?? false

  const prefilled_employer = props.prefilled_employer ?? ''
  const prefilled_name = props.prefilled_name ?? ''
  const prefilled_email = client?.email ?? props.prefilled_email ?? ''
  const prefilled_state = props.prefilled_state ?? ''
  const prefilled_income = props.prefilled_income ?? ''
  const [employer, setEmployer] = React.useState('')
  const [bestMatchingCompany, setBestMatchingCompany] = React.useState('')

  const [employerHelper, setEmployerHelper] = React.useState('')
  const [employerError, setEmployerError] = React.useState(false)
  const [annualIncome, setAnnualIncome] = React.useState('65000')
  const [esppDiscount, setEsppDiscount] = React.useState(15)
  const [contribution, setContribution] = React.useState(10)
  const [maxDiscount, setMaxDiscount] = React.useState(15)
  const [discountDisabled, setDiscountDisabled] = React.useState(false)
  const [maxContrib, setMaxContrib] = React.useState(25)
  const [dollarLimit, setDollarLimit] = React.useState(0)
  const [searchParams, setSearchParams] = useSearchParams()
  const [companies, setCompanies] = React.useState([])
  const [companiesObj, setCompaniesObj] = React.useState([])
  const [states, setStates] = React.useState([])
  const [state, setState] = React.useState(null)
  const [esppGain, setEsppGain] = React.useState(1147.06)
  const [inputValue, setInputValue] = React.useState('')
  const leadId = props.leadId ?? ''
  const [withoutBenny, setWithoutBenny] = React.useState(541.66)
  const [name, setName] = React.useState('')
  const [nameError, setNameError] = React.useState(false)
  const [nameHelper, setNameHelper] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [emailError, setEmailError] = React.useState(false)
  const [emailHelper, setEmailHelper] = React.useState('')
  const [step, setStep] = React.useState('step1')
  const [disabledStatus, setDisabledStatus] = React.useState(true)
  const [showReferral, setShowReferral] = React.useState(false)
  const [codeFound, setCodeFound] = React.useState('')
  const [referralError, setReferralError] = React.useState(false)
  const [referralHelper, setReferralHelper] = React.useState('')
  const [referralCode, setReferralCode] = React.useState('')
  const [codeBenefit, setCodeBenefit] = React.useState(null)
  const [loading, setLoading] = React.useState(false)
  const [validStatus, setValid] = React.useState({
    name: true,
    email: true,
    employer: true,
    state: true,
  })
  const [employerOpen, setEmployerOpen] = React.useState(false)
  const { prereferral } = useParams()
  let navigate = useNavigate()

  const [skipSteps, setSkipSteps] = React.useState(false)

  const emailValidation = (email) => {
    const regex = new RegExp(
      /^(("[\w-\s]+")|([\w-+]+(?:\.[\w-+]+)*)|("[\w-\s]+")([\w-+]+(?:\.[\w-+]+)*))(@((?:[\w-+]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
    )
    return !(!email || regex.test(email) === false)
  }

  if (searchParams.get('employer') !== null) {
    if (searchParams.get('employer') !== '') {
      if (employer !== null) {
        if (employer.length === 0) {
          let preemployer = searchParams.get('employer')
          setEmployer(preemployer)
          setValid((validStatus) => ({
            ...validStatus,
            employer: false,
          }))
        }
      }
    }
  }

  const checkReferralCode = (referralCode) => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/espp/referralCode/${referralCode}/`, {
        headers: {
          'Content-Type': 'application/json',
          'benny-api-key': process.env.REACT_APP_CORE_API_KEY,
          'benny-api-secret': secret_key,
        },
      })
      .then((response) => {
        const data = response.data.referralCode
        if (data.active === true) {
          setCodeFound('Valid')
          setCodeBenefit(data.receiver_benefit)
        } else {
          setCodeFound('Invalid')
        }
      })
      .catch((error) => {
        console.log(error.response.data.referralCode)
        if (error.response.data.referralCode === 'Not found') {
          setCodeFound('Not found')
        }
      })
  }

  const encrypt_api_secret = (secretKey) => {
    var crypt = new Crypt()

    // Perform our encryption based on our public key - only private key can read it!
    var encrypted = crypt.encrypt(publicKey, secretKey)

    // Return encrypted string
    return encrypted
  }

  const secret_key = encrypt_api_secret(process.env.REACT_APP_CORE_API_SECRET)
  const filter = createFilterOptions({ limit: 5 })
  const CustomPaper = (props) => {
    return (
      <Paper
        sx={{ display: employerDisplay === true ? 'block' : 'none' }}
        elevation={8}
        {...props}
      />
    )
  }

  const handleSliderDiscount = (event, newValue) => {
    setEsppDiscount(newValue)
  }

  const handleSliderContribution = (event, newValue) => {
    setContribution(newValue)
  }

  const handleAnnualIncomeChange = (event) => {
    let income = event.target.value.replace(/[^0-9]/g, '')
    setAnnualIncome(income)
  }

  console.log({ annualIncome })

  const handleReferralChange = (e) => {
    if (e.target.value.length > 0) {
      setReferralCode(e.target.value)
      let value = e.target.value
      if (e.target.value.length === 7) {
        setReferralHelper('')
        checkReferralCode(value)
      } else {
        setReferralHelper('Please enter a 7 digit ReferralCode')
        setCodeFound('')
      }
    } else {
      setReferralCode('')
    }
  }

  const showtReferralText = () => {
    setShowReferral(!showReferral)
  }

  const handleDiscountChange = (event) => {
    let discount = event.target.value
    discount = discount.replace(' %', '')
    discount = Number(discount)
    setEsppDiscount(discount)
  }

  if (searchParams.get('referral') !== null) {
    if (searchParams.get('referral') !== '') {
      if (referralCode !== null) {
        if (referralCode.length === 0) {
          let prereferral = searchParams.get('referral')
          setReferralCode(prereferral)
          setShowReferral(true)
          checkReferralCode(prereferral)
        }
      }
    }
  }

  const isValidForm = () => {
    if (!validStatus.name && !validStatus.email && !validStatus.employer && !validStatus.state) {
      setDisabledStatus(false)
    } else {
      setDisabledStatus(true)
    }
  }

  React.useEffect(() => {
    isValidForm()
  }, [validStatus])

  const handleNameChange = (e) => {
    if (e.target.value.length > 0) {
      setNameError(false)
      setNameHelper('')
      setName(e.target.value)
      setValid((validStatus) => ({
        ...validStatus,
        name: false,
      }))
    } else {
      setValid((validStatus) => ({
        ...validStatus,
        name: true,
      }))
      setNameError(true)
      setNameHelper('This field is required')
    }
  }

  const handleEmailChange = (e) => {
    if (e.target.value.length > 0) {
      if (emailValidation(e.target.value)) {
        setEmailError(false)
        setValid((validStatus) => ({
          ...validStatus,
          email: false,
        }))
        setEmailHelper('')
        setEmail(e.target.value)
      } else {
        setValid((validStatus) => ({
          ...validStatus,
          email: true,
        }))
        setEmailError(true)
        setEmailHelper('Please enter a valid format')
      }
    } else {
      setValid((validStatus) => ({
        ...validStatus,
        email: true,
      }))
      setEmailError(true)
      setEmailHelper('This field is required')
    }
  }

  const handleContributionChange = (event) => {
    let contribution = event.target.value
    contribution = contribution.replace(' %', '')
    contribution = Number(contribution)
    setContribution(contribution)
  }

  const handleCalculator = () => {
    let annual = annualIncome.replace('$ ', '')
    annual = annual.replace(',', '')
    annual = Number(annual)
    let gain =
      (annual * (esppDiscount / 100) * (contribution / 100)) / (1 - esppDiscount / 100) <
      25000 * (esppDiscount / 100)
        ? (annual * (esppDiscount / 100) * (contribution / 100)) / (1 - esppDiscount / 100)
        : 25000 * (esppDiscount / 100)
    let withoutbenny =
      (annual * (contribution / 100)) / 12 < (25000 * (1 - esppDiscount / 100)) / 12
        ? (annual * (contribution / 100)) / 12
        : (25000 * (1 - esppDiscount / 100)) / 12
    setWithoutBenny(Number(withoutbenny).toFixed(2))
    setEsppGain(gain.toFixed(2))
  }

  const handleSubmit = () => {
    let customerId = getCookie('customerId')

    if (!disabledStatus || skipSteps) {
      setLoading(true)
      let newincome = annualIncome.replace('$ ', '')
      newincome = newincome.replace(',', '')
      axios
        .post(
          `${process.env.REACT_APP_SERVER_URL}/espp/contact/`,
          {
            name: skipSteps ? 'none' : name,
            email: skipSteps ? client?.email ?? 'none' : email,
            employer: bestMatchingCompany,
            state: skipSteps ? 'none' : state,
            income: Number(newincome),
            contribution_percentage: Number(contribution / 100),
            referral: codeFound === 'Valid' ? referralCode : '',
            customer_id: customerId ?? '',
            email_verified: !!client?.email_verified,
            client_id: client?.id,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'benny-api-key': process.env.REACT_APP_CORE_API_KEY,
              'benny-api-secret': secret_key,
            },
          },
        )
        .then((response) => {
          const data = response.data
          if (data.status === 'succeed') {
            axios
              .post(`${process.env.REACT_APP_SERVER_URL}/espp/add_application_lead/`, {
                applicationId: data.applicationId,
                leadId: leadId,
              })
              .then((response) => {
                console.log('Superresponse', response)
              })
              .catch((error) => {
                console.error('ERROR BENNY', error)
              })
            navigate(`/application/${data.applicationId}?utm_source=calculator_home`)
            if (isOnlyCalculator) {
              window.parent.postMessage(
                { type: 'REDIRECT', url: `application/${data.applicationId}` },
                '*',
              )
            }
          } else {
            if (data.error === 'unqualified state') {
              axios
                .post(`${process.env.REACT_APP_SERVER_URL}/espp/add_application_lead/`, {
                  applicationId: data.applicationId,
                  leadId: leadId,
                })
                .then((response) => {
                  console.log('Superresponse', response)
                })
                .catch((error) => {
                  console.error('ERROR BENNY', error)
                })
              navigate(`/application/${data.applicationId}?utm_source=calculator_home`)
              if (isOnlyCalculator) {
                window.parent.postMessage(
                  { type: 'REDIRECT', url: `application/${data.applicationId}` },
                  '*',
                )
              }
            } else {
              navigate(`/verificationError`)
            }
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  const handleCalculatorButton = () => {
    if (!bestMatchingCompany) {
      setEmployerError(true)
      setEmployerHelper('This field is required')
      return
    }

    if (skipSteps) {
      if (isOnlyCalculator) {
        console.log(isOnlyCalculator)
        return window.parent.postMessage(
          {
            type: 'REDIRECT',
            url: `apply/?employer=${bestMatchingCompany}&income=${Number(
              annualIncome,
            )}&contribution_percentage=${Number(
              contribution / 100,
            )}&utm_source=calculator_embedded`,
          },
          '*',
        )
      }
      window.scrollTo(0, 0)
      return navigate(
        `/apply/${bestMatchingCompany}?income=${Number(
          annualIncome,
        )}&contribution_percentage=${Number(
          contribution / 100,
        )}&referral=${referralCode}&utm_source=calculator_home`,
      )
    }
    console.log('EL EMPLOYER ESTA', employer)
    if (employer.length > 0) {
      setStep('step2')
      setEmployerError(false)
      setEmployerHelper('')
    } else {
      setStep('step1')
      setEmployerError(true)
      setEmployerHelper('This field is required')
    }

    if (name.length === 0) {
    }

    if (step === 'step2' && disabledStatus === false) {
      handleSubmit()
    } else {
      if (step === 'step2') {
        if (validStatus.name === true) {
          setNameError(true)
          setNameHelper('This field is required')
        }
        if (validStatus.email === true) {
          setEmailError(true)
          setEmailHelper('This field is required')
        }
      }
    }
  }

  React.useEffect(() => {
    document.title = 'Start Application | Benny'
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/espp/states/`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        const { data } = response ?? {}
        const options = data.map((state) => {
          return state.full_name
        })
        if (prereferral !== undefined) {
          setReferralCode(prereferral)
          setShowReferral(true)
          checkReferralCode(prereferral)
        }
        const sortedData = options.sort()
        setStates(sortedData)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  const append_atomic_response = (query) => {
    var secret_key = encrypt_api_secret(process.env.REACT_APP_CORE_API_SECRET)
    setDisabled(true)
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/espp/search_atomic/${query}/`, {
        headers: {
          'Content-Type': 'application/json',
          'benny-api-key': process.env.REACT_APP_CORE_API_KEY,
          'benny-api-secret': secret_key,
        },
      })
      .then((response) => {
        console.log('DATA RESPONSE FOR REFERRAL', response.data.companies)
        var atomic_companies = response.data.companies
        let unique = [...new Set(companies)]
        console.log('Current companies', unique)
        atomic_companies.map((company) => {
          if (unique.includes(company)) {
            console.log('YA ESTOY')
          } else {
            setCompanies((current) => [...current, company])
            setCompaniesObj((current) => [...current, { company, inDb: false }])
          }
        })
      })
      .catch((error) => {
        console.log(error.response.data)
      })
      .finally(() => {
        setDisabled(false)
      })
  }

  React.useEffect(() => {
    if (!bestMatchingCompany) return
    setDisabled(true)
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/espp/espp_by_company/${bestMatchingCompany}/`, {
        headers: {
          'Content-Type': 'application/json',
          'benny-api-key': process.env.REACT_APP_CORE_API_KEY,
          'benny-api-secret': secret_key,
        },
      })
      .then((response) => {
        let data = response.data
        if (data.contr_dollar_limit) {
          setDollarLimit(Number(data.contr_dollar_limit))
        }
        if (data.discount) {
          setEsppDiscount(Number(data.discount) * 100)
          setDiscountDisabled(true)
          setMaxDiscount(Number(data.discount) * 100)
        }
        if (data.contr_percent_limit) {
          setMaxContrib(Number(data.contr_percent_limit) * 100)
          setContribution(Number(data.contr_percent_limit) * 100)
        }
      })
      .catch((error) => {
        setMaxContrib(25)
        setContribution(25)
        setDiscountDisabled(false)
        setMaxDiscount(15)
        console.log(error)
      })
      .finally(() => {
        setDisabled(false)
      })
  }, [bestMatchingCompany])

  React.useEffect(() => {
    if (prefilled_employer.length > 0) {
      setEmployer(prefilled_employer)
      setValid((validStatus) => ({
        ...validStatus,
        employer: false,
      }))
      setTimeout(() => {
        setEmployerOpen(false)
      }, 1000)
    }
  }, [prefilled_employer])

  React.useEffect(() => {
    var secret_key = encrypt_api_secret(process.env.REACT_APP_CORE_API_SECRET)
    document.title = 'Start Application | Benny'
    if (prefilled_name.length > 0) {
      setName(prefilled_name)
      setValid((validStatus) => ({
        ...validStatus,
        name: false,
      }))
    }
    if (prefilled_email.length > 0) {
      setEmail(prefilled_email)
      setValid((validStatus) => ({
        ...validStatus,
        email: false,
      }))
    }
    if (prefilled_income.length > 0) {
      setAnnualIncome(prefilled_income)
    }
    if (prefilled_state.length > 0) {
      setState(prefilled_state)
      setValid((validStatus) => ({
        ...validStatus,
        state: false,
      }))
    }

    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/espp/companies/`, {
        headers: {
          'Content-Type': 'application/json',
          'benny-api-key': process.env.REACT_APP_CORE_API_KEY,
          'benny-api-secret': secret_key,
        },
      })
      .then((response) => {
        const { data } = response ?? {}
        const array = data.data
        const companyOptions = array.map((company) => {
          return company.company.name
        })
        const sortedCompanies = companyOptions.sort()
        setCompanies(sortedCompanies)
        setCompaniesObj(
          sortedCompanies?.map((comp) => ({
            company: comp,
            inDb: true,
          })),
        )
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  React.useEffect(() => {
    handleCalculator()
  }, [annualIncome, contribution, esppDiscount])

  function getBestMatchingCompany(companies = [], targetName = '') {
    console.log({ companies, targetName })
    const companyNames = companies.map((comp) => comp.company)
    const fuzzy = FuzzySet(companyNames ?? [])

    const result = fuzzy.get(targetName ?? '')

    if (result && result.length > 0) {
      const bestMatch = result[0]
      const similarity = bestMatch[0]
      const matchedCompany = bestMatch[1]

      if (similarity > 0.8) {
        return companies.find((comp) => comp.company === matchedCompany)
      }
    }
    return null
  }

  React.useEffect(() => {
    const bestMatching = getBestMatchingCompany(companiesObj, employer)
    setBestMatchingCompany(bestMatching?.company ?? employer)

    if (bestMatching) {
      setSkipSteps(bestMatching.inDb)
    } else {
      setSkipSteps(false)
    }
  }, [JSON.stringify(companiesObj), employer])

  const calculateBebbyFund = () => {
    const cleanedAnnualIncome = annualIncome.replace(/[$,]/g, '')
    const numericAnnualIncome = parseFloat(cleanedAnnualIncome === '' ? 0 : cleanedAnnualIncome)
    const numericContribution = parseFloat(contribution)
    let bennyFund

    if (dollarLimit === 0) {
      const maxLimitPerYear = 25000 * (1 - esppDiscount / 100)
      if (numericAnnualIncome * (numericContribution / 100) > maxLimitPerYear) {
        bennyFund = maxLimitPerYear
      } else {
        bennyFund = numericAnnualIncome * (numericContribution / 100)
      }
    } else if (numericAnnualIncome * (numericContribution / 100) < dollarLimit) {
      bennyFund = numericAnnualIncome * (numericContribution / 100)
    } else {
      bennyFund = dollarLimit
    }

    return bennyFund
  }

  return (
    <Grid
      id='esppcalculator'
      className='calculator-container fade-in'
      container
      sx={{
        mx: 'auto',
        mt: { xs: '0px', md: '3em' },
        mb: '5em',
      }}
    >
      {leadId === '' ? (
        <Grid item md={12} mx='auto'>
          {!isOnlyCalculator && (
            <Grid mx='auto' my={{ xs: '20px', md: '0px' }} textAlign='center'>
              <h2 className='calculator-title' id='calculator'>
                ESPP Calculator
              </h2>
              <h4 className='calculator-subtitle'>
                Calculate how much Benny can fund and help you gain.
              </h4>
            </Grid>
          )}
        </Grid>
      ) : null}

      <Grid container my='3em'>
        <Grid
          container
          item
          md={6}
          xs={12}
          bgcolor='white'
          borderRadius={{ xs: '16px 16px 0 0', md: '16px 0 0 16px' }}
          p='40px'
          border='1px solid #EDF5F3'
          flexDirection='column'
          justifyContent='center'
        >
          <h1 className='calculator-paper-title'>Employer name</h1>
          <Autocomplete
            id='employerInput'
            value={employer}
            PaperComponent={CustomPaper}
            onChange={(event, newValue) => {
              if (typeof newValue === 'string') {
                setValid((validStatus) => ({
                  ...validStatus,
                  employer: false,
                }))
                setEmployerOpen(false)
                setEmployer(newValue)
              } else if (newValue && newValue.inputValue) {
                // Create a new value from the user input
                setEmployer(newValue.inputValue)
              } else {
                setEmployer(newValue)
              }
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params)

              const { inputValue } = params
              // Suggest the creation of a new value
              const isExisting = options.some((option) => inputValue === option)
              if (inputValue !== '' && !isExisting) {
                filtered.push(inputValue)
              }

              return filtered
            }}
            onInputChange={(e, newInputValue) => {
              if (newInputValue !== null && newInputValue !== undefined) {
                console.log({ newInputValue })

                setInputValue(newInputValue)
                if (newInputValue.length === 2) {
                  setEmployer(newInputValue)
                  setEmployerDisplay(false)
                  setEmployerOpen(true)
                } else if (newInputValue.length > 2) {
                  append_atomic_response(newInputValue)
                  setEmployerOpen(true)
                  setEmployerDisplay(true)
                  setEmployerError(false)
                  setEmployerHelper('')
                  setEmployer(newInputValue)
                  setValid((validStatus) => ({
                    ...validStatus,
                    employer: false,
                  }))
                } else {
                  setEmployer(newInputValue)
                  setValid((validStatus) => ({
                    ...validStatus,
                    employer: true,
                  }))
                  setEmployerOpen(true)
                }
              } else {
                setEmployer('')
              }
            }}
            onClose={() => {
              setEmployerOpen(false)
            }}
            open={employerOpen}
            selectOnFocus
            handleHomeEndKeys
            options={[...new Set(companies)]}
            renderOption={(props, option) => <li {...props}>{option}</li>}
            sx={{
              '& .MuiOutlinedInput-root': {
                background: 'white',
                py: '0px',
                borderRadius: '8px',
                '& > fieldset': { borderColor: '#E2E8F0' },
              },
              '& .MuiOutlinedInput-root.Mui-focused': {
                '& > fieldset': {
                  borderColor: '#4B288E',
                },
              },
              '& .MuiInputLabel-root': { color: '#818080' },
            }}
            freeSolo
            renderInput={(params) => (
              <Grid>
                <TextField error={employerError} placeholder='e.g Tesla...' {...params} />
                <FormHelperText id='employerHelper' sx={{ color: 'red', m: '0px' }}>
                  {employerHelper}
                </FormHelperText>
              </Grid>
            )}
          />
          <FormControl sx={{ width: '100%' }}>
            <h1 className='calculator-paper-title'>Your annual income</h1>
            <Grid container maxWidth='100%'>
              <NumberFormat
                prefix='$'
                customInput={TextField}
                variant='outlined'
                type='tel'
                thousandSeparator={true}
                fullWidth
                value={annualIncome}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    background: 'white',
                    alignSelf: 'center',
                    borderRadius: '8px',
                    '& > fieldset': { borderColor: '#E2E8F0' },
                  },
                  '& .MuiOutlinedInput-root.Mui-focused': {
                    '& > fieldset': {
                      borderColor: '#4B288E',
                    },
                  },
                  '& .MuiOutlinedInput-input': {
                    py: '0.4em',
                    color: 'black',
                    fontWeight: '500',
                    fontSize: '18px',
                  },
                }}
                onChange={handleAnnualIncomeChange}
                autoComplete='off'
              />
            </Grid>
          </FormControl>
          <FormControl sx={{ width: '100%' }}>
            <h1 className='calculator-paper-title'>ESPP discount</h1>
            <Grid container justifyContent='space-between'>
              <Grid item xs={8.5} md={9}>
                <BennySlider
                  valueLabelDisplay='auto'
                  aria-label='pretto slider'
                  id='discount-slider'
                  disabled={discountDisabled}
                  defaultValue={15}
                  min={0}
                  max={maxDiscount}
                  step={5}
                  onChange={handleSliderDiscount}
                  value={typeof esppDiscount === 'number' ? esppDiscount : 0}
                />
              </Grid>
              <Grid item xs={2.5} md={2}>
                <NumberFormat
                  customInput={TextField}
                  variant='outlined'
                  id='discount-input'
                  thousandSeparator={true}
                  disabled={discountDisabled}
                  suffix=' %'
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      background: 'white',
                      py: '0px',
                      borderRadius: '8px',
                      '& > fieldset': { borderColor: '#E2E8F0' },
                    },
                    '& .MuiOutlinedInput-root.Mui-focused': {
                      '& > fieldset': {
                        borderColor: '#4B288E',
                      },
                    },
                    '& .MuiOutlinedInput-input': {
                      py: '0.4em',
                      px: '0.5em',
                      color: '#194339',
                      fontWeight: '400',
                    },
                  }}
                  isAllowed={esppDiscountLimit}
                  onChange={handleDiscountChange}
                  value={esppDiscount}
                  autoComplete='off'
                />
              </Grid>
            </Grid>
          </FormControl>
          <FormControl sx={{ width: '100%' }}>
            <h1 className='calculator-paper-title'>Max contribution allowed</h1>
            <Grid container justifyContent='space-between'>
              <Grid item xs={8.5} md={9}>
                <BennySlider
                  valueLabelDisplay='auto'
                  aria-label='pretto slider'
                  id='contribution-slider'
                  min={1}
                  step={1}
                  defaultValue={10}
                  onChange={handleSliderContribution}
                  onChangeCommitted={() => {
                    createEvent({ name: 'UsedContributionSlider' })
                  }}
                  value={typeof contribution === 'number' ? contribution : 0}
                  max={maxContrib}
                />
              </Grid>
              <Grid item xs={2.5} md={2}>
                <NumberFormat
                  customInput={TextField}
                  variant='outlined'
                  thousandSeparator={true}
                  suffix=' %'
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      background: 'white',
                      py: '0px',
                      borderRadius: '8px',
                      '& > fieldset': { borderColor: '#E2E8F0' },
                    },
                    '& .MuiOutlinedInput-root.Mui-focused': {
                      '& > fieldset': {
                        borderColor: '#4B288E',
                      },
                    },
                    '& .MuiOutlinedInput-input': {
                      py: '0.4em',
                      px: '0.5em',
                      color: '#194339',
                      fontWeight: '400',
                    },
                  }}
                  isAllowed={esppDiscountLimit}
                  onChange={handleContributionChange}
                  value={contribution}
                  autoComplete='off'
                />
              </Grid>
            </Grid>
          </FormControl>
        </Grid>

        <Grid
          container
          item
          md={6}
          xs={12}
          bgcolor='#1BCD90'
          borderRadius={{ xs: '0 0 16px 16px', md: '0 16px 16px 0' }}
          p='40px'
          border='1px solid #1BCD90'
          justifyContent='center'
          flexDirection='column'
        >
          <Typography
            sx={{
              fontFamily: 'Garnett-semibold',
              textAlign: 'center',
              fontSize: '18px',
              color: '#000',
            }}
          >
            Benny will fund up to
          </Typography>
          <Typography
            sx={{
              fontSize: '33px',
              color: '#000',
              fontFamily: 'Garnett-bold',
              textAlign: 'center',
              fontStyle: 'italic',
            }}
          >
            {formatToDollars(calculateBebbyFund())}/year
          </Typography>
          <div style={{}}>
            <Typography
              sx={{
                fontFamily: 'Garnett-regular',
                textAlign: 'center',
                fontSize: '18px',
                color: '#000',
              }}
            >
              towards your ESPP
            </Typography>

            <Typography
              sx={{
                fontFamily: 'Garnett-regular',
                textAlign: 'center',
                fontSize: '18px',
                color: '#000',
                display: step === 'step1' ? 'block' : 'none',
              }}
            >
              enabling a gain of{' '}
              <strong style={{ fontFamily: 'Garnett-semibold' }}>
                ~{formatToDollars(esppGain)}
              </strong>
            </Typography>
            <div
              id='calculator-step-2'
              style={{
                display: step === 'step2' ? 'block' : 'none',
              }}
              className='animate__animated animate__pulse'
            >
              <div
                style={{
                  height: '1px',
                  width: '85px',
                  backgroundColor: '#fff',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  boxShadow: '0px -1px 0px 0px rgba(0, 0, 0, 0.25)',
                  marginTop: '24px',
                  marginBottom: '24px',
                  opacity: 0.5,
                }}
              />

              <h1 className='calculator-paper-title' style={{ color: '#194339' }}>
                State of residence
              </h1>
              <Autocomplete
                id='stateInput'
                disablePortal
                value={state}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setState(newValue)
                    setValid((validStatus) => ({
                      ...validStatus,
                      state: false,
                    }))
                  } else {
                    setValid((validStatus) => ({
                      ...validStatus,
                      state: true,
                    }))
                  }
                }}
                options={states}
                fullWidth
                sx={{
                  '& .MuiOutlinedInput-root': {
                    background: 'white',
                    mt: '0.3em',
                    py: '0px',
                    borderRadius: '8px',
                    '& > fieldset': { borderColor: '#E2E8F0' },
                  },
                  '& .MuiOutlinedInput-root.Mui-focused': {
                    '& > fieldset': {
                      borderColor: '#4B288E',
                    },
                  },
                  '& .MuiInputLabel-root': { color: '#818080' },
                }}
                renderInput={(params) => <TextField placeholder='Please Select' {...params} />}
              />
              <FormControl sx={{ width: '100%' }}>
                <h1 className='calculator-paper-title' style={{ color: '#194339' }}>
                  Name
                </h1>
                <OutlinedInput
                  id='nameInput'
                  sx={{
                    backgroundColor: 'white',
                    borderRadius: '8px',
                    '& .MuiOutlinedInput-root': {
                      py: '0px',
                      '& > fieldset': { borderColor: '#E2E8F0' },
                    },
                    '& .MuiOutlinedInput-root.Mui-focused': {
                      '& > fieldset': {
                        borderColor: '#4B288E',
                      },
                    },
                    '& .MuiOutlinedInput-input': {
                      py: '0.5em',
                      color: '#194339',
                      fontWeight: '400',
                    },
                  }}
                  value={name}
                  onInput={(e) => {
                    setName(e.target.value)
                  }}
                  onChange={handleNameChange}
                  error={nameError}
                />
                <FormHelperText id='nameHelper' sx={{ color: 'red', m: '0px' }}>
                  {nameHelper}
                </FormHelperText>
              </FormControl>
              <FormControl sx={{ width: '100%' }}>
                <h1 className='calculator-paper-title' style={{ color: '#194339' }}>
                  Email
                </h1>
                <OutlinedInput
                  id='emailInput'
                  sx={{
                    backgroundColor: 'white',
                    borderRadius: '8px',
                    '& .MuiOutlinedInput-root': {
                      py: '0px',
                      '& > fieldset': { borderColor: '#E2E8F0' },
                    },
                    '& .MuiOutlinedInput-root.Mui-focused': {
                      '& > fieldset': {
                        borderColor: '#4B288E',
                      },
                    },
                    '& .MuiOutlinedInput-input': {
                      py: '0.5em',
                      color: '#194339',
                      fontWeight: '400',
                    },
                  }}
                  onChange={handleEmailChange}
                  value={email}
                  onInput={(e) => {
                    setEmail(e.target.value)
                  }}
                  type='email'
                  error={emailError}
                  disabled={!!client?.email}
                />
                <FormHelperText id='emailHelper' sx={{ color: 'red', m: '0px' }}>
                  {emailHelper}
                </FormHelperText>
              </FormControl>
              {leadId === '' ? (
                <Typography
                  className='calculator-paper-title'
                  onClick={showtReferralText}
                  sx={{
                    mt: '20px',
                    cursor: 'pointer',
                    display: showReferral ? 'none' : 'block',
                    color: '#4B288E',
                  }}
                >
                  I have a referral code
                </Typography>
              ) : null}
              <FormControl fullWidth sx={{ fontWeight: '400', fontSize: '17px', my: '1em' }}>
                <Typography
                  className='calculator-paper-title'
                  sx={{ display: showReferral ? 'block' : 'none', color: '#194339' }}
                >
                  Referral code(optional)
                </Typography>
                <OutlinedInput
                  id='referralInput'
                  sx={{
                    backgroundColor: 'white',
                    display: showReferral ? 'block' : 'none',
                    borderRadius: '8px',
                    '& .MuiOutlinedInput-root': {
                      py: '0px',
                      '& > fieldset': { borderColor: '#E2E8F0' },
                    },
                    '& .MuiOutlinedInput-root.Mui-focused': {
                      '& > fieldset': {
                        borderColor: '#4B288E',
                      },
                    },
                    '& .MuiOutlinedInput-input': {
                      py: '0.5em',
                      color: '#194339',
                      fontWeight: '400',
                    },
                  }}
                  value={referralCode}
                  onChange={handleReferralChange}
                  error={referralError}
                  type='text'
                />
                <FormHelperText id='referralHelper' sx={{ color: 'red', m: '0px' }}>
                  {referralHelper}
                </FormHelperText>
                <Typography
                  sx={{
                    fontWeight: '400',
                    fontSize: '16px',
                    display: codeFound === 'Valid' && codeBenefit > 0 ? 'block' : 'none',
                  }}
                >
                  Great! ${codeBenefit} discount will be applied to your application.
                </Typography>
                <Typography
                  sx={{
                    fontWeight: '400',
                    fontSize: '16px',
                    display: codeFound === 'Invalid' ? 'block' : 'none',
                    color: 'red',
                  }}
                >
                  {referralCode} is no longer valid. You can still apply without the code.
                </Typography>
                <Typography
                  sx={{
                    fontWeight: '400',
                    fontSize: '16px',
                    display: codeFound === 'Not found' ? 'block' : 'none',
                    color: 'red',
                  }}
                >
                  {referralCode} is not a valid code. Please check and try again. You can still
                  apply without the code.
                </Typography>
              </FormControl>
            </div>

            <button
              className={
                step === 'step1'
                  ? employer
                    ? 'calculator-button employer-added'
                    : 'calculator-button'
                  : disabledStatus
                  ? 'calculator-button-disabled'
                  : 'calculator-button'
              }
              style={{ backgroundColor: `${colors.primary}` }}
              onClick={handleCalculatorButton}
              disabled={disabled}
            >
              {loading ? (
                <CircularProgress size={25} sx={{ color: 'white', margin: 'auto' }} />
              ) : (
                <span>{step === 'step1' ? 'View Funding Details' : 'Submit'}</span>
              )}
            </button>
          </div>
        </Grid>
      </Grid>
    </Grid>
  )
}
