import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-mui'
import * as mui from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import * as Yup from 'yup'

import { Typography } from 'Components/Typography'
import { LabelVertical } from 'Components/LabelVertical'
import { userContext } from 'context/user'
import { graphqlClient, useQuery } from 'utils/graphql'

import { Layout } from '../Layout'
import { Login } from '../Login'


const requiredMsg = 'Required'
const validationSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, '8 characters minimum')
    .matches(/[a-z]/, 'Must contain at least one lowercase letter')
    .matches(/[A-Z]/, 'Must contain at least one uppercase letter')
    .matches(/[0-9]/, 'Must contain at least one number')
    .required(requiredMsg),
  password2: Yup.string()
    .required(requiredMsg)
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
})

export function SetPassword(props: {mode: 'set'|'reset'|'change'}) {
  const token = new URLSearchParams(window.location.search).get('token')
  const contextUser = React.useContext(userContext)

  const [commonErrors, setCommonErrors] = React.useState<string[]>([])

  const navigate = useNavigate()

  let user = {errors: undefined, ...contextUser}
  if (props.mode !== 'change') {
    user = useQuery(
      {userBySetPasswordToken: [
          {token},
          {
            client: {name: 1},
            email: 1,
          },
        ]},
      'userBySetPasswordToken'
    )
  }

  function handleSetPassword(password: string, password2: string, setFieldError: (f: string, e: string) => void) {
    let query: CallableFunction = graphqlClient.chain.mutation.setPassword({ token, password, password2 }).execute
    if (props.mode === 'change') {
      query = graphqlClient.chain.mutation.changePassword({ password, password2 }).execute
    }
    query({ __scalar: 1})
      .then(() => {
        contextUser.refetch()
        navigate('/customer/applications')
      })
      .catch(e => {
        const gqlError = e.errors[0].extensions
        if (gqlError.common_errors) {
          setCommonErrors(gqlError.common_errors)
        } else if (gqlError.field_errors) {
          for (const [field, error] of Object.entries(gqlError.field_errors)) {
            setFieldError(field, error as string)
          }
        } else {
          throw e
        }
      })
  }

  if (user.spinner) return user.spinner

  if (props.mode === 'change' && !user.data) return <Login />

  if (user.errors || (props.mode === 'change' && !user.data)) {
    return (
      <Layout>
        <Typography variant="h3" sx={{ color: 'black'}}>
          Oops!
        </Typography>
        <Typography sx={{ color: 'black'}}>
          {user.errors[0].extensions?.field_errors?.token || user.errors[0].message.includes('UUID')
            ? 'Invalid token. '
            : 'Unexpected error. '
          }
          Please contact support.
        </Typography>
      </Layout>
    )
  }

  return (
    <Layout>
      <Typography variant="h3" sx={{ color: 'black'}}>
        Hi {user.data?.client?.name || user.data?.email},
      </Typography>
      <Typography sx={{ color: 'black'}}>
        {props.mode === 'set'
          ? 'Welcome to Benny. Let’s set up your account.'
          : 'Please enter your new password.'
        }
      </Typography>
      <Formik
        initialValues={{ password: '', password2: '' }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, setFieldError }) => {
          handleSetPassword(values.password, values.password2, setFieldError)
          setSubmitting(false)
        }}
      >
        {({ submitForm, isSubmitting, touched, errors }) => (
          <Form>
            {commonErrors.map((error, i) => (
              <mui.Alert key={i} severity="error">{error}</mui.Alert>
            ))}
            <LabelVertical
              field={
                <Field
                  component={TextField}
                  error={touched.password && !!errors.password}
                  fullWidth
                  helperText={touched.password && errors.password}
                  InputProps={{
                    autoFocus: true,
                    fullWidth: true,
                    size: 'small',
                    type: 'password',
                }}
                  name="password"
                  required
                />
              }
              label='Password'
              sx={{ mt: 5 }}
            />
            <LabelVertical
              field={
                <Field
                  component={TextField}
                  error={touched.password2 && !!errors.password2}
                  helperText={touched.password2 && errors.password2}
                  InputProps={{ fullWidth: true, size: 'small', type: 'password' }}
                  name="password2"
                  fullWidth
                  required
                />
              }
              label='Confirm Password'
              sx={{ mb: 5, mt: 2 }}
            />
            <mui.Box sx={{
              display: 'flex',
              justifyContent: 'center',
            }}>
              <mui.Button disabled={isSubmitting} onClick={submitForm} variant="contained" type="submit">
                {props.mode === 'set' ? 'Create Account' : 'Save'}
              </mui.Button>
            </mui.Box>
          </Form>
        )}
      </Formik>
      {props.mode === 'set' && <>
        <Typography variant="body2" sx={{textAlign: 'center', mt: 7, fontSize: '18px'}}>
          By creating an account, you agree with Benny’s{' '}
          <mui.Link
            href="/terms"
            target="blank"
            sx={{textDecoration: 'none', color: theme => theme.palette.secondary.light}}
          >
            Terms of Service
          </mui.Link>{' '}
          and{' '}
          <mui.Link
            href="/privacy"
            target="blank"
            sx={{textDecoration: 'none', color: theme => theme.palette.secondary.light}}
          >
            Privacy Policy
          </mui.Link>
          .
        </Typography>
      </>}
    </Layout>
  )
}