import { useEffect } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

export const Partners = () => {
  const { partner } = useParams()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const queryParams = Object.fromEntries(searchParams.entries())

  if (queryParams['impactid']) {
    const impactidValue = queryParams['impactid']

    delete queryParams['impactid']

    queryParams['irclickid'] = impactidValue
  }

  const queryString =
    Object.keys(queryParams).length > 0 ? `&${new URLSearchParams(queryParams)}` : ''

  const redirectUrl = `/smartest-way-to-fund-espp?referral=${partner}${queryString}`

  useEffect(() => {
    if (partner) {
      navigate(redirectUrl)
    }
  }, [partner, navigate, redirectUrl])

  return null
}
