import loadable from '@loadable/component'
import { Grid } from '@mui/material'
import { ReactElement, useCallback } from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate, useParams } from 'react-router-dom'
import Footer from '../../Components/common/Footer'
//import Header from '../../Components/common/websiteheader'

import { WrapperHeader } from '../../Components/header'
import ReferralBanner from '../../Components/kb_landing_page/banners/referral_banner'
import { HeroSectionV3 } from '../../Components/kb_landing_page/hero_section/hero_section'
import { CtaAction, SectionTypes, VariantConfig } from './config'
/* import { BannerConfirmAccount } from '../../Components/bannerConfirmAccount'
 */

const Calculator = loadable(() => import('../../Components/kb_landing_page/calculator/calculator'))
const CTABanner = loadable(() => import('../../Components/common/banners/ctabanner'))
const Faqs = loadable(() => import('../../Components/kb_landing_page/faqs/faqs_v3'))
const HowDoesItWorkV2 = loadable(() => import('../../Components/howdoesitwork/variantV2'))
const Testimonials = loadable(() => import('../../Components/common/testimonials/testimonials'))
const Features = loadable(() => import('../../Components/common/features/features'))

export function Home({ forceTrack, variant }: { variant: VariantConfig; forceTrack?: boolean }) {
  const navigate = useNavigate()

  let { prereferral } = useParams()

  const handleCtaClick = useCallback((action: CtaAction) => {
    switch (action.type) {
      case 'route':
        const url = !!prereferral ? `${action.value}&referral=${prereferral}` : action.value
        navigate(url)
        break
      case 'scroll':
        break
      default:
        break
    }
  }, [])

  const getSection = useCallback(
    (currentVariant: VariantConfig, section: SectionTypes): ReactElement | null => {
      switch (section) {
        case SectionTypes.HeroSection:
          return <HeroSectionV3 {...currentVariant.heroSection} onCtaClick={handleCtaClick} key={section} />
        case SectionTypes.Calculator:
          return <Calculator key={section} />
        case SectionTypes.Features:
          return <Features origin={currentVariant.origin} key={section} />
        case SectionTypes.Testimonials:
          return <Testimonials origin={currentVariant.origin} key={section} />
        case SectionTypes.HowDoesItWorkV2:
          return <HowDoesItWorkV2 origin={currentVariant.origin} key={section} />
        case SectionTypes.Faqs:
          return <Faqs key={section} />
        case SectionTypes.CTABanner:
          return <CTABanner origin={currentVariant.origin} key={section} />
        default:
          return null
      }
    },
    [],
  )

  return (
    <Grid>
      <Helmet>
        <title>Benny, Employee Stock Purchase Plans, ESPP</title>
        <meta property='og:site_name' content='Benny, Employee Stock Purchase Plans, ESPP' />
        <meta property='og:title' content='Benny, Employee Stock Purchase Plans, ESPP' />
        <meta property='og:url' content='https://www.heybenny.com' />
        <meta property='og:type' content='website' />
        <meta
          property='og:description'
          content='Providing Employee Stock Purchase Plan (ESPPs) essentials: Education and Cash for Participation.'
        />
        <meta
          property='og:image'
          content='https://heybenny.com/assets/og-image-benny-0efcb29b4c16f5e4ad1fc9e73a95f89b952cebaf6d1c90cc65bacfc7a76444d5.png'
        />
        <meta
          property='og:image:url'
          content='https://heybenny.com/assets/og-image-benny-0efcb29b4c16f5e4ad1fc9e73a95f89b952cebaf6d1c90cc65bacfc7a76444d5.png'
        />
        <meta itemProp='name' content='Benny, Employee Stock Purchase Plans, ESPP' />
        <meta itemProp='url' content='https://www.pepito.com' />
        <meta
          itemProp='description'
          content='Providing Employee Stock Purchase Plan (ESPPs) essentials: Education and Cash for Participation.'
        />
        <meta
          property='twitter:image'
          content='https://heybenny.com/assets/og-image-benny-0efcb29b4c16f5e4ad1fc9e73a95f89b952cebaf6d1c90cc65bacfc7a76444d5.png'
        />
        <meta name='twitter:title' content='Benny, Employee Stock Purchase Plans, ESPP' />
        <meta name='twitter:url' content='https://www.heybenny.com' />
        <meta name='twitter:card' content='summary_large_image' />
        <meta
          name='twitter:description'
          content='Providing Employee Stock Purchase Plan (ESPPs) essentials: Education and Cash for Participation.'
        />
        <meta
          name='description'
          content='Providing Employee Stock Purchase Plan (ESPPs) essentials: Education and Cash for Participation.'
        />
        <link rel='canonical' href='https://heybenny.com' />
      </Helmet>
      
      {/* <BannerConfirmAccount /> */}
      <ReferralBanner />
      {/* <Header
        {...variant.header}
        onCtaClick={handleCtaClick}
        cta={{ text: 'Get Started', action: { type: 'route', value: '/apply?s=nav_get_started' } }}
      /> */}

      <WrapperHeader extraQueryParams={{ s: 'nav_get_started' }} />

      {variant?.sectionsOrder.map((section) => getSection(variant, section))}
      <Footer />
    </Grid>
  )
}

Home.defaultProps = {
  forceTrack: false,
}
