import Cookies from 'js-cookie'
import React from 'react'

import {QueryResponse} from '../types/graphql'
import {createClient} from '../types/graphql/createClient'
import {Query, QueryRequest} from '../types/graphql/schema'
import {CenteredSpinner} from '../Components/CenteredSpinner'


export const graphqlClient = createClient({
  fetcher: async (query, fetch) => {
    if (!Cookies.get('csrftoken')) {
      await fetch(`${process.env.REACT_APP_SERVER_URL}/webhook`, { credentials: 'include' })
    }
    return fetch(
      `${process.env.REACT_APP_SERVER_URL}/gql-api`,
      {
        method: "POST",
        body: JSON.stringify(query),
        credentials: 'include',
        headers: {"Content-Type": "application/json"}
      }
    )
    .then(r => r.json())
  }
})

type UseQueryBaseReturnType = {
  errors: QueryResponse['errors']
  isFetching: boolean
  refetch: React.DispatchWithoutAction
  spinner: JSX.Element | null
}

export function useQuery(query: QueryRequest): UseQueryBaseReturnType & {data: Query}
export function useQuery<F extends keyof Query>(query: QueryRequest, field: F): UseQueryBaseReturnType & {data: Query[F]}
export function useQuery<F extends keyof Query>(query: QueryRequest, field?: F) {
  type dataType = F extends keyof Query ? Query[F] : Query
  const [data, setData] = React.useState<dataType>()
  const [errors, setErrors] = React.useState<QueryResponse['errors']>()
  const [isFetching, setIsFetching] = React.useState(false)
  const [hasResponse, setHasResponse] = React.useState(false)
  const [spinner, setSpinner] = React.useState<JSX.Element|null>(<CenteredSpinner />)

  const refetch = React.useReducer(() => {
    setHasResponse(false)
    setErrors(undefined)
  }, undefined)[1]

  React.useEffect(() => {
    if (!hasResponse && !isFetching) {
      setIsFetching(true)
      graphqlClient
        .query(query)
        .then(response => {
          setErrors(response.errors)
          let resData
          if (field && response.data) {
            resData = (response.data as any)[field] as dataType
          } else {
            resData = response.data as unknown as dataType
          }
          response.data ? setData(resData) : setData(undefined)
          setHasResponse(true)
          setIsFetching(false)
        })
    }
  }, [hasResponse, isFetching])

  React.useEffect(() => {
    if ((!data && isFetching) || !hasResponse) {
      setSpinner(<CenteredSpinner />)
    } else {
      setSpinner(null)
    }
  }, [data, isFetching, hasResponse, errors])

  if (errors && errors.length) console.warn(errors)

  return {
    data,
    errors,
    isFetching,
    refetch,
    spinner,
  }
}