import { useEffect } from 'react'

const canUseDOM = !!(
  typeof window !== 'undefined' &&
  window.document &&
  window.document.createElement
)

const IntercomAPI = (...args) => {
  if (canUseDOM && window.Intercom) {
    window.Intercom.apply(null, args)
  } else {
    console.warn('Intercom not initialized yet')
  }
}

const useIntercom = (appID, otherProps) => {
  useEffect(() => {
    if (!appID || !canUseDOM) return

    if (!window.Intercom) {
      ;((w, d, id) => {
        const i = function () {
          i.c(arguments)
        }
        i.q = []
        i.c = function (args) {
          i.q.push(args)
        }
        w.Intercom = i
        const s = d.createElement('script')
        s.async = 1
        s.src = 'https://widget.intercom.io/widget/' + id
        s.onload = () => console.log('Intercom script loaded successfully.')
        s.onerror = (e) => console.error('Intercom script failed to load:', e)
        d.head.appendChild(s)
      })(window, document, appID)
    }

    window.intercomSettings = { ...otherProps, app_id: appID }
    window.Intercom('boot', otherProps)

    return () => {
      if (window.Intercom) {
        window.Intercom('shutdown')
        delete window.Intercom
        delete window.intercomSettings
      }
    }
  }, [appID, otherProps])
}

const Intercom = ({ appID, ...otherProps }) => {
  useIntercom(appID, otherProps)
  return null
}

export { Intercom, IntercomAPI }
