import { Field } from 'formik'
import * as mui from '@mui/material'
import React from 'react'

import { DecimalLimitedTextField } from 'Components/Fields'
import { Typography } from 'Components/Typography'

import { formatDate } from '../Contributions'
import { EstimatedTaxSection } from './EstimatedTaxSection'


export function Taxes(props: {
  bennyFundAmount: number
  companyName: string
  longTermGainsTax: number
  offeringDate: Date
  offeringDateFmv: number
  ordinaryIncomeTax: number
  purchaseDate: Date
  purchaseDateFmv: number
  purchasePrice: number
  saleDate: string
  salePrice: number
  sharesAmount: number
}) {
  let dates = [new Date(props.purchaseDate), new Date(props.offeringDate), new Date(props.offeringDate)]
  dates[0].setFullYear(props.purchaseDate.getFullYear() + 1)
  dates[1].setFullYear(props.offeringDate.getFullYear() + 2)
  dates[2].setFullYear(props.offeringDate.getFullYear() + 2)

  return (
    <>
      <mui.Stack sx={{width: '100%', pb: 4, borderBottom: 1, borderColor: 'divider'}} gap={2}>
        <Typography sx={{color: 'black'}}><b>Your Tax Rates</b></Typography>

        <mui.Stack direction="row" sx={{width: '100%', justifyContent: 'space-between', alignItems: 'center'}}>
          <Typography sx={{color: 'black'}}>Ordinary Income</Typography>
          <Field
            component={DecimalLimitedTextField}
            fullWidth
            InputProps={{
              size: 'small',
              endAdornment: <mui.InputAdornment position='end'>%</mui.InputAdornment>,
            }}
            decimalPlaces={0}
            name="ordinaryIncomeTax"
            required
            sx={{width: '70px', backgroundColor: 'white'}}
          />
        </mui.Stack>

        <mui.Stack direction="row" sx={{width: '100%', justifyContent: 'space-between', alignItems: 'center'}}>
          <Typography sx={{color: 'black'}}>Long Term Capital Gains</Typography>
          <Field
            component={DecimalLimitedTextField}
            fullWidth
            InputProps={{
              size: 'small',
              endAdornment: <mui.InputAdornment position='end'>%</mui.InputAdornment>,
            }}
            decimalPlaces={0}
            name="longTermGainsTax"
            required
            sx={{width: '70px', backgroundColor: 'white'}}
          />
        </mui.Stack>
      </mui.Stack>

      <mui.Stack sx={{ width: '100%', py: 4, borderBottom: 1, borderColor: 'divider' }} gap={2}>
        <Typography sx={{color: 'black'}}><b>ESPP Purchase</b></Typography>

        <Typography sx={{color: 'black'}}>
          Invested <b>${
            (props.sharesAmount * props.purchasePrice).toLocaleString('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          }</b> to purchase{' '}
          <b>{props.sharesAmount} {props.companyName} shares</b> on {formatDate(props.purchaseDate)},{' '}
          at ${props.purchasePrice.toFixed(2)} per share.
        </Typography>

        <mui.Paper
          sx={{
            backgroundColor: 'transparent',
            borderRadius: '12px',
            border: '1px solid #60CA95',
            boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
            mb: 1,
            px: 3,
            py: 4,
          }}
        >
          <Typography sx={{ color: 'black', pb: 4 }}>
            Benny <b>funds your contributions</b>, allowing you to maximize your ESPP without reducing your take-home
            pay.
          </Typography>
          <mui.Link href={`/apply?step=about_your_espp&employer=${props.companyName}s=calculator_page_chart`}>
            Start your application today!
          </mui.Link>
        </mui.Paper>
      </mui.Stack>

      <mui.Stack sx={{ width: '100%', py: 4 }} gap={2}>
        <Typography sx={{color: 'black'}}><b>Estimated Tax</b></Typography>

        <mui.Stack direction="row" sx={{ width: '100%', alignItems: 'center' }} spacing={2}>
          <Typography sx={{color: 'black'}}>Sale Price</Typography>
          <Field
            component={DecimalLimitedTextField}
            fullWidth
            InputProps={{
              size: 'small',
              startAdornment: <mui.InputAdornment position='start'>$</mui.InputAdornment>,
            }}
            name="salePrice"
            required
            sx={{ width: '100px', backgroundColor: 'white' }}
          />
        </mui.Stack>

        <Typography sx={{color: 'black'}}>If you sell:</Typography>
        
        <EstimatedTaxSection
          title={`Before ${formatDate(dates[0])}`}
          costBasis={props.purchaseDateFmv * props.sharesAmount}
          isShortTerm={true}
          ordinaryIncome={(props.purchaseDateFmv - props.purchasePrice) * props.sharesAmount}
          ordinaryIncomeTax={props.ordinaryIncomeTax}
          longTermGains={props.salePrice * props.sharesAmount - props.purchaseDateFmv * props.sharesAmount}
          longTermGainsTax={props.ordinaryIncomeTax}
          profit={props.salePrice * props.sharesAmount - props.bennyFundAmount}
          sharesAmount={props.sharesAmount}
        />

        <EstimatedTaxSection
          title={`Before ${formatDate(dates[1])}`}
          costBasis={props.purchaseDateFmv * props.sharesAmount}
          isShortTerm={false}
          ordinaryIncome={(props.purchaseDateFmv - props.purchasePrice) * props.sharesAmount}
          ordinaryIncomeTax={props.ordinaryIncomeTax}
          longTermGains={props.salePrice * props.sharesAmount - props.purchaseDateFmv * props.sharesAmount}
          longTermGainsTax={props.longTermGainsTax}
          profit={props.salePrice * props.sharesAmount - props.bennyFundAmount}
          sharesAmount={props.sharesAmount}
        />

        <EstimatedTaxSection
          title={`After ${formatDate(dates[2])}`}
          costBasis={props.offeringDateFmv * props.sharesAmount}
          isShortTerm={false}
          ordinaryIncome={props.offeringDateFmv * props.sharesAmount - props.bennyFundAmount}
          ordinaryIncomeTax={props.ordinaryIncomeTax}
          longTermGains={props.salePrice * props.sharesAmount - props.offeringDateFmv * props.sharesAmount}
          longTermGainsTax={props.longTermGainsTax}
          profit={props.salePrice * props.sharesAmount - props.bennyFundAmount}
          sharesAmount={props.sharesAmount}
        />
      </mui.Stack>
    </>
  )
}