import axios from 'axios'
import { useEffect, useState } from 'react'

export const useFetchEsppDetails = (esppId, secret_key) => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState('none')
  const [email, setEmail] = useState('none')
  const [phone, setPhone] = useState('')
  const [income, setIncome] = useState('65000')
  const [state, setState] = useState('none')
  const [contribution, setContribution] = useState(null)
  const [maxContribPercentage, setMaxContribPercentage] = useState(null)
  const [yearly_gain, setYearlyGain] = useState(null)
  const [espp_discount, setEsppDiscount] = useState(null)
  const [monthly_gain, setMonthlyGain] = useState(null)
  const [contr_dollar_limit, setContr_dollar_limit] = useState(null)
  const [employer, setCompany] = useState(null)
  const [enrollment_deadline, setEnrollmentDeadline] = useState(null)
  const [purchase_period_length, setPurchasePeriodLength] = useState(null)
  const [period_contr_dollar_limit, setPeriod_contr_dollar_limit] = useState(null)
  const [dollarLimit, setDollarLimit] = useState(0)

  const handleCalculator = () => {
    let yearly_gain = 0
    let monthly_gain = 0
    let annual_income = income
    annual_income = annual_income.replace('$', '')
    annual_income = annual_income.replace(',', '')
    annual_income = Number(annual_income)
    yearly_gain =
      (annual_income * (espp_discount / 100) * (contribution / 100)) / (1 - espp_discount / 100) <
      25000 * (espp_discount / 100)
        ? (annual_income * (espp_discount / 100) * (contribution / 100)) / (1 - espp_discount / 100)
        : 25000 * (espp_discount / 100)
    monthly_gain =
      (annual_income * (contribution / 100)) / 12 < (25000 * (1 - espp_discount / 100)) / 12
        ? (annual_income * (contribution / 100)) / 12
        : (25000 * (1 - espp_discount / 100)) / 12
    setMonthlyGain(monthly_gain.toFixed())
    setYearlyGain(yearly_gain.toFixed())
  }

  const calculateBennyFund = () => {
    const cleanedAnnualIncome = income.replace(/[$,]/g, '')
    const numericAnnualIncome = parseFloat(cleanedAnnualIncome === '' ? 0 : cleanedAnnualIncome)
    const numericContribution = parseFloat(contribution)
    let bennyFund

    if (dollarLimit === 0) {
      const maxLimitPerYear = 25000 * (1 - espp_discount / 100)
      if (numericAnnualIncome * (numericContribution / 100) > maxLimitPerYear) {
        bennyFund = maxLimitPerYear
      } else {
        bennyFund = numericAnnualIncome * (numericContribution / 100)
      }
    } else if (numericAnnualIncome * (numericContribution / 100) < dollarLimit) {
      bennyFund = numericAnnualIncome * (numericContribution / 100)
    } else {
      bennyFund = dollarLimit
    }

    return bennyFund
  }

  useEffect(() => {
    setLoading(true)
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/espp/landing_page/${esppId}/`, {
        headers: {
          'Content-Type': 'application/json',
          'benny-api-key': process.env.REACT_APP_CORE_API_KEY,
          'benny-api-secret': secret_key,
        },
      })
      .then((response) => {
        const { data } = response ?? {}
        // Aquí podrías realizar tus operaciones con los datos y luego...
        if (data.status === 'not_found') {
          return setData(null)
        }
        setData(data)
      })
      .catch((error) => {
        console.log('ERRORCHIS', error)
        setData(null)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [esppId])

  useEffect(() => {
    if (data !== null) {
      document.title =
        data.espp.company_name + ' Employee Stock Purchase Plan (ESPP), financed with Benny'

      // Calculat max contribution
      const discountedmax = 25000 * (1 - data.discount)
      let max_contribution_amount = 0

      if (data.contr_dollar_limit) {
        setDollarLimit(data.contr_dollar_limit)
      }

      if (data.contr_dollar_limit === 0) {
        max_contribution_amount = discountedmax
      } else {
        if (data.contr_dollar_limit > discountedmax) {
          max_contribution_amount = discountedmax
        } else {
          max_contribution_amount = data.contr_dollar_limit
        }
      }
      let company = data.espp.company_name
      let discount = data.espp.discount
      let max_contrib_percentage = data.espp.contr_percent_limit * 100
      setEsppDiscount(discount * 100)
      setMaxContribPercentage(max_contrib_percentage)
      company = company.replaceAll(' ', '-')
      company = company.replaceAll("'", '-')
      setPurchasePeriodLength(data.espp.purchase_period_length)
      setCompany(data.espp.company_name)
      setEnrollmentDeadline(data.enrollment_deadline)
      setContribution(max_contrib_percentage)
      setPeriod_contr_dollar_limit(data.espp.period_contr_dollar_limit)
      setContr_dollar_limit(data.espp.contr_dollar_limit)
    }
  }, [data])

  useEffect(() => {
    handleCalculator()
  }, [contribution, income])

  return {
    data,
    loading,
    name,
    setName,
    email,
    setEmail,
    phone,
    setPhone,
    state,
    setState,
    income,
    setIncome,
    contribution,
    setContribution,
    maxContribPercentage,
    yearly_gain,
    monthly_gain,
    employer,
    espp_discount,
    enrollment_deadline,
    contr_dollar_limit,
    purchase_period_length,
    period_contr_dollar_limit,
    bennyFund: calculateBennyFund(),
  }
}
