import EastIcon from '@mui/icons-material/East'
import { Box, Grid, Typography } from '@mui/material'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { needsChromeUpdate } from 'utils/getChromeVersion'
import Footer from '../../../Components/common/Footer'
import Header from '../../../Components/common/header'
import { CustomButton } from '../../../Components/CustomButton'
import { LoadingDots } from '../../../Components/LoadingDots'
import { useGetApplicationById } from '../../../hooks/useGetApplicationById'
import { bennyApi } from '../../../utils/bennyApi'
import { CustomerTestimonial } from './components/CustomerTestimonial'
import { EmploymentVerificationUploader } from './components/EmploymentVerificationUploader'
import { TrustedByEmployees } from './components/TrustedByEmployees'

export const VerifyEmployment = () => {
  const [newfile, setNewfile] = useState<File>(null)
  const [loading, setLoading] = useState<boolean>(false)

  const { applicationId } = useParams()
  const { application, mostRecentVerification } = useGetApplicationById(applicationId)

  const navigate = useNavigate()

  const handleSubmit = async () => {
    setLoading(true)
    const file = newfile
    const formData = new FormData()
    console.log('FILE', file)
    try {
      formData.append('file', file)
      formData.append('applicationId', application.id)
      formData.append('verificationMethod', 'Manual')
      formData.append('submission_type', 'term_sheet')
      formData.append('verification_id', mostRecentVerification.id)
      await bennyApi.post(`/espp/submitApplication/`, formData)
      navigate(`/application/${application.id}`, { replace: true })
    } catch (error) {
      console.log('There is no file', error)
    }
  }

  if (!application) {
    return <LoadingDots />
  }

  const needsUpdate = needsChromeUpdate()

  return (
    <Grid sx={{ background: '#fff' }}>
      <Header />

      {needsUpdate && (
        <Box
          display={'flex'}
          bgcolor={'#E9E9E9'}
          p={'16px'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Typography textAlign={'center'}>
          You are using an older version of Chrome, which may limit website functionality.
            <br />
            Please consider{' '}
            <a
              href='https://www.google.com/chrome/'
              target='_blank'
              style={{ textDecoration: 'underline', cursor: 'pointer', color: 'inherit' }}
            >
              upgrading to the latest version
            </a>{' '}
            for the best experience.
          </Typography>
        </Box>
      )}

      <Grid container my={'5em'}>
        <Grid container mx='auto' sx={{ px: { xs: '1em', md: '0em' } }} my={'5em'}>
          <EmploymentVerificationUploader
            loanAmount={application.loan_amount}
            newfile={newfile}
            setNewfile={setNewfile}
            sendVerification={handleSubmit}
            loading={loading}
            needsUpdate={needsUpdate}
          />
        </Grid> 

        <Grid container my={'32px'}>
          <TrustedByEmployees />
        </Grid>

        <Grid container mx='auto' sx={{ px: { xs: '1em', md: '0em' } }} my={'32px'}>
          <CustomerTestimonial />
        </Grid>

        <Grid container mx='auto' sx={{ px: { xs: '1em', md: '0em' } }} my={'32px'}>
          <Grid container justifyContent='center' margin={'0 auto'} maxWidth={'950px'}>
            <div style={{ width: '1px', height: '65px', backgroundColor: '#60CA95' }} />
          </Grid>
        </Grid>

        <Grid container mx='auto' sx={{ px: { xs: '1em', md: '0em' } }} my={'32px'}>
          <Grid
            container
            flexDirection={'column'}
            justifyContent='center'
            alignItems={'center'}
            margin={'0 auto'}
            maxWidth={'950px'}
            gap={'32px'}
          >
            <Typography
              textAlign={'center'}
              color={'#242424'}
              fontSize={{ xs: '21px', md: '24px' }}
              fontFamily={'Garnett-Regular'}
              lineHeight={'normal'}
            >
              Strengthen your financial life.
              <br />
              Maximize your ESPP with Benny.
            </Typography>
            <CustomButton
              to='EmploymentVerificationUploader'
              title='Upload Pay Stub Now'
              variant={'primary'}
              buttonProps={{
                endIcon: <EastIcon />,
                style: { backgroundColor: '#9747FF' },
              }}
            />
          </Grid>
        </Grid>
      </Grid>

      <Footer />
    </Grid>
  )
}
