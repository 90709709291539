import { Box, Button, Container, Paper, Typography } from '@mui/material'
import { Component, ErrorInfo, ReactNode } from 'react'

interface Props {
  children: ReactNode
}

interface State {
  hasError: boolean
  errorMessage?: string
}

export class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: Error): State {
    return { hasError: true, errorMessage: error.message }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('ErrorBoundary caught an error', error, errorInfo)
  }

  handleRetry = () => {
    console.log(this)
    window.location.reload()
  }

  render() {
    const { children } = this.props
    const { hasError, errorMessage } = this.state

    if (hasError) {
      return (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          <Container component='main' maxWidth='xs'>
            <Paper elevation={3}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  padding: 3,
                }}
              >
                <Typography variant='h5' gutterBottom>
                  Oops! Something went wrong.
                </Typography>
                <Typography variant='body2' color='textSecondary' gutterBottom>
                  Error: {errorMessage}
                </Typography>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={this.handleRetry}
                  sx={{
                    background: 'linear-gradient(106.91deg, #60CA95 -0.41%, #4B288E 71.48%)',
                    '&:hover': {
                      background: 'linear-gradient(106.91deg, #60CA95 -0.41%, #4B288E 71.48%)', // Esto es para mantener el mismo gradiente cuando el botón es pasado por encima.
                    },
                  }}
                >
                  Try Again
                </Button>
              </Box>
            </Paper>
          </Container>
        </Box>
      )
    }

    return children
  }
}
