import * as mui from '@mui/material'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import React from 'react'


export function ResultsItem({ label, value, tooltip }: {label: string, value: string, tooltip?: string}) {
  return (
    <mui.Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1}}>
      <mui.Typography variant="subtitle1">
        {label}
        {tooltip && (
          <mui.Tooltip title={tooltip}>
            <mui.IconButton size="small">
              <HelpOutlineIcon fontSize="inherit"/>
            </mui.IconButton>
          </mui.Tooltip>
        )}
      </mui.Typography>
      <mui.Typography variant="subtitle1">{value}</mui.Typography>
    </mui.Box>
  )
}