import { useEffect } from 'react'
import { useStatesSelector } from '../store/states'
import { bennyApi } from '../utils/bennyApi'

export const useStates = () => {
  const statesList = useStatesSelector((state) => state.statesList)
  const addStates = useStatesSelector((state) => state.addStates)

  useEffect(() => {
    const fetchStates = async () => {
      if (statesList.length === 0) {
        try {
          const response = await bennyApi.get<{ full_name: string }[]>('/espp/states/')
          const options = response.data.map((state) => state.full_name)
          const sortedData = options.sort()
          addStates(sortedData)
        } catch (error) {
          console.error(error)
        }
      }
    }

    fetchStates()
  }, [statesList.length, addStates])

  return { statesList }
}
