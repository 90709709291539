import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import * as mui from '@mui/material'
import { Field, Formik } from 'formik'
import { useEffect, useState } from 'react'
import NumberFormat from 'react-number-format'
import * as yup from 'yup'

import { CustomSelectField, CustomSliderField, CustomTextField } from 'Components/Fields'
import { LabelVertical } from 'Components/LabelVertical'
import { getTextStyleVariant, Typography } from 'Components/Typography/Typography'
import { calculateBennyFund, formatToDollars } from 'utils/commons'

import { useSearchParams } from 'react-router-dom'
import { SendCopy } from '../sendCopy'
import { IESPP } from '../types'
import { Gains } from './Gains'
import { Taxes } from './Taxes'

export function formatDate(date: Date) {
  return `${date.toLocaleDateString('en-US', {
    timeZone: 'UTC',
    month: 'short',
  })} ${date.getUTCDate()}, ${date.getUTCFullYear()}`
}

function parseValueToNumber(value: string) {
  const numericValue = value.replace(/[^0-9.-]+/g, '')
  return Number(numericValue)
}

function findCurrentAndNextPeriods(periods: IESPP['dates']) {
  if (!periods) return { currentPeriod: null, nextPeriod: null }

  const sortedPeriods = periods.sort(
    (a, b) => new Date(a.start_date).getTime() - new Date(b.start_date).getTime(),
  )
  const today = new Date()
  const nextPeriodIndex = sortedPeriods.findIndex((period) => new Date(period.start_date) > today)
  const currentPeriodIndex = nextPeriodIndex > 0 ? nextPeriodIndex - 1 : 0

  const currentPeriod = sortedPeriods[currentPeriodIndex] || null
  const nextPeriod = sortedPeriods[nextPeriodIndex] || null

  return { currentPeriod, nextPeriod }
}

const validationSchema = yup.object({
  contributionPercentage: yup.number().required('Required'),
  estimatedPrice: yup.string().required('Required'),
  longTermGainsTax: yup.number().required('Required').positive(),
  offeringDate: yup.string().required('Required'),
  offeringDateFmv: yup.number().required('Required').positive(),
  ordinaryIncomeTax: yup.number().required('Required').positive(),
  participationPeriod: yup.string().required('Required'),
  salePrice: yup.number().required('Required').positive(),
  sharePrice: yup.string().required('Required'),
})

type SchemaType = yup.InferType<typeof validationSchema>

function TabPanel(props: {
  children: React.ReactNode
  index: number
  name: string
  value: number
}) {
  return (
    <div
      aria-labelledby={`tab-${props.name}-${props.index}`}
      hidden={props.value !== props.index}
      id={`tabpanel-${props.name}-${props.index}`}
      role='tabpanel'
      style={{ width: '100%' }}
    >
      {props.value === props.index && (
        <mui.Grid container mt={{ xs: '16px', md: '48px' }}>
          {props.children}
        </mui.Grid>
      )}
    </div>
  )
}

interface ContributionsProps {
  price: number | null
  espp?: IESPP
  income: number
}

export const Contributions = ({ price, espp, income }: ContributionsProps) => {
  const [showAwesome, setShowAwesome] = useState(false)

  const [currentRootTab, setCurrentRootTab] = useState(0)
  const [currentResultsTab, setCurrentResultsTab] = useState(0)
  const [searchParams, setSearchParams] = useSearchParams()

  const companyName = espp?.company?.name ?? ''
  const discountPercentage = Number(espp?.discount ?? 0) * 100 ?? 0
  const enrollTimes = 12 / espp?.purchase_period_length ?? 0
  const contributionPercentage = Number(espp?.contr_percent_limit) * 100 ?? 0
  const lookback = espp?.lookback ?? false

  const { currentPeriod, nextPeriod } = findCurrentAndNextPeriods(espp?.dates)
  const periods: IESPP['dates'] = []
  if (currentPeriod) periods.push(currentPeriod)
  if (nextPeriod) periods.push(nextPeriod)

  const firstStartDate = new Date(periods[0]?.start_date)
  const offeringDateOptions = []

  const onOpenShowAwesome = () => {
    setShowAwesome(true)
  }

  const onCloseShowAwesome = () => {
    setShowAwesome(false)
  }

  for (
    let date = new Date(firstStartDate);
    date >=
    new Date(firstStartDate.getFullYear() - 2, firstStartDate.getMonth(), firstStartDate.getDate());
    date.setMonth(date.getMonth() - espp?.purchase_period_length)
  ) {
    offeringDateOptions.push({
      label: formatDate(date),
      value: date.toISOString(),
    })
  }

  periods.map((period) => {
    const startDate = period?.start_date ? new Date(period?.start_date) : new Date()
    const label = startDate.toLocaleDateString('en-US', {
      timeZone: 'UTC',
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    })
    return { label, value: period.id }
  })

  const participationPeriodOptions = periods.map((period) => {
    const startDate = period?.start_date ? new Date(period?.start_date) : new Date()
    const endDate = period?.end_date ? new Date(period?.end_date) : new Date()
    const label = `${startDate.toLocaleDateString('en-US', {
      timeZone: 'UTC',
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    })} - ${endDate.toLocaleDateString('en-US', {
      timeZone: 'UTC',
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    })}`
    return { label, value: period.id }
  })

  const initialValues = {
    contributionPercentage:
      Number(searchParams.get('contributionPercentage')) || contributionPercentage,
    estimatedPrice: searchParams.get('estimatedPrice') || String(price),
    sharePrice: searchParams.get('sharePrice') || String(price),
    participationPeriod:
      searchParams.get('participationPeriod') || participationPeriodOptions[0].value,
  }

  useEffect(() => {
    let urlUpdated = false
    const params = new URLSearchParams(searchParams)

    Object.entries(initialValues).forEach(([key, newValue]) => {
      const currentValue = params.get(key)
      if (String(newValue) !== currentValue) {
        params.set(key, String(newValue))
        urlUpdated = true
      }
    })

    if (urlUpdated) {
      setSearchParams(params)
    }
  }, [initialValues])

  return (
    <mui.Grid container position={'relative'}>
      <mui.Grid container sx={{ width: '100%' }} px={{ xs: 0, md: '100px' }}>
        <mui.Grid
          container
          sx={{ borderBottom: 1, borderColor: 'divider' }}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <mui.Tabs value={currentRootTab} onChange={(e, v) => setCurrentRootTab(v)}>
            <mui.Tab
              label='Contributions and Gains'
              id='tab-root-0'
              aria-controls='tabpanel-root-0'
              sx={{ textTransform: 'none', ...getTextStyleVariant('') }}
            />
          </mui.Tabs>

          <SendCopy customOnSubmit={onOpenShowAwesome} />
        </mui.Grid>
        <TabPanel index={0} name='root' value={currentRootTab}>
          {showAwesome && (
            <mui.Box
              bgcolor={'#60CA95'}
              width={'80%'}
              p={'20px'}
              borderRadius={'12px'}
              mx={'auto'}
              position={'relative'}
            >
              <div
                style={{
                  position: 'relative',
                  bottom: 0,
                  top: -0,
                  cursor: 'pointer',
                  display: 'flex',
                  justifyContent:'space-between',
                  width:'100%'
                }}
                
              >
                <div></div>
              <Typography variant='body2' textAlign={'center'} sx={{ fontWeight: '700' }}>
                Awesome!
              </Typography>
                <HighlightOffIcon onClick={onCloseShowAwesome} sx={{ color: '#194339'}} />
              </div>
              <br />
              <Typography variant='body2' textAlign={'center'}>
                You're one step closer to maximizing your ESPP’s potential with Benny! 🚀
                <br />
                Check your inbox shortly for your exclusive report.
              </Typography>
            </mui.Box>
          )}

          <mui.Grid container>
            <Formik<SchemaType>
              onSubmit={() => {}}
              initialValues={{
                ...initialValues,
                longTermGainsTax: 15,
                offeringDate: offeringDateOptions[0].value,
                offeringDateFmv: price,
                ordinaryIncomeTax: 22,
                salePrice: price,
              }}
              validationSchema={validationSchema}
            >
              {({ values, handleChange, handleBlur, setFieldValue }) => {
                const handleFieldChange = (fieldName: string, value: unknown) => {
                  if (searchParams.has(fieldName)) {
                    console.log('HOLAAAA PERRRA HPTA')
                    const newSearchParams = new URLSearchParams(searchParams)
                    newSearchParams.set(fieldName, value.toString())
                    setSearchParams(newSearchParams)
                  }
                }

                const currentPeriod = periods.find(
                  (period) => period.id === values.participationPeriod,
                )

                const startDateFormatted = new Date(currentPeriod?.start_date).toLocaleDateString(
                  'en-US',
                  {
                    timeZone: 'UTC',
                    month: 'short',
                    day: '2-digit',
                  },
                )

                const endDateFormatted = new Date(currentPeriod.end_date).toLocaleDateString(
                  'en-US',
                  {
                    timeZone: 'UTC',
                    month: 'short',
                    day: '2-digit',
                  },
                )

                /* summary */
                const bennyFundAmount = calculateBennyFund({
                  espp: espp,
                  contributionPercentage: values.contributionPercentage / 100,
                  income: income,
                })

                const estimatedPrice = parseValueToNumber(values.estimatedPrice)
                const sharePrice = parseValueToNumber(values.sharePrice)

                const currentPrice = lookback
                  ? sharePrice < estimatedPrice
                    ? sharePrice
                    : estimatedPrice
                  : estimatedPrice

                const afterPriceDiscount = (currentPrice ?? 1) * (1 - discountPercentage / 100)

                const sharesPurchased =
                  afterPriceDiscount === 0
                    ? 0
                    : parseFloat((bennyFundAmount / afterPriceDiscount).toFixed(2))

                const marketValue = sharesPurchased * estimatedPrice

                const esppGain = marketValue - bennyFundAmount

                return (
                  <mui.Grid
                    container
                    spacing={{ xs: 0, md: '32px' }}
                    pt={{ xs: '22px', md: '42px' }}
                  >
                    <mui.Grid
                      item
                      container
                      xs={12}
                      md={6}
                      display={'flex'}
                      flexDirection={'column'}
                      gap={'48px'}
                    >
                      <mui.Box>
                        <Typography inheritColor variant={'h3'} mb={'12px'}>
                          {companyName} ESPP Details
                        </Typography>

                        <mui.Box ml={{ xs: 0, md: '24px' }}>
                          <mui.List
                            sx={{
                              '& .MuiListItem-root': {
                                position: 'relative',
                                '&::before': {
                                  content: '"•"',
                                  position: 'absolute',
                                  left: 0,
                                  color: 'currentColor',
                                  fontSize: '1rem',
                                },
                              },
                            }}
                          >
                            <mui.ListItem>
                              <Typography variant={'body1'} inheritColor>
                                Get a {discountPercentage}% discount on {companyName} shares.
                              </Typography>
                            </mui.ListItem>
                            <mui.ListItem>
                              <Typography variant={'body1'} inheritColor>
                                Enroll {enrollTimes} times a year.
                              </Typography>
                            </mui.ListItem>
                          </mui.List>
                        </mui.Box>
                      </mui.Box>

                      <mui.Box>
                        {/* <Typography variant={'h3'} mb={'12px'} inheritColor>
                          ESPP Offering Period
                        </Typography> */}

                        {/* <mui.Box ml={{ xs: 0, md: '24px' }}>
                          <mui.Box maxWidth={{ xs: '100%', md: '60%' }} mb={'43px'}>
                            <LabelVertical
                              label='Offering Date'
                              field={
                                <mui.Box>
                                  <CustomSelectField
                                    name='offeringDate'
                                    options={offeringDateOptions}
                                    variant={'outlined'}
                                  />
                                </mui.Box>
                              }
                            />
                            <LabelVertical
                              label='Share Price on the Offering Date'
                              field={
                                <mui.Box>
                                  <Field
                                    name='offeringDateFmv'
                                    component={DecimalLimitedTextField}
                                    InputProps={{
                                      size: 'small',
                                      startAdornment: (
                                        <mui.InputAdornment position='start'>$</mui.InputAdornment>
                                      ),
                                    }}
                                    sx={{ width: '120px' }}
                                  />
                                </mui.Box>
                              }
                              sx={{ mt: 3 }}
                            />
                          </mui.Box>
                        </mui.Box> */}

                        <Typography variant={'h3'} mb={'12px'} inheritColor>
                          Your ESPP Contribution
                        </Typography>

                        <mui.Box ml={{ xs: 0, md: '24px' }}>
                          <Typography variant='body1' mb={'24px'} inheritColor>
                            You can contribute up to {contributionPercentage}% of your salary with a
                            maximum of {formatToDollars(Number(espp.contr_dollar_limit))} per year.
                          </Typography>

                          <mui.Box maxWidth={{ xs: '100%', md: '60%' }} mb={'43px'}>
                            <LabelVertical
                              label='Select your participation period'
                              field={
                                <CustomSelectField
                                  name='participationPeriod'
                                  options={participationPeriodOptions}
                                  variant={'outlined'}
                                  customHandleChange={(value) =>
                                    handleFieldChange('participationPeriod', value)
                                  }
                                />
                              }
                            />
                          </mui.Box>

                          <mui.Box>
                            <LabelVertical
                              label='Your ESPP contribution (%)'
                              field={
                                <mui.Box
                                  display={'flex'}
                                  gap={'32px'}
                                  justifyContent={'center'}
                                  alignItems={'center'}
                                  maxWidth={{ xs: '100%', md: '50%' }}
                                >
                                  <CustomSliderField
                                    name='contributionPercentage'
                                    min={1}
                                    max={contributionPercentage}
                                    step={1}
                                    valueLabelDisplay='auto'
                                    customHandleChange={(value) =>
                                      handleFieldChange('contributionPercentage', value)
                                    }
                                  />

                                  <mui.Box
                                    border={'1px solid #E2E8F0'}
                                    display={'flex'}
                                    justifyContent={'center'}
                                    alignItems={'center'}
                                    padding={'10px'}
                                    borderRadius={'8px'}
                                  >
                                    {values.contributionPercentage}%
                                  </mui.Box>
                                </mui.Box>
                              }
                            />
                          </mui.Box>
                        </mui.Box>
                      </mui.Box>

                      <mui.Box pb={'32px'} borderBottom={'1px solid #E9E9E9'}>
                        <Typography variant='h3' mb={'12px'} inheritColor>
                          Estimated Purchase Price
                        </Typography>

                        <mui.Box ml={{ xs: 0, md: '24px' }}>
                          {lookback && (
                            <Typography variant='body1' mb={'24px'} inheritColor>
                              {companyName} ESPP includes a lookback. With a lookback {companyName}{' '}
                              compares {startDateFormatted} and {endDateFormatted} share price and
                              uses the lower of the two to apply discount and purchase for you.
                            </Typography>
                          )}

                          {lookback && (
                            <mui.Box mb={'43px'}>
                              <LabelVertical
                                label={`Share Price as of ${formatDate(
                                  new Date(currentPeriod?.start_date),
                                )}`}
                                field={
                                  <mui.Box maxWidth={'30%'}>
                                    <Field
                                      component={NumberFormat}
                                      fullWidth
                                      id='sharePrice'
                                      name='sharePrice'
                                      value={values.sharePrice}
                                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        setFieldValue('sharePrice', e.target.value)
                                        handleFieldChange('sharePrice', e.target.value)
                                      }}
                                      onBlur={handleBlur}
                                      customInput={CustomTextField}
                                      prefix={'$ '}
                                      thousandSeparator
                                      autoComplete='off'
                                    />
                                  </mui.Box>
                                }
                              />
                            </mui.Box>
                          )}

                          <mui.Box>
                            <LabelVertical
                              label={`Estimated Price as of ${formatDate(
                                new Date(currentPeriod.end_date),
                              )}`}
                              field={
                                <mui.Box maxWidth={'30%'}>
                                  <Field
                                    component={NumberFormat}
                                    fullWidth
                                    id='estimatedPrice'
                                    name='estimatedPrice'
                                    value={values.estimatedPrice}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                      setFieldValue('estimatedPrice', e.target.value)
                                      handleFieldChange('estimatedPrice', e.target.value)
                                    }}
                                    onBlur={handleBlur}
                                    customInput={CustomTextField}
                                    prefix={'$ '}
                                    thousandSeparator
                                    autoComplete='off'
                                  />
                                </mui.Box>
                              }
                            />
                          </mui.Box>
                        </mui.Box>
                      </mui.Box>
                    </mui.Grid>

                    <mui.Grid
                      item
                      container
                      xs={12}
                      md={6}
                      display={'flex'}
                      flexDirection={'column'}
                    >
                      <mui.Grid
                        container
                        item
                        padding={'32px'}
                        bgcolor={'#F6FBF9'}
                        borderRadius={'12px'}
                      >
                        {/*  <mui.Box
                          sx={{
                            borderBottom: 1,
                            borderColor: (theme) => theme.palette.primary.main,
                            width: '100%',
                          }}
                        >
                          <mui.Tabs
                            onChange={(e, v) => setCurrentResultsTab(v)}
                            variant='fullWidth'
                            value={currentResultsTab}
                          >
                            <mui.Tab
                              label='Gains'
                              id='tab-results-0'
                              aria-controls='tabpanel-results-0'
                              sx={{ textTransform: 'none', ...getTextStyleVariant('') }}
                            />
                            <mui.Tab
                              label='Taxes'
                              id='tab-results-1'
                              aria-controls='tabpanel-results-1'
                              sx={{ textTransform: 'none', ...getTextStyleVariant('') }}
                            />
                          </mui.Tabs>
                        </mui.Box> */}
                        <TabPanel index={0} name='results' value={currentResultsTab}>
                          <Gains
                            afterPriceDiscount={afterPriceDiscount}
                            bennyFundAmount={bennyFundAmount}
                            companyName={companyName}
                            currentPeriod={currentPeriod}
                            endDateFormatted={endDateFormatted}
                            esppGain={esppGain}
                            estimatedPrice={estimatedPrice}
                            formatDate={formatDate}
                            lookback={lookback}
                            marketValue={marketValue}
                            sharePrice={sharePrice}
                            sharesPurchased={sharesPurchased}
                            startDateFormatted={startDateFormatted}
                          />
                        </TabPanel>
                        <TabPanel index={1} name='results' value={currentResultsTab}>
                          <Taxes
                            bennyFundAmount={bennyFundAmount}
                            companyName={companyName}
                            longTermGainsTax={values.longTermGainsTax}
                            offeringDate={new Date(values.offeringDate)}
                            offeringDateFmv={values.offeringDateFmv}
                            ordinaryIncomeTax={values.ordinaryIncomeTax}
                            purchaseDate={new Date(currentPeriod?.end_date)}
                            purchaseDateFmv={currentPrice}
                            purchasePrice={afterPriceDiscount}
                            saleDate={formatDate(new Date(currentPeriod?.end_date))}
                            salePrice={values.salePrice}
                            sharesAmount={sharesPurchased}
                          />
                        </TabPanel>
                      </mui.Grid>
                    </mui.Grid>

                    <mui.Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                      <mui.Box
                        sx={{
                          textAlign: 'center',
                          background: 'linear-gradient(282deg, #581BBB -0.5%, #0CA671 98.84%)',
                          borderRadius: '12px',
                          maxWidth: '690px',
                          mt: 8,
                          px: 8,
                          py: 6,
                          width: '100%',
                        }}
                      >
                        <Typography variant='h2' sx={{ color: 'white', textAlign: 'center' }}>
                          Benny funds your ESPP contributions
                        </Typography>
                        <Typography sx={{ color: 'white', textAlign: 'center', py: 4 }}>
                          Ready to maximize your employee stock purchase plan benefits without
                          reducing your take-home pay? Start your application today!
                        </Typography>
                        <mui.Button
                          component={mui.Link}
                          variant='outlined'
                          href={`/apply?step=about_your_espp&employer=${companyName}&s=calculator_page_end`}
                          endIcon={<ArrowForwardIcon />}
                          sx={{
                            ...getTextStyleVariant('subtitle1'),
                            borderColor: 'white',
                            color: 'white',
                            px: 4,
                            py: 2,
                            '&:hover': {
                              backgroundColor: 'transparent',
                              borderColor: 'white',
                              color: 'white',
                            },
                          }}
                        >
                          Let’s Go!
                        </mui.Button>
                      </mui.Box>
                    </mui.Box>
                  </mui.Grid>
                )
              }}
            </Formik>
          </mui.Grid>
        </TabPanel>
      </mui.Grid>
    </mui.Grid>
  )
}
