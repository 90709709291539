import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Box, Button, CircularProgress, Grid } from '@mui/material'
import { Field, Formik } from 'formik'
import { useCustomParams } from 'hooks/useCustomParams'
import NumberFormat from 'react-number-format'
import * as yup from 'yup'
import { CustomTextField } from '../../../../Components/Fields'
import { GradientTypography } from '../../../../Components/gradientTypography'
import { Typography } from '../../../../Components/Typography'
import { Espp } from '../../../../types'
import { formatToDollars } from '../../../../utils/commons'

const schema = yup.object({
  income: yup
    .number()
    .required('Income is required')
    .positive('Income must be greater than zero')
    .min(1, 'Income must be greater than zero'),
})

type SchemaType = yup.InferType<typeof schema>

interface AboutStepProps {
  espp: (Espp & { dates: string }) | null
  employer: string
  contributionPercentage: number
  onHandled: (income: string) => void
  income: number
}

export const AboutStep = ({
  espp,
  employer,
  contributionPercentage,
  income,
  onHandled,
}: AboutStepProps) => {
  const { setSearchParams, searchParams } = useCustomParams()

  const esppDiscount = Number(espp?.discount ?? 0) * 100
  const contribution = contributionPercentage * 100
  const contrDollarLimit = Number(espp?.contr_dollar_limit ?? 0)
  const enrollTimes = (12 / espp?.purchase_period_length).toFixed(0)
  const dates = espp?.dates ?? ''

  const onSubmit = (values: SchemaType) => {
    onHandled(String(values.income))
  }

  return (
    <Grid container sx={{ width: '100%', minWidth: '100%' }}>
      <Grid
        container
        borderBottom={'1px solid #E9E9E9'}
        pb={{ xs: '24px', md: '48px' }}
        sx={{ width: '100%', minWidth: '100%' }}
      >
        <GradientTypography>About {employer}'s ESPP</GradientTypography>

        {!!espp ? (
          <Grid
            container
            rowSpacing={4}
            columnSpacing={2}
            mb={{ xs: '32px', md: '64px' }}
            mt={{ xs: '12px', md: '24px' }}
            sx={{ '& > .MuiGrid-item': { padding: '12px', position: 'relative' } }}
          >
            {[
              <>
                You can buy {employer} stock at a <strong>{esppDiscount}% discount.</strong>
              </>,
              <>
                You can enroll <strong>{enrollTimes} times</strong> a year prior to each ESPP
                participation period.
              </>,
              <>
                You can <strong>contribute up to {contribution}%</strong> of your salary with a max
                of {formatToDollars(contrDollarLimit)} per year.
              </>,
              <>
                Your <strong>participation periods</strong> run {dates}.
              </>,
            ].map((text, index) => (
              <Grid item xs={12} md={6} key={index}>
                <Typography inheritColor>{text}</Typography>
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    width: 'calc(100% - 32px)',
                    borderBottom: '1px solid #E9E9E9',
                    marginLeft: '12px',
                  }}
                />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Grid container mb={{ xs: '32px', md: '64px' }} mt={{ xs: '12px', md: '24px' }} />
        )}

        <Grid
          container
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Formik<SchemaType>
            validationSchema={schema}
            onSubmit={onSubmit}
            initialValues={{ income: income ? income : 65000 }}
            validateOnMount
            isInitialValid
          >
            {({
              values,
              handleBlur,
              touched,
              errors,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => {
              return (
                <Box display={'flex'}>
                  <Box
                    display={'flex'}
                    flexDirection={'column'}
                    gap={'20px'}
                    justifyContent={'center'}
                  >
                    <Box display={'flex'} flexDirection={'column'} gap={'20px'} maxWidth={'540px'}>
                      <Typography inheritColor variant='h3'>
                        ESPP contributions are based on income. What is your annual gross income?
                      </Typography>
                    </Box>

                    <Box
                      display={'flex'}
                      justifyContent={'center'}
                      alignItems={'flex-end'}
                      flexDirection={{ xs: 'column', md: 'row' }}
                      px={{ xs: 0, md: '32px' }}
                      gap={'20px'}
                    >
                      <Field
                        component={NumberFormat}
                        fullWidth
                        id='income'
                        name='income'
                        placeholder='Annual gross income'
                        value={values.income}
                        onValueChange={(value) => {
                          setFieldValue('income', value.floatValue)
                          searchParams.delete('income')
                          setSearchParams(searchParams)
                        }}
                        onBlur={handleBlur}
                        error={touched.income && Boolean(errors.income)}
                        customInput={CustomTextField}
                        prefix={'$ '}
                        thousandSeparator
                        autoComplete='off'
                      />

                      <Button
                        variant='contained'
                        onClick={() => handleSubmit()}
                        disabled={!!isSubmitting}
                        sx={{ padding: '10px 32px' }}
                      >
                        {!!isSubmitting ? (
                          <CircularProgress size={16} sx={{ color: 'white' }} />
                        ) : (
                          <ArrowForwardIcon />
                        )}
                      </Button>
                    </Box>
                  </Box>
                </Box>
              )
            }}
          </Formik>
        </Grid>
      </Grid>
    </Grid>
  )
}
