import { CircularProgress } from '@mui/material'
import React from 'react'


export function CenteredSpinner(props: { noMargin?: boolean }) {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', marginTop: props.noMargin ? '0' : 'calc(50vh - 50px)' }}>
      <CircularProgress size={100} />
    </div>
  )
}