import loadable from '@loadable/component'
import { ThemeProvider } from '@mui/material'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'

import { userContext, useUserContext } from 'context/user'

import App from 'App'
import { EmbeddableCalculator } from 'Components/EmbeddableCalculator'
import { ErrorBoundary } from 'Components/ErrorBoundary'
import { LoadingDots } from 'Components/LoadingDots'
import store from 'Components/login/store'
import 'fonts/Garnett/Garnett-Semibold.ttf'
import 'index.css'
import reportWebVitals from 'reportWebVitals'
import {
  $Home,
  AboutUs,
  ContactUs,
  EsppCalculator,
  HomeV1,
  HomeV2,
  HomeV3,
  Offers,
  Partners,
  SuperMoney,
  VerifyApplication,
  VerifyClient,
  VerifyEmployment,
  Waitlist,
  WizardCalculator,
} from 'screens'
import { Login } from 'screens/auth/Login'
import { Register } from 'screens/auth/Register'
import { SetPassword } from 'screens/auth/SetPassword'
import { Calculator } from 'screens/Calculator'
import { CustomerPortal } from 'screens/CustomerPortal'
import { Fincon } from 'screens/fincon'
import { TestEmbedPage } from 'screens/test'
import { theme } from 'theme'

const ExperimentalLanding = loadable(() =>
  import('./Components/experimental_landing/experimental_landing'),
)
const Page404 = loadable(() => import('./Components/landingPage/404Page'))
const LandingPageV2 = loadable(() => import('./Components/landing_page_v2/landing_page_v2'))
const DetailsByCompanyPage = loadable(() => import('./Components/mainWebsite/esppdetailsbycompany'))
const HomePage = loadable(() => import('./Components/mainWebsite/homepage'))
const PrivacyPage = loadable(() => import('./Components/mainWebsite/privacy'))
const ResearchPage = loadable(() => import('./Components/mainWebsite/research'))
const TermsPage = loadable(() => import('./Components/mainWebsite/terms'))
const RedirectHandler = loadable(() => import('./Components/unestIntegration/redirectHandler'))
const Unest = loadable(() => import('./Components/unestIntegration/unestHandler'))
const CompanyLandingV3 = loadable(() => import('./Components/company_landing/company_landing'))
const Success = loadable(() => import('./Components/applicationPage/successfulpage'))
const Failure = loadable(() => import('./Components/applicationPage/unsuccessfullPage'))
const ExistingCustomers = loadable(() => import('./Components/applicationPage/existingcustomers'))
const AppForm = loadable(() => import('./Components/applicationPage/applicationForm'))
const Application = loadable(() => import('./Components/applicationPage/applicationPage'))
const ErrorVerificationPage = loadable(() =>
  import('./Components/applicationPage/errorVerificationPage'),
)
const AdminApplicationDetails = loadable(() => import('./Components/newAdmin/applicationDetails'))
const CompanyList = loadable(() => import('./Components/adminDashboard/companies'))
const AdminCompanyUpload = loadable(() => import('./Components/adminDashboard/uploadCompanies'))

const Esppstartdates = loadable(() => import('./Components/adminDashboard/esppstartdates'))
const DisbursementTracker = loadable(() =>
  import('./Components/newAdmin/disbursementTracker/disbursementTracker'),
)
const AdminPanel = loadable(() => import('./Components/newAdmin/adminPanel'))
const ContractGeneratorContainer = loadable(() =>
  import('./Components/newAdmin/contractGeneration/contractGeneratorContainer'),
)

const esppDetailsByCompany = [
  { name: '/rc', customEsppId: '191dda51-c744-4d27-bdd2-7ab89ba2a03a' },
  { name: '/microsoft', customEsppId: '3689c395-8c76-4897-b109-da224531f418' },
  { name: '/apple', customEsppId: '2a8d99dc-25ce-4d21-82e2-9485bd4f3941' },
  { name: '/maximize-espp', customEsppId: '36df05d8-5512-4bc5-aff5-0c05eb871f09' },
]

function RedirectToLogin() {
  return <Navigate to='/login' replace />
}

// eslint-disable-next-line react/prop-types
function PrivateRoute({ component: Component, ...rest }) {
  const user = React.useContext(userContext)
  if (user.spinner) return user.spinner
  if (!user.data) return <RedirectToLogin />
  if (!user.data.isStaff) return <Navigate to='/customer/applications' replace />
  return <Component {...rest} />
}

function RootComponent() {
  const user = useUserContext()
  return (
    <Provider store={store}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <ErrorBoundary>
            <userContext.Provider value={user}>
              <App>
                <React.Suspense fallback={<LoadingDots />}>
                  <Routes>
                    <Route path='/' element={<$Home />} />

                    <Route path='/loc' element={<SuperMoney />} />

                    <Route path='/offers' element={<Offers />} />

                    <Route path='/partners/:partner' element={<Partners />} />

                    <Route
                      path='/application/:applicationId/verify-employment'
                      element={<VerifyEmployment />}
                    />

                    <Route path='/about-us' element={<AboutUs />} />

                    {/* <Route path="/blog" element={<Blog/>} /> */}
                    <Route path='/pay-off-student-loans' element={<HomeV1 />} />
                    <Route path='/use-benny-to-back-your-espp' element={<HomeV2 />} />
                    <Route path='/smartest-way-to-fund-espp' element={<HomeV3 />} />
                    <Route path='/fincon' element={<Fincon />} />
                    <Route path='/espp-calculator' element={<EsppCalculator />} />

                    <Route path='/waitlist' element={<Waitlist />} />

                    <Route path='/apply' element={<WizardCalculator />} />
                    {/*   <Route path="/apply" element={<NewAppForm />} /> */}
                    <Route path='/oldapply' element={<AppForm />} />
                    <Route path='/uploadcompanies' element={<AdminCompanyUpload />} />
                    <Route path='/calculator' element={<Calculator />} />
                    <Route path='/companies' element={<CompanyList />} />
                    <Route path='/login' element={<Login />} />
                    <Route path='/register' element={<Register />} />
                    <Route path='/change-password' element={<SetPassword mode='change' />} />
                    <Route path='/set-password' element={<SetPassword mode='set' />} />
                    <Route path='/reset-password' element={<SetPassword mode='reset' />} />
                    <Route path='/verificationError' element={<ErrorVerificationPage />} />
                    <Route path='/application/:applicationId' element={<Application />} />

                    <Route
                      path='/verify/application/:applicationId'
                      element={<VerifyApplication />}
                    />
                    <Route path='/verify/client' element={<VerifyClient />} />

                    <Route path='/lp/:applicationId' element={<ExistingCustomers />} />
                    <Route path='/lp/:applicationId/success' element={<Success />} />
                    <Route path='/lp/:applicationId/failure' element={<Failure />} />
                    <Route
                      path='/:company/espp_details/:esppId'
                      element={<CompanyLandingV3 type='b2c' />}
                    />

                    {esppDetailsByCompany.map((esppDetail, i) => (
                      <Route
                        key={i}
                        path={esppDetail.name}
                        element={
                          <CompanyLandingV3 type='b2c' customEsppId={esppDetail.customEsppId} />
                        }
                      />
                    ))}

                    <Route
                      path='/embeddable-calculator/:esppId'
                      element={<EmbeddableCalculator />}
                    />
                    <Route path='/embeddable-calculator' element={<EmbeddableCalculator />} />
                    <Route path='/test/:esppId' element={<TestEmbedPage />} />
                    <Route path='/test' element={<TestEmbedPage />} />
                    <Route
                      path='/espps/CoStar'
                      element={
                        <LandingPageV2 esppId='57a4aa0e-f054-450c-984c-f2384f5f8460' type='b2b' />
                      }
                    />
                    <Route
                      exact
                      path='/The-Home-Depot/espp_details/36df05d8-5512-4bc5-aff5-0c05eb871f09'
                      element={<Page404 />}
                    />
                    <Route path='/:company/espp/:esppId' element={<LandingPageV2 type='b2b' />} />
                    <Route path='/404' element={<Page404 />} />
                    <Route path='/contact-us' element={<ContactUs />} />
                    <Route path='/terms' element={<TermsPage />} />
                    <Route path='/privacy' element={<PrivacyPage />} />
                    <Route path='/f/:involvemeformname' element={<ResearchPage />} />
                    <Route path='/home' element={<HomePage />} />
                    <Route path='/get-started' element={<ExperimentalLanding />} />
                    <Route path='/newhome' element={<HomeV1 />} />
                    <Route path='/refer/:prereferral' element={<$Home forceTrack />} />
                    <Route path='/espp_details_by_company' element={<DetailsByCompanyPage />} />
                    <Route path='partners/unest' element={<Unest />} />
                    <Route path='offer/:leadId' element={<RedirectHandler />} />

                    <Route path='/customer/*' element={<CustomerPortal />} />

                    <Route path='*' element={<Page404 />} />

                    {/* http://localhost:3000/verify/application/bc4d036e-0fe1-4302-9f96-1ef464dae15c?code=24DEF4A655644574 */}

                    {/* PrivateRoutes */}
                    <Route
                      path='/companies_by_date'
                      element={<PrivateRoute component={Esppstartdates} />}
                    />
                    <Route path='/admin' element={<PrivateRoute component={AdminPanel} />} />
                    <Route
                      path='/admin/contract/:applicationId'
                      element={<PrivateRoute component={ContractGeneratorContainer} />}
                    />
                    <Route
                      path='/admin/disbursements'
                      element={<PrivateRoute component={DisbursementTracker} />}
                    />
                    <Route
                      path='/admin/application/:applicationId'
                      element={<PrivateRoute component={AdminApplicationDetails} />}
                    />
                  </Routes>
                </React.Suspense>
              </App>
            </userContext.Provider>
          </ErrorBoundary>
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  )
}

ReactDOM.render(<RootComponent />, document.getElementById('root'))

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.ready.then(() => {
    window.addEventListener('load', () => {
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`
      navigator.serviceWorker
        .register(swUrl)
        .then((registration) => {
          console.log('Service Worker successfully registered with scope:', registration.scope)
        })
        .catch((err) => {
          console.log('Error when registering the Service Worker:', err)
        })
    })
  })
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log)
