import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-mui'

import * as mui from '@mui/material'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import React from 'react'

import * as Yup from 'yup'

import { Typography } from 'Components/Typography'

const requiredMsg = 'Required'
const validationSchema = Yup.object().shape({
  email: Yup.string().email().required(requiredMsg),
  password: Yup.string()
    .min(8, '8 characters minimum')
    .matches(/[a-z]/, 'Must contain at least one lowercase letter')
    .matches(/[A-Z]/, 'Must contain at least one uppercase letter')
    .matches(/[0-9]/, 'Must contain at least one number')
    .matches(/[@$!%*#?&]/, 'Must contain at least one special character')
    .required(requiredMsg),
  password2: Yup.string()
    .required(requiredMsg)
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
})

export function Register() {
  return (
    <mui.Container component="main" maxWidth="xs">
      <mui.Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <mui.Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </mui.Avatar>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <Formik
          initialValues={{ email: '', password: '', password2: '' }}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(false)
          }}
        >
          {({ submitForm, isSubmitting, touched, errors }) => (
            <Form>
              <mui.Stack spacing={3} mt={3}>
                <Field
                  component={TextField}
                  error={touched.email && !!errors.email}
                  helperText={touched.email && errors.email}
                  InputProps={{
                    autoComplete: 'email',
                    autoFocus: true,
                    fullWidth: true,
                    type: 'email',
                  }}
                  label="Email address"
                  name="email"
                  fullWidth
                  required
                />
                <Field
                  component={TextField}
                  error={touched.password && !!errors.password}
                  helperText={touched.password && errors.password}
                  InputProps={{ fullWidth: true, type: 'password' }}
                  label="Password"
                  name="password"
                  fullWidth
                  required
                />
                <Field
                  component={TextField}
                  error={touched.password2 && !!errors.password2}
                  helperText={touched.password2 && errors.password2}
                  InputProps={{ fullWidth: true, type: 'password' }}
                  label="Confirm password"
                  name="password2"
                  fullWidth
                  required
                />
                <mui.FormControlLabel
                  control={<mui.Checkbox value="allowExtraEmails" color="primary" />}
                  label="I want to receive inspiration, marketing promotions and updates via email."
                />
                <mui.Button
                  disabled={isSubmitting}
                  fullWidth
                  onClick={submitForm}
                  sx={{ mt: 3, mb: 2 }}
                  type="submit"
                  variant="contained"
                >
                  Sign Up
                </mui.Button>
                <mui.Grid container justifyContent="flex-end">
                  <mui.Grid item>
                    <mui.Link href="#" variant="body2">
                      Already have an account? Sign in
                    </mui.Link>
                  </mui.Grid>
                </mui.Grid>
              </mui.Stack>
            </Form>
          )}
        </Formik>
      </mui.Box>
    </mui.Container>
  )
}