import { Box, CircularProgress } from '@mui/material'
import { Fragment } from 'react'
import { useParams } from 'react-router-dom'
import { useFetchEsppDetails } from '../../hooks/useFetchEsppDetails'
import { encrypt_api_secret } from '../../utils/commons'
import CalculatorV3 from '../kb_landing_page/calculator/calculator'

export const EmbeddableCalculator = () => {
  const { esppId } = useParams()

  const secret_key = encrypt_api_secret(process.env.REACT_APP_CORE_API_SECRET)

  const {
    data,
    loading,
    name,
    setName,
    email,
    setEmail,
    phone,
    setPhone,
    state,
    setState,
    income,
    setIncome,
    contribution,
    setContribution,
    maxContribPercentage,
    yearly_gain,
    monthly_gain,
    employer,
    espp_discount,
    enrollment_deadline,
    contr_dollar_limit,
    purchase_period_length,
    period_contr_dollar_limit,
    bennyFund,
  } = useFetchEsppDetails(esppId, secret_key)

  if (loading) {
    return (
      <Box sx={{ display: 'flex', height: '100vh' }}>
        <CircularProgress sx={{ margin: 'auto', color: '#4B288E' }} />
      </Box>
    )
  }

  return (
    <Fragment>
      <Box sx={{ display: 'flex', height: '100vh' }}>
        <CalculatorV3 isOnlyCalculator prefilled_employer={employer} />
      </Box>
    </Fragment>
  )
}
