import * as mui from '@mui/material'
import React from 'react'

export function LabelVertical(props: {
  label: string,
  field: React.ReactNode,
  sx?: mui.SxProps,
}) {
  return (
    <mui.Stack spacing={1} sx={props.sx}>
      <mui.Typography color='primary' sx={{ fontSize: '17px' }}>{props.label}</mui.Typography>
      {props.field}
    </mui.Stack>
  )
}
