import * as mui from '@mui/material'
import { Typography } from 'Components/Typography'

import accentureLogo from '../../media/images/homepage3/logos/accenture.svg'
import amdLogo from '../../media/images/homepage3/logos/amd.svg'
import appleLogo from '../../media/images/homepage3/logos/apple.svg'
import msftLogo from '../../media/images/homepage3/logos/msft.svg'
import nvidiaLogo from '../../media/images/homepage3/logos/nvidia.svg'

const logos = [
  { src: appleLogo, alt: 'Apple' },
  { src: accentureLogo, alt: 'Accenture' },
  { src: amdLogo, alt: 'AMD' },
  { src: msftLogo, alt: 'Microsoft' },
  { src: nvidiaLogo, alt: 'Nvidia' },
]

interface TrustedByEmployeesProps {
  title?: string
}

export function TrustedByEmployees({
  title = 'Trusted By Employees Of Top Companies',
}: TrustedByEmployeesProps) {
  return (
    <mui.Grid container item xs={12} my={8}>
      <mui.Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
        <Typography sx={{ color: 'black' }}>{title}</Typography>
      </mui.Grid>
      <mui.Grid container item xs={12} sx={{ justifyContent: 'center' }}>
        <mui.Stack
          direction='row'
          sx={{
            px: 2,
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            gap: { xs: 2, md: 8 },
          }}
        >
          {logos.map((logo, i) => (
            <mui.Grid key={i} sx={{ display: 'flex', alignItems: 'flex-end' }}>
              <img
                src={logo.src}
                alt={logo.alt}
                className='animate__animated animate__pulse animate__backInRight'
              />
            </mui.Grid>
          ))}
        </mui.Stack>
      </mui.Grid>
    </mui.Grid>
  )
}
