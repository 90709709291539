import axios from 'axios'
import { encrypt_api_secret } from './commons'

const secret_key = encrypt_api_secret(process.env.REACT_APP_CORE_API_SECRET)

const baseURL = process.env.REACT_APP_SERVER_URL

export const bennyApi = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
    'benny-api-key': process.env.REACT_APP_CORE_API_KEY,
    'benny-api-secret': secret_key,
  },
})
