const logos = [
  { src: 'https://imagesbenny.s3.amazonaws.com/homepage/tmobile.svg', alt: 'T-Mobile logo' },
  { src: 'https://imagesbenny.s3.amazonaws.com/homepage/intel.svg', alt: 'Intel logo' },
  { src: 'https://imagesbenny.s3.amazonaws.com/homepage/microsoft.svg', alt: 'Microsoft logo' },
  {
    src: 'https://imagesbenny.s3.amazonaws.com/homepage/royalcaribbean.svg',
    alt: 'Royal Caribbean logo',
  },
  { src: 'https://imagesbenny.s3.amazonaws.com/homepage/labcorp.svg', alt: 'LabCorp logo' },
]

export const Logos = () => {
  return (
    <>
      {logos.map((logo, index) => (
        <img
          key={index}
          src={logo.src}
          alt={logo.alt}
          loading='lazy'
          className='animate__animated animate__pulse animate__backInRight'
        />
      ))}
    </>
  )
}
