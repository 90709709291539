import axios from 'axios'
import { getCookie, setCookie } from '../helpers/cookieHelper'

export const getIPAddress = async () => {
  try {
    let ip = getCookie('ip')
    if (!ip) {
      const response = await axios.get('https://api.ipify.org/?format=json')
      ip = response.data.ip
      setCookie('ip', ip, 365)
    }

    return ip
  } catch (error) {
    console.error('Error IP:', error)
    return null
  }
}
