import { AppBar, Box, Button, Drawer, Grid, ListItem, ListItemText, Toolbar } from '@mui/material'
import React from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { Link } from 'react-scroll'
import { useEventClick } from '../../hooks/tracker'
import logo from '../../media/images/logos/newlogo.svg'
import { CtaAction, HeaderConfig } from '../../screens/home/config'
import { colors } from '../../utils/colors'
import { AuthClientForm } from '../Auth/AuthClientForm'
import './header.css'

interface Headerprops extends HeaderConfig {
  income?: string
  employer?: string
  onCtaClick?: (action: CtaAction) => void
}

export default function Header2({ income = '', employer = '', cta, onCtaClick }: Headerprops) {
  const { createEvent } = useEventClick()

  const [open, setOpen] = React.useState(false)
  const [navBar, setNavbar] = React.useState(false)
  const data = [
    {
      name: 'ESPP Calculator',
      title: 'esppcalculator',
    },
    { name: 'How it Works', title: 'howitworks' },
    { name: 'Contact Us', title: '/contact-us' },
    { name: 'Blog', title: 'https://blog.heybenny.com' },
    { name: 'Apply', title: '/apply' },
  ]
  const [searchParams] = useSearchParams()
  let { prereferral } = useParams()

  const getList = () => (
    <div style={{ width: 250 }} onClick={() => setOpen(false)}>
      {data.map((item, index) =>
        item.name === 'Contact Us' ? (
          <a
            key={`${item.title}-${index}`}
            style={{
              color: '#194339',
              textDecoration: 'none',
              fontWeight: '400',
              fontSize: '18px',
            }}
            href={item.title}
          >
            <ListItem button sx={{ borderBottom: 'solid 1px #fefefe' }}>
              <ListItemText primary={item.name} />
            </ListItem>
          </a>
        ) : item.name === 'Blog' ? (
          <a
            key={`${item.title}-${index}`}
            style={{
              color: '#194339',
              textDecoration: 'none',
              fontWeight: '400',
              fontSize: '18px',
            }}
            href={item.title}
          >
            <ListItem button sx={{ borderBottom: 'solid 1px #fefefe' }}>
              <ListItemText primary={item.name} />
            </ListItem>
          </a>
        ) : item.name === 'Apply' ? (
          <a
            key={`${item.title}-${index}`}
            style={{
              color: '#194339',
              textDecoration: 'none',
              fontWeight: '400',
              fontSize: '18px',
            }}
            href={item.title}
          >
            <ListItem button>
              <ListItemText primary={item.name} />
            </ListItem>
          </a>
        ) : item.name === 'ESPP Calculator' ? (
          <Link
            smooth
            onClick={() => setOpen(false)}
            style={{
              color: '#194339',
              textDecoration: 'none',
              fontWeight: '400',
              fontSize: '18px',
            }}
            to={item.title}
            key={`${item.title}-${index}`}
          >
            <ListItem button sx={{ borderBottom: 'solid 1px #fefefe' }}>
              <ListItemText primary={item.name} sx={{ marginTop: '20px', '& .MuiListItemText-primary': { textTransform: 'none' }}} />
            </ListItem>
          </Link>
        ) : (
          <Link
            onClick={() => setOpen(false)}
            smooth
            style={{
              color: '#194339',
              textDecoration: 'none',
              fontWeight: '400',
              fontSize: '18px',
            }}
            to={item.title}
            key={`${item.title}-${index}`}
          >
            <ListItem button sx={{ borderBottom: 'solid 1px #fefefe' }}>
              <ListItemText primary={item.name} />
            </ListItem>
          </Link>
        ),
      )}
    </div>
  )

  if (searchParams.get('referral') !== null) {
    if (searchParams.get('referral') !== '') {
      prereferral = searchParams.get('referral')
    }
  }

  React.useEffect(() => {
    const changeHeaderPosition = () => {
      if (window.scrollY >= 50) {
        setNavbar(true)
      } else {
        setNavbar(false)
      }
    }
    window.addEventListener('scroll', changeHeaderPosition)
    return () => {
      window.removeEventListener('scroll', changeHeaderPosition)
    }
  }, [])

  return (
    <div>
      <AppBar
        color='default'
        elevation={0}
        id='header'
        className='websiteheader'
        sx={{
          display: { xs: 'none', md: 'block' },
          position: !navBar ? 'relative !important' : 'fixed !important',
        }}
      >
        <Toolbar>
          <Grid
            container
            justifyContent='space-between'
            direction='row'
            py='1.2em'
            sx={{ flexWrap: 'nowrap' }}
          >
            <Grid item xs={3} md={3} ml='1.2em'>
              <Link to={prereferral === null ? '/' : `/?referral=${prereferral}`} target='_blank'>
                <img src={logo} width='114px' height='40px' alt='Rooted' loading='lazy' />
              </Link>
            </Grid>
            <Grid item xs={6} md={6} textAlign='center' my='auto' mx='auto'>
              <Grid container alignSelf='center' justifyContent='start'>
                <Grid item my='auto' mx='1em'>
                  <Link
                    smooth
                    style={{
                      color: '#40645B',
                      textDecoration: 'none',
                      fontWeight: '500',
                      fontSize: '16px',
                      // marginLeft: '1em',
                      // marginRight: '1em',
                      cursor: 'pointer',
                    }}
                    to='esppcalculator'
                  >
                    ESPP Calculator
                  </Link>
                </Grid>
                <Grid item my='auto' mx='1em'>
                  <Link
                    smooth
                    style={{
                      color: '#40645B',
                      textDecoration: 'none',
                      fontWeight: '500',
                      fontSize: '16px',
                      // marginLeft: '1em',
                      // marginRight: '1em',
                      cursor: 'pointer',
                    }}
                    to='howitworks'
                  >
                    How it Works
                  </Link>
                </Grid>
                <Grid item my='auto' mx='1em'>
                  <a
                    style={{
                      color: '#40645B',
                      textDecoration: 'none',
                      fontWeight: '500',
                      fontSize: '16px',
                      // mx: '1em',
                    }}
                    href='/contact-us'
                  >
                    Contact Us
                  </a>
                </Grid>
                <Grid item my='auto' mx='1em'>
                  <a
                    style={{
                      color: '#40645B',
                      textDecoration: 'none',
                      fontWeight: '500',
                      fontSize: '16px',
                      // mx: '1em',
                    }}
                    href='https://blog.heybenny.com/'
                    target='_blank'
                    rel='noreferrer'
                    //href='/blog'
                  >
                    Blog
                  </a>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} md={3} textAlign='center' my='auto'>
              <Grid container alignSelf='center' justifyContent='end'>
                <Grid item my='auto' mx='0.5em' display={{ xs: 'none', md: 'block', lg: 'block' }}>
                  <Grid
                    container
                    direction='row'
                    alignItems='center'
                    fontWeight='bold'
                    sx={{ color: '#194339' }}
                  >
                    <a
                      style={{
                        color: '#194339',
                        textDecoration: 'none',
                        fontWeight: '400',
                        fontSize: '13px',
                      }}
                      href='tel:6083026665'
                    >
                      Help? (608) 302-6665
                    </a>
                  </Grid>
                </Grid>
                <Grid item my='auto' mx='0.5em'>
                  <Box display={'flex'} gap={'8px'}>
                    <Button
                      onClick={() => createEvent({ name: 'HeaderNavGetStart' })}
                      variant='contained'
                      sx={{
                        backgroundColor: `${colors.primary}`,
                        textTransform: 'none',
                        borderRadius: '8px',
                        '&:hover': { background: '#8746FE' },
                      }}
                      href={
                        cta.action.type === 'route'
                          ? `${cta.action.value}&employer=${employer}&referral=${
                              prereferral || ''
                            }&income=${income}`
                          : `/apply?employer=${employer}&referral=${
                              prereferral || ''
                            }&income=${income}`
                      }
                    >
                      {cta.text ?? 'Apply'}
                    </Button>
                    <AuthClientForm />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Grid
        container
        className='mobilewebsiteheader'
        sx={{
          display: { xs: 'flex', md: 'none' },
          position: !navBar ? 'relative !important' : 'fixed !important',
        }}
      >
        <Grid item xs={3}>
          <Link to={prereferral === null ? '/' : `/?referral=${prereferral}`} target='_blank'>
            <img src={logo} width='100px' height='35px' alt='Rooted' />
          </Link>
        </Grid>
        <Grid item xs={8} my='auto'>
          <Grid container justifyContent='flex-end'>
            <Grid item my='auto' mx='0.5em'>
              <Button
                variant='outlined'
                sx={{
                  textTransform: 'none',
                  borderRadius: '8px',
                  color: '#502694',
                  border: '1px solid #502694',
                  fontSize: '18px',
                  '&:hover': { background: '#8746FE' },
                }}
                href={`/apply/?employer=${employer}&referral=${prereferral || ''}&income=${income}`}
              >
                Apply
              </Button>
            </Grid>
            <Grid item my='auto' mx='0.5em'>
              <Grid
                container
                direction='row'
                alignItems='center'
                fontWeight='bold'
                sx={{ color: '#194339' }}
              >
                <Link
                  to=''
                  style={{
                    color: '#194339',
                    textDecoration: 'none',
                    fontWeight: '400',
                    fontSize: '18px',
                  }}
                  onClick={() => setOpen(true)}
                >
                  Menu
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Drawer open={open} anchor='right' onClose={() => setOpen(false)}>
          {getList()}
        </Drawer>
      </Grid>
    </div>
  )
}
