import EastIcon from '@mui/icons-material/East'
import { Box, Button, CircularProgress, Grid } from '@mui/material'
import { TrustedByEmployees } from 'Components/TrustedByEmployees'
import { Field, Form, Formik } from 'formik'
import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { formatToDollars } from 'utils/commons'
import * as yup from 'yup'
import { InferType } from 'yup'
import { CustomAutocomplete, CustomTextField } from '../../../../Components/Fields'
import { GradientTypography } from '../../../../Components/gradientTypography'
import { CustomText } from '../../../../Components/Text'
import { Typography } from '../../../../Components/Typography'
import { getCookie } from '../../../../helpers/cookieHelper'
import { bennyApi } from '../../../../utils/bennyApi'
import { submitContactForm } from '../../../../utils/submitContactForm'

const schema = yup.object({
  employer: yup.string().required('Employer is required'),
  name: yup.string().required('Name is required'),
  email: yup.string().email('Enter a valid email').required('Email is required'),
  state: yup.string().required('State is required'),
  income: yup.number().required('Income is required'),
  phone: yup.number().nullable().optional(),
  contributionPercentage: yup.number().required('Contribution is required'),
  referralCode: yup
    .string()
    .optional()
    .matches(/^[A-Za-z0-9]{7}$/, 'Please enter a valid 7 character Referral Code'),
  codeBenefit: yup.string().nullable().optional(),
  codeFound: yup.string().nullable().optional().oneOf(['Valid', 'Invalid', 'Not_Found']),
})

type SchemaType = InferType<typeof schema>

interface ContactFormProp {
  bennyFund: number
  income: number
  contributionPercentage: number
  statesList: string[]
  employer: string
  referral: string
}

export const ContactForm = ({
  bennyFund,
  income,
  contributionPercentage,
  statesList,
  employer,
  referral,
}: ContactFormProp) => {
  const [params] = useSearchParams()

  const utmSource = params.get('utm_source')

  const [showReferralCodeField, setShowReferralCodeField] = useState<boolean>(!!referral)

  const navigate = useNavigate()

  const checkReferralCode = async (referralCode: string, setFieldValue) => {
    try {
      const { data } = await bennyApi.get(`/espp/referralCode/${referralCode}/`)
      if (data?.referralCode?.active) {
        setFieldValue('codeFound', 'Valid')
        setFieldValue(
          'codeBenefit',
          data?.referralCode?.receiver_benefit ? data?.referralCode?.receiver_benefit : null,
        )
      } else {
        setFieldValue('codeFound', 'Invalid')
      }
    } catch (error) {
      console.error(error?.response?.data?.referralCode)
      if (error?.response?.data?.referralCode === 'Not found') {
        setFieldValue('codeFound', 'Not_Found')
      }
    }
  }

  const onSubmit = async (values: SchemaType) => {
    const customerId = getCookie('customerId')

    try {
      const response = await submitContactForm({
        customerId,
        name: values.name,
        email: values.email,
        employer: values.employer,
        state: values.state,
        income: values.income,
        referralCode: !!values.codeBenefit ? values.referralCode : '',
        phone: values.phone,
      })

      navigate(
        utmSource
          ? `/application/${response.applicationId}?utm_source=${utmSource}`
          : `/application/${response.applicationId}`,
      )
    } catch (error) {
      console.error('Error submitting contact form:', error)
    }
  }

  useEffect(() => {
    if (referral) {
      const referralCodeInput = document.getElementById('referralCode')
      if (referralCodeInput instanceof HTMLInputElement) {
        referralCodeInput.value = referral
        referralCodeInput.dispatchEvent(new Event('input', { bubbles: true }))
      }
    }
  }, [referral])

  return (
    <Grid container>
      <Grid container>
        <Grid container>
          {bennyFund ? (
            <GradientTypography>
              Well, this is exciting! We’ve got {formatToDollars(bennyFund)} in ESPP funding just
              for you.
            </GradientTypography>
          ) : (
            <GradientTypography>Get Your Offer</GradientTypography>
          )}
        </Grid>

        <Typography my={'12px'}>
          We’re building you a custom offer to check out, we just need a couple of last pieces of
          information.
        </Typography>
      </Grid>

      <Grid container justifyContent={'center'} alignItems={'center'} mt={'10px'}>
        <Formik<SchemaType>
          validationSchema={schema}
          onSubmit={onSubmit}
          initialValues={{
            name: '',
            email: '',
            state: '',
            income,
            contributionPercentage,
            referralCode: referral,
            codeBenefit: null,
            employer,
            phone: null,
          }}
          validateOnMount
          isInitialValid
        >
          {({
            touched,
            errors,
            values,
            handleChange,
            handleBlur,
            setFieldTouched,
            setFieldValue,
            handleSubmit,
            isSubmitting,
          }) => {
            useEffect(() => {
              if (referral.length === 7) {
                checkReferralCode(referral, setFieldValue)
              }
            }, [referral])

            return (
              <>
                <Grid container width={{ xs: '100%', md: '50%' }}>
                  <Form style={{ width: '100%' }}>
                    <Box
                      p={'32px'}
                      borderRadius={'8px'}
                      display={'flex'}
                      flexDirection={'column'}
                      gap={'8px'}
                    >
                      <Field
                        component={CustomTextField}
                        fullWidth
                        id='name'
                        name='name'
                        placeholder='*Name'
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.name && Boolean(errors.name)}
                        helperText={touched.name && errors.name}
                      />

                      <Field
                        name='state'
                        component={CustomAutocomplete}
                        options={statesList}
                        getOptionLabel={(option: string) => option}
                        onChange={(_, value) => setFieldValue('state', value)}
                        onBlur={() => setFieldTouched('state', true)}
                        disableClearable
                        renderInput={(params) => (
                          <CustomTextField
                            {...params}
                            error={touched.state && Boolean(errors.state)}
                            helperText={touched.state && errors.state}
                            placeholder='*State'
                          />
                        )}
                      />

                      <Field
                        component={CustomTextField}
                        fullWidth
                        id='email'
                        name='email'
                        placeholder='*Email'
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.email && Boolean(errors.email)}
                        helperText={touched.email && errors.email}
                      />

                      <Field
                        component={CustomTextField}
                        fullWidth
                        id='phone'
                        name='phone'
                        placeholder='Phone number'
                        value={values.phone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.phone && Boolean(errors.phone)}
                        helperText={touched.phone && errors.phone}
                      />

                      {!showReferralCodeField && (
                        <CustomText
                          onClick={() => setShowReferralCodeField(true)}
                          color={'#9747FF'}
                          sx={{ cursor: 'pointer' }}
                        >
                          I have a referral code
                        </CustomText>
                      )}

                      {showReferralCodeField && (
                        <>
                          <Field
                            component={CustomTextField}
                            fullWidth
                            id='referralCode'
                            name='referralCode'
                            placeholder='Referral code (optional)'
                            value={values.referralCode}
                            onChange={(e) => {
                              handleChange(e)
                              const { value } = e.target
                              if (value.length === 7) {
                                checkReferralCode(value, setFieldValue)
                              }
                            }}
                            onBlur={handleBlur}
                            error={touched.referralCode && Boolean(errors.referralCode)}
                            helperText={touched.referralCode && errors.referralCode}
                            inputProps={{
                              maxLength: 7,
                            }}
                          />

                          <CustomText
                            sx={{
                              display:
                                values.codeFound === 'Valid' && Number(values.codeBenefit) > 0
                                  ? 'block'
                                  : 'none',
                            }}
                          >
                            Great! {values.codeBenefit} discount will be applied to your
                            application.
                          </CustomText>

                          <CustomText
                            color={'red'}
                            sx={{
                              display: values.codeFound === 'Invalid' ? 'block' : 'none',
                            }}
                          >
                            {values.referralCode} is no longer valid. You can still apply without
                            the code.
                          </CustomText>

                          <CustomText
                            color={'red'}
                            sx={{
                              display: values.codeFound === 'Not_Found' ? 'block' : 'none',
                            }}
                          >
                            {values.referralCode} is not a valid code. Please check and try again.
                            You can still apply without the code.
                          </CustomText>
                        </>
                      )}
                    </Box>
                  </Form>
                </Grid>

                <Grid container justifyContent={'center'} alignItems={'center'} mt={'16px'}>
                  <Button
                    sx={{ padding: '16px' }}
                    variant='contained'
                    disabled={!!isSubmitting}
                    onClick={() => handleSubmit()}
                    type='submit'
                    endIcon={
                      !!isSubmitting ? (
                        <CircularProgress size={'20px'} sx={{ color: 'white' }} />
                      ) : (
                        <EastIcon />
                      )
                    }
                  >
                    Continue
                  </Button>
                </Grid>

                <TrustedByEmployees title='Join the ranks of savvy employees at these companies who are already using Benny' />
              </>
            )
          }}
        </Formik>
      </Grid>
    </Grid>
  )
}
