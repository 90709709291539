import MenuIcon from '@mui/icons-material/Menu'
import {
  AppBar,
  Box,
  Button,
  Container,
  Drawer,
  IconButton,
  Link as MuiLink,
  List,
  ListItem,
  ListItemButton,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { motion, useScroll } from 'framer-motion'
import { useEffect, useState } from 'react'
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom'
import { Link as ScrollLink } from 'react-scroll'
import { useCustomParams } from '../../hooks/useCustomParams'
import logo from '../../media/images/logos/newlogo.svg'

interface NavLinkProps {
  to: string
  label: string
  isExternal?: boolean
}

const NavLink = ({ to, label, isExternal = false }: NavLinkProps) => {
  const location = useLocation()
  const active = location.pathname === to || location.hash === to

  const getLinkComponent = () => {
    if (to.startsWith('#')) {
      return (
        <ScrollLink
          to={to.substring(1)}
          spy
          smooth
          duration={500}
          activeClass='active'
          style={{
            fontSize: '18px',
            fontWeight: 400,
            lineHeight: '125%',
            textDecoration: 'none',
            fontFamily: 'Helvetica Neue',
            color: '#194339',
            cursor: 'pointer',
          }}
        >
          {label}
        </ScrollLink>
      )
    } else if (isExternal) {
      return (
        <MuiLink
          href={to}
          target='_blank'
          color='#194339'
          sx={{
            fontSize: '18px',
            fontWeight: 400,
            lineHeight: '125%',
            textDecoration: 'none',
            fontFamily: 'Helvetica Neue',
          }}
        >
          {label}
        </MuiLink>
      )
    } else {
      return (
        <MuiLink
          component={RouterLink}
          to={to}
          color='#194339'
          sx={{
            fontSize: '18px',
            fontWeight: 400,
            lineHeight: '125%',
            textDecoration: 'none',
            fontFamily: 'Helvetica Neue',
          }}
        >
          {label}
        </MuiLink>
      )
    }
  }

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        px: '16px',
        borderBottom: active ? '3px solid #194339' : 'none',
      }}
    >
      {getLinkComponent()}
    </Box>
  )
}

interface DrawerContentProps {
  navList: NavLinkProps[]
  closeDrawer: (event?: React.KeyboardEvent | React.MouseEvent) => void
  customCta?: { label: string; onClick: () => void }
}

const DrawerContent = ({ navList, closeDrawer, customCta }: DrawerContentProps) => {
  const getListItemButtonComponent = (item: NavLinkProps) => {
    if (item.to.startsWith('#')) {
      return (
        <ScrollLink
          to={item.to.substring(1)}
          spy
          smooth
          duration={500}
          onClick={closeDrawer}
          style={{
            textDecoration: 'none',
            color: '#194339',
          }}
        >
          {item.label}
        </ScrollLink>
      )
    } else if (item.isExternal) {
      return (
        <MuiLink
          href={item.to}
          target='_blank'
          onClick={closeDrawer}
          style={{
            textDecoration: 'none',
            color: '#194339',
          }}
        >
          {item.label}
        </MuiLink>
      )
    } else {
      return (
        <RouterLink
          to={item.to}
          onClick={closeDrawer}
          style={{
            textDecoration: 'none',
            color: '#194339',
          }}
        >
          {item.label}
        </RouterLink>
      )
    }
  }

  return (
    <Box role='presentation' onClick={closeDrawer} onKeyDown={closeDrawer} sx={{ width: 250 }}>
      <List>
        {navList
          .concat(customCta ? [] : [{ to: '/apply', label: 'Apply' }])
          .filter((item) => item)
          .map((item, index) => (
            <ListItem key={index} disablePadding>
              <ListItemButton component='div'>{getListItemButtonComponent(item)}</ListItemButton>
            </ListItem>
          ))}

        {customCta && (
          <ListItem disablePadding onClick={customCta.onClick}>
            <ListItemButton component='div'>{customCta.label}</ListItemButton>
          </ListItem>
        )}
      </List>
    </Box>
  )
}

interface HeaderProps {
  onGetStarted: () => void
  navList: NavLinkProps[]
  customCta?: { label: string; onClick: () => void }
}

export const Header = ({ onGetStarted, navList, customCta }: HeaderProps) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const { scrollYProgress } = useScroll()
  const [showButton, setShowButton] = useState(false)

  const [open, setOpen] = useState<boolean>(false)

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return
    }

    setOpen(open)
  }

  useEffect(() => {
    const unsubScrollY = scrollYProgress.onChange((current) => {
      setShowButton(current > 0.07)
    })

    return () => unsubScrollY()
  }, [scrollYProgress])

  return (
    <AppBar
      position='sticky'
      sx={{ height: '90px' }}
      style={{ backgroundColor: '#FFFFFF', borderBottom: '1px solid #E2DBEC' }}
      elevation={0}
    >
      <Container maxWidth='xl' sx={{ height: '100%' }}>
        <Toolbar
          sx={{
            height: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            px: 0,
          }}
        >
          <RouterLink to={'/'}>
            <img src={logo} width='114px' height='40px' alt='Rooted' loading='lazy' />
          </RouterLink>

          {isMobile ? (
            <>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: showButton ? 1 : 0 }}
                transition={{ duration: 0.5 }}
              >
                <Button
                  variant='contained'
                  disabled={!showButton}
                  onClick={() => {
                    window.location.href = 'https://app.heybenny.com/auth/sign-up'
                  }}
                >
                  Get Started
                </Button>
              </motion.div>

              <IconButton
                edge='start'
                color='inherit'
                aria-label='menu'
                onClick={() => setOpen(true)}
              >
                <Typography
                  sx={{
                    color: '#9747FF',
                    display: 'flex',
                    fontSize: '18px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontWeight: '700',
                  }}
                >
                  <MenuIcon sx={{ color: '#9747FF', fontSize: '24px', fontWeight: '900' }} />
                </Typography>
              </IconButton>
              <Drawer open={open} anchor='right' onClose={toggleDrawer(false)}>
                <DrawerContent
                  navList={navList}
                  closeDrawer={toggleDrawer(false)}
                  customCta={customCta}
                />
              </Drawer>
            </>
          ) : (
            <nav
              style={{
                display: 'flex',
                justifyContent: 'center',
                height: '100%',
                alignItems: 'center',
              }}
            >
              {navList.map((item, idx) => (
                <NavLink key={`${idx}-${item.label}`} {...item} />
              ))}
            </nav>
          )}

          {!isMobile && (
            <Box display={'flex'} justifyContent={'center'} alignItems={'center'} gap={'32px'}>
              <MuiLink
                href='tel:6083026665'
                color='#194339'
                sx={{
                  fontSize: '18px',
                  fontWeight: 400,
                  lineHeight: '125%',
                  textDecoration: 'none',
                  fontFamily: 'Helvetica Neue',
                }}
              >
                (608) 302-6665
              </MuiLink>

              <Button
                variant='contained'
                onClick={() => {
                  window.location.href = 'https://app.heybenny.com/auth/sign-up'
                }}
              >
                {customCta ? customCta?.label : 'Get Started'}
              </Button>
            </Box>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  )
}

export const defaultNavList: NavLinkProps[] = [
  {
    to: '/espp-calculator',
    label: 'ESPP Calculator',
  },

  {
    to: '/about-us',
    label: 'About Us',
  },
  {
    to: '/contact-us',
    label: 'Contact Us',
  },
  {
    to: 'https://blog.heybenny.com',
    label: 'Blog',
    isExternal: true,
  },
  {
    to: '/login',
    label: 'Login',
  },
]

export interface WrapperHeaderProps {
  navList?: NavLinkProps[]
  extraQueryParams?: Record<string, string | number | boolean>
  customCta?: { label: string; onClick: () => void }
}

export const WrapperHeader = ({
  navList = defaultNavList,
  extraQueryParams = {},
  customCta,
}: WrapperHeaderProps) => {
  const navigate = useNavigate()

  const { employer, income, referral } = useCustomParams()

  const queryParams = new URLSearchParams()

  if (employer) queryParams.set('employer', employer)
  if (income) queryParams.set('income', income)
  if (referral) queryParams.set('referral', referral)

  Object.entries(extraQueryParams).forEach(([key, value]) => {
    queryParams.set(key, value.toString())
  })

  const url = `/apply?${queryParams.toString()}`

  const onGetStarted = () => {
    navigate(url)
  }

  return <Header onGetStarted={onGetStarted} navList={navList} customCta={customCta} />
}
