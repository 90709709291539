import { Box, CircularProgress, Grid, Typography } from '@mui/material'
import { CustomButton } from 'Components/CustomButton'
import { getTextStyleVariant } from 'Components/Typography'
import { Fragment, useRef } from 'react'
import filesImg from '../../../../media/icons/files.svg'
import { Application } from '../../../../types'
import { formatToDollars } from '../../../../utils/commons'

interface EmploymentVerificationUploaderProps {
  loanAmount: Application['loan_amount']
  newfile: File | null
  setNewfile: (file: File) => void
  sendVerification: () => void
  loading: boolean,
  needsUpdate:boolean
}

export const EmploymentVerificationUploader = ({
  loanAmount,
  newfile,
  setNewfile,
  sendVerification,
  loading,
  needsUpdate
}: EmploymentVerificationUploaderProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null)

  const handleClick = () => {
    console.log(fileInputRef)
    console.log('current', fileInputRef.current)

    if (fileInputRef.current) {
      console.log('HERE-----')
      fileInputRef.current.click()
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null
    if (file) {
      setNewfile(file)
    }
  }

  const handleDragOver = (e) => {
    e.preventDefault()
  }

  const handleDrop = (e) => {
    e.preventDefault()
    const file = e.dataTransfer.files[0]
    if (file) {
      setNewfile(file)
    }
  }

  const truncatedFileName = `${newfile?.name.substring(0, 5)}...${newfile?.name.substring(
    newfile?.name.length - 5,
  )}`

  return (
    <Fragment>
      <div id={'EmploymentVerificationUploader'} />
      <Grid container margin={'0 auto'} maxWidth={'950px'}>
        <Grid>
          <Typography
            sx={{
              color: '#194339',
              textAlign: 'left',
              fontSize: { xs: '20px', sm: '40px' },
              fontFamily: 'Garnett-Regular',
            }}
            lineHeight={'normal'}
          >
            Verify your employment to unlock
          </Typography>

          <Typography
            lineHeight={'normal'}
            sx={{
              color: '#9747FF',
              textAlign: 'left',
              fontSize: { xs: '20px', sm: '40px' },
              fontFamily: 'Garnett-medium',
            }}
          >
            {formatToDollars(loanAmount)} in ESPP Funding
          </Typography>

          <Typography fontSize={'21px'} lineHeight={'normal'} mt={'16px'} color={'#242424'}>
            Your pre-approval is conditional upon verifying employment.
            <br />
            Upload your <strong>most recent pay stub</strong> allowing us to verify income and
            employment.
          </Typography>
        </Grid>

        <Grid
          sx={{
            flexDirection: 'column',
            mt: '15px',
          }}
        >
          <Grid container justifyContent={'space-between'} gap={{ xs: '64px', md: 0 }}>
            <Grid item md={6} xs={12}>
              <Box
                height='100%'
                sx={{
                  borderWidth: 1,
                  borderRadius: '24px',
                  borderColor: 'rgb(75, 40, 142)',
                  borderStyle: 'dashed',
                  backgroundColor: 'white',
                }}
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'flex-end'}
                p={'16px'}
              >
                <Box
                  display={'flex'}
                  flexGrow={1}
                  flexDirection={'column'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  component='div'
                  onClick={handleClick}
                  onDragOver={handleDragOver}
                  onDrop={handleDrop}
                  sx={{ cursor: 'pointer' }}
                >
                  <input
                    type='file'
                    ref={fileInputRef}
                    onChange={handleChange}
                    style={{ display: 'block', opacity: 0, position: 'absolute', zIndex: -1 }}
                  />

                  <img src={filesImg} alt='files' style={{ height: '100px', width: 'auto' }} />
                  <Typography
                    sx={{
                      color: '#4B288E',
                      fontSize: '24px',
                      fontFamily: 'Helvetica Neue',
                      fontWeight: '700',
                    }}
                  >
                    Drop the file here
                    <br />
                    or click to upload
                  </Typography>

                  {!!newfile && (
                    <Typography sx={{ ...getTextStyleVariant('body2'), color: '#9747FF' }}>
                      {truncatedFileName}
                    </Typography>
                  )}
                </Box>
                <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                  <CustomButton
                    title='Submit'
                    variant={!!newfile ? 'primary' : 'disabled'}
                    onClick={!!newfile || loading ? sendVerification : () => undefined}
                    buttonProps={{
                      endIcon: loading && <CircularProgress style={{ color: '#ffffff' }} />,
                      style: { padding: '16px 64px', marginTop: '8px', zIndex: 100 },
                    }}
                  />
                </Box>
              </Box>
              {
                needsUpdate && (
              
              <Typography textAlign={'center'}>
            Issues uploading your paystub?
            <br />
            Email us at {' '}
            <a
              href='mailto:verification@heybenny.com'
              target='_blank'
              style={{ textDecoration: 'underline', cursor: 'pointer', color: '#9747FF' }}
            >
              verification@heybenny.com
            </a>{' '}
            to submit
             </Typography>
                )
}
            </Grid>

            <Grid container item md={5} xs={12} sx={{ minHeight: 300, alignItems: 'center' }}>
              <div>
                <Typography
                  sx={{
                    color: '#242424',
                    fontFamily: ' Helvetica Neue',
                    fontSize: '18px',
                    fontWeight: '700',
                  }}
                >
                  Why Choose Benny to Fund Your ESPP?
                </Typography>
                <Typography
                  sx={{
                    color: '#242424',
                    fontFamily: ' Helvetica Neue',
                    fontSize: '18px',
                    fontWeight: '400',
                  }}
                >
                  ✔ No Credit Check
                  <br />
                  ✔ Trusted ESPP Experts
                  <br />
                  ✔ Direct Funding Each Pay Period
                  <br />✔ 30-day free cancellation
                </Typography>
              </div>
              <div style={{ height: '1px', backgroundColor: '#E9E9E9', width: '100%' }} />
              <div>
                <Typography
                  sx={{
                    color: '#242424',
                    fontFamily: ' Helvetica Neue',
                    fontSize: '18px',
                    fontWeight: '700',
                  }}
                >
                  Data Security and Privacy
                </Typography>
                <Typography
                  sx={{
                    color: '#242424',
                    fontFamily: ' Helvetica Neue',
                    fontSize: '18px',
                    fontWeight: '400',
                  }}
                >
                  Your data is stored using the latest encryption technologies.
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  )
}
