import { Fade, Grid, Typography } from '@mui/material'

import testimonialOne from '../../../../media/images/testimonialOne.webp'

import { useState } from 'react'
import testimonialTwo from '../../../../media/images/testimonialTwo.webp'

export const CustomerTestimonial = () => {
  const [activeTestimonial, setActiveTestimonial] = useState(1)

  /*  useEffect(() => {
    const intervalId = setInterval(() => {
      setActiveTestimonial((prevActiveTestimonial) => (prevActiveTestimonial === 1 ? 2 : 1))
    }, 10000)

    return () => clearInterval(intervalId)
  }, []) */

  return (
    <Grid
      container
      direction='column'
      justifyContent='space-between'
      margin={'0 auto'}
      maxWidth={'950px'}
    >
      <Grid item>
        <Typography
          textAlign={'center'}
          color={'#242424'}
          fontSize={{ xs: '21px', md: '32px' }}
          fontFamily={'Garnett-Medium'}
          mb={'64px'}
        >
          Hear from Benny Customers
        </Typography>
      </Grid>

      <Fade in={activeTestimonial === 1} timeout={1000}>
        <div>{activeTestimonial === 1 && <TestimonialOne />}</div>
      </Fade>

      <Fade in={activeTestimonial === 2} timeout={1000}>
        <div>{activeTestimonial === 2 && <TestimonialTwo />}</div>
      </Fade>
    </Grid>
  )
}

const TestimonialOne = () => {
  return (
    <Grid item container gap={{ xs: 4, md: 0 }}>
      <Grid item xs={12} md={6} container justifyContent={'center'}>
        <img
          src={testimonialOne}
          alt='testimonial one'
          style={{ maxWidth: '100%', height: 'auto' }}
        />
      </Grid>
      <Grid item xs={12} md={6} container direction='column' justifyContent={'end'} mb={'32px'}>
        <Typography
          fontFamily={'Garnett-Regular'}
          fontSize={{ xs: '21px', md: '24px' }}
          mb={'32px'}
        >
          “I turned my ESPP gains into a student loan payoff strategy. Benny was the one who helped
          me use my stock plan to start building financial freedom!" - Taylor S
        </Typography>
        <Typography fontSize={'16px'} fontFamily={'Garnett-Regular'}>
          Taylor has reduced her student loan by $8,000.
        </Typography>
      </Grid>
    </Grid>
  )
}

const TestimonialTwo = () => {
  return (
    <Grid item container gap={{ xs: 4, md: 0 }}>
      <Grid
        item
        xs={12}
        md={6}
        container
        direction='column'
        justifyContent={'end'}
        mb={'32px'}
        order={{ xs: 2, md: 1 }}
      >
        <Typography
          fontFamily={'Garnett-Regular'}
          fontSize={'24px'}
          mb={'32px'}
          textAlign={{ sx: 'left', md: 'right' }}
        >
          “Saving for college while managing all other expenses is not easy. Now I put my ESPP
          profits towards my daughter’s college fund. Thanks Benny!” - Tim
        </Typography>
        <Typography
          textAlign={{ sx: 'left', md: 'right' }}
          fontSize={'16px'}
          fontFamily={'Garnett-Regular'}
        >
          Tim has saved $3,600 towards college savings.
        </Typography>
      </Grid>
      <Grid item xs={12} md={6} container justifyContent={'center'} order={{ sx: 1, md: 2 }}>
        <img
          src={testimonialTwo}
          alt='testimonial one'
          style={{ maxWidth: '100%', height: 'auto' }}
        />
      </Grid>
    </Grid>
  )
}
