import { bennyApi } from '../utils/bennyApi'

interface UserResponse {
  client: {
    id: string
    email: string
    name: string
    customer_number: string
    email_verified: boolean
  }
}

export const createClient = async (userData: { email: string; password: string }) => {
  const response = await bennyApi.post<UserResponse>('/espp/create_client/', userData)
  return response.data
}

export const authenticateClient = async (userData: { email: string; password: string }) => {
  const response = await bennyApi.post<UserResponse>('/espp/token/', userData)
  return response.data
}

export const verifyApplicationEmail = async ({
  applicationId,
  code,
}: {
  applicationId: string
  code: string
}) => {
  return await bennyApi.put(`/espp/verification_email/${applicationId}/`, {
    code,
  })
}

export const sendVerificationEmail = async (email: string) => {
  try {
    const response = await bennyApi.post<{ message: string }>(
      `/espp/client_verification_email/${email}/`,
    )

    if (response.status === 204) {
      return { message: 'Email already verified.', status: response.status }
    }

    return { ...response.data, status: response.status }
  } catch (error) {
    throw error
  }
}

export const verifyClientEmail = async ({ email, code }: { email: string; code: string }) => {
  const response = await bennyApi.put<UserResponse>(`/espp/client_verification_email/${email}/`, {
    code,
  })

  return response.data
}

export const sendVerificationEmails = async ({
  applicationId,
  clientEmail,
}: {
  applicationId: string
  clientEmail: string
}) => {
  const response = await bennyApi.post<{ message: string }>(
    `/espp/link_client_to_aplication/${applicationId}/${clientEmail}/`,
  )

  return response.data
}

export const linkClientAplication = async ({
  applicationId,
  clientEmail,
}: {
  applicationId: string
  clientEmail: string
}) => {
  try {
    const response = await bennyApi.put<UserResponse>(
      `/espp/link_client_to_aplication/${applicationId}/${clientEmail}/`,
    )

    return { ...response.data, status: response.status }
  } catch (error) {
    if (error.response && error.response.data && error.response.data.error) {
      throw new Error(error.response.data.error)
    } else {
      throw new Error('An unknown error occurred')
    }
  }
}
