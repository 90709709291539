import { makeStyles } from '@mui/styles'

interface StylesProps {
  fullscreen: boolean
}

const useLoadingStyles = makeStyles({
  root: (props: StylesProps) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: props.fullscreen ? '100vh' : 'auto',
  }),
  dot: {
    width: 20,
    height: 20,
    borderRadius: '50%',
    background: 'linear-gradient(106.91deg, #60CA95 -0.41%, #4B288E 71.48%)',
    margin: '0 8px',
    animation: '$bounce 1.4s infinite both',
  },
  '@keyframes bounce': {
    '0%, 20%, 50%, 80%, 100%': {
      transform: 'translateY(0)',
    },
    '40%': {
      transform: 'translateY(-20px)',
    },
    '60%': {
      transform: 'translateY(-10px)',
    },
  },
  '@media (max-width:600px)': {
    dot: {
      width: 10,
      height: 10,
    },
  },
})

interface LoadingDotsProps {
  count?: number
  delay?: number
  fullscreen?: boolean
}

export function LoadingDots({ count, delay, fullscreen }: LoadingDotsProps) {
  const classes = useLoadingStyles({ fullscreen })

  return (
    <div className={classes.root}>
      {Array.from({ length: count }).map((_, index) => (
        <div key={index} className={classes.dot} style={{ animationDelay: `${delay * index}s` }} />
      ))}
    </div>
  )
}

LoadingDots.defaultProps = {
  count: 3,
  delay: 0.2,
  fullscreen: true,
}
