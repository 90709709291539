import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-mui'
import * as mui from '@mui/material'
import React from 'react'
import * as yup from 'yup'

import { Typography } from 'Components/Typography'
import { LabelVertical } from 'Components/LabelVertical'
import { graphqlClient } from 'utils/graphql'

import { Layout } from '../Layout'


const validationSchema = yup.object().shape({
  email: yup.string().email().required('Required'),
})

export function ForgotPassword(props: {goBack: () => void}) {
  const [commonErrors, setCommonErrors] = React.useState<string[]>([])
  const [showSuccess, setShowSuccess] = React.useState(false)

  function handleResetPassword(values: { email: string }, setFieldError: (f: string, e: string) => void) {
    graphqlClient.chain.mutation
      .resetPassword({ email: values.email })
      .execute({ __scalar: 1 })
      .then(() => {
        setShowSuccess(true)
      })
      .catch(e => {
        if (e.errors[0].extensions.common_errors) {
          setCommonErrors(e.errors[0].extensions.common_errors)
        } else if (e.errors[0].extensions.field_errors) {
          for (const [field, error] of Object.entries(e.errors[0].extensions.field_errors)) {
            setFieldError(field, error as string)
          }
        } else {
          throw e
        }
      })
  }

  if (showSuccess) {
    return (
      <Layout>
        <Typography variant="body2" sx={{ color: 'black', mb: 3 }}>
          A password reset link was sent. It should arrive in your inbox shortly.
        </Typography>
        <mui.Button onClick={props.goBack} variant="contained" type="submit">
          Return to login
        </mui.Button>
      </Layout>
    )
  }

  return (
    <Layout>
      <Typography sx={{ color: 'black', mb: 3 }}>
        Please enter your email to get the link to set a new password.
      </Typography>
      <Formik
        initialValues={{ email: '' }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, setFieldError }) => {
          handleResetPassword(values, setFieldError)
          setSubmitting(false)
        }}
      >
        {({ submitForm, isSubmitting }) => (
          <Form>
            {commonErrors.map((error, i) => (
              <mui.Alert key={i} severity="error">{error}</mui.Alert>
            ))}
            <LabelVertical
              field={
                <Field
                  component={TextField}
                  InputProps={{
                    autoComplete: 'email',
                    autoFocus: true,
                    fullWidth: true,
                    size: 'small',
                    type: 'email',
                  }}
                  name="email"
                  fullWidth
                  required
                />
              }
              label='Email address'
              sx={{ my: 2 }}
            />
            <mui.Button
              disabled={isSubmitting}
              onClick={submitForm}
              variant="contained"
            >
              Reset password
            </mui.Button>
          </Form>
        )}
      </Formik>
    </Layout>
  )
}