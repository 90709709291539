import { useEffect, useState } from 'react'
import { Espp } from '../types'
import { bennyApi } from '../utils/bennyApi'

export const useEsppByEmployer = (employer?: string) => {
  const [espp, setEspp] = useState<(Espp & { dates: string }) | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<Error | null>(null)

  useEffect(() => {
    if (!employer) return

    const fetchEsppByEmployer = async () => {
      setIsLoading(true)
      try {
        const response = await bennyApi.get<Espp & { dates: string }>(
          `/espp/espp_by_company/${employer}/`,
        )
        setEspp(response.data)
      } catch (error) {
        console.error('Error fetchEsppByEmployer:', error)
        setEspp(null)
        setError(error as Error)
      } finally {
        setIsLoading(false)
      }
    }

    fetchEsppByEmployer()
  }, [employer])

  return { espp, isLoading, error }
}
