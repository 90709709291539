import { Button, ButtonProps, SxProps, Theme } from '@mui/material'
import React from 'react'
import { Link } from 'react-scroll'
import { colors } from '../../utils/colors'

interface CustomButtonProps {
  variant?: 'outlinePrimary' | 'primary' | 'secundary' | 'disabled'
  style?: React.CSSProperties
  title: string
  onClick?: () => void
  to?: string
  buttonProps?: ButtonProps
}

const variants: Record<string, React.CSSProperties> = {
  primary: {
    border: `1px solid ${colors.primary}`,
    background: `${colors.primary}`,
    color: '#FFFFFF',
    padding: '16px 32px',
    fontWeight: 'bold',
  },
  secundary: {
    border: '1px solid #FFFFFF',
    background: '#FFFFFF',
    color: `${colors.primary}`,
    padding: '16px 32px',
    fontWeight: 'bold',
  },
  outlinePrimary: {
    border: `1px solid ${colors.primary}`,
    background: 'transparent',
    color: `${colors.primary}`,
    padding: '16px 32px',
    fontWeight: 'bold',
  },
  disabled: {
    border: '1px solid #4F4F4F',
    background: '#4F4F4F',
    color: '#D3D3D3',
    padding: '16px 32px',
    fontWeight: 'bold',
  },
}

export function CustomButton({
  variant = 'outlinePrimary',
  style = {},
  title,
  onClick,
  to,
  buttonProps,
}: CustomButtonProps) {
  const isDisabled = variant === 'disabled'

  const buttonStyles: SxProps<Theme> = {
    borderRadius: '100px',
    textTransform: 'none',
    fontSize: '18px',
    ...variants[variant],
    ...style,
    '&:hover': {
      backgroundColor: variants[variant]?.background,
      ...(isDisabled
        ? { cursor: 'not-allowed' }
        : {
            transform: 'scale(0.98)',
            '& svg': {
              transform: 'translateX(5px)',
            },
          }),
    },
  }

  const buttonContent = (
    <Button sx={buttonStyles} onClick={onClick} {...buttonProps}>
      {title}
    </Button>
  )

  return !!to ? (
    <Link to={to} smooth>
      {buttonContent}
    </Link>
  ) : (
    buttonContent
  )
}

CustomButton.defaultProps = {
  style: {},
  variant: 'outlinePrimary',
  onClick: () => {},
  to: '',
}
