import { useEffect, useState } from 'react'
import { Application } from 'types'
import { bennyApi } from 'utils/bennyApi'

export interface ICustomerApplications {
  applications: {
    bennyFee?: number
    application: Application & { name: string }
    disbursements: {
      disbursement_amount: string
      paycheck_date: string
      disbursement_date: string
    }[]
  }[]
  referral_code: { active: boolean; code: string; receiver_benefit: number; sender_benefit: number }
}

export const useGetCustomerApplications = (email: string, clientId: string) => {
  const [account, setAccount] = useState<ICustomerApplications>()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const getCustomerApplications = async () => {
      try {
        setIsLoading(true)
        const response = await bennyApi.get<ICustomerApplications>(
          `/espp/get_customer_applications/${email}/${clientId}`,
        )
        setAccount(response.data)
        setIsLoading(false)
      } catch (error) {
        console.error(error)
      }
    }

    if (!!email && !!clientId) {
      getCustomerApplications()
    }
  }, [email, clientId])

  return { account, isLoading }
}
