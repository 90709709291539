import { Grid } from '@mui/material'
import { useEffect, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'

import { Layout } from 'Components/Layout'
import { Typography } from 'Components/Typography'
import { getCookie } from 'helpers/cookieHelper'
import { useEventNavigation } from 'hooks/tracker'

export const SuperMoney = () => {
  const widgetRef = useRef(null)
  const [searchParams] = useSearchParams()
  const id = searchParams.get('id')

  useEventNavigation()

  useEffect(() => {
    const script = document.createElement('script')
    script.innerHTML = `
      window.smOptions = window.smOptions || {
        primaryColor: "",
        btnTextColor: "",
        linkTextColor: "",
        accentColor: "",
        ratingPositiveColor: "",
        ratingNeutralColor: "",
        ratingNegativeColor: "",
        stars: true,
        display: "full",
      };
      window.iframeSrc = window.iframeSrc || [];
      window.iframeSrc.push({
        src: 'https://track.supermoney.com/aff_c?offer_id=1717&aff_id=2412&file_id=673&click_id=${id}&aff_sub=${getCookie('customerId')}'
      });
      if (!document.getElementById('smEmbedHelper')) {
        var script = document.createElement('script');
        script.id = 'smEmbedHelper';
        script.src = 'https://app.supermoney.com/includes/js/embed_helper.js';
        document.getElementsByTagName("head")[0].appendChild(script);
      }
    `
    widgetRef.current.appendChild(script)

    const img = document.createElement('img')
    img.src = `https://track.supermoney.com/aff_i?offer_id=1717&file_id=673&aff_id=1187&click_id=${id}`
    img.width = 0
    img.height = 0
    img.style.position = 'absolute'
    img.style.visibility = 'hidden'
    document.body.appendChild(img)
  }, [])

  return (
    <Layout>
      <Grid container maxWidth={'1200px'} mx='auto' my={'32px'} spacing={2}>
        <Grid item xs={12}>
          <Typography variant='h2' textAlign={'center'}>
            Ready to unlock the full potential of your ESPP?
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography textAlign={'center'}>
            Ready to unlock the full potential of your ESPP? Complete your funding journey by
            applying for a Personal Line of Credit in just a few easy steps. After approval, Benny
            will manage the funds to ensure you're making the most of your ESPP!
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <iframe
            ref={widgetRef}
            className='sm_widget'
            style={{ width: '100%', border: 'none', minHeight: '80vh' }}
          ></iframe>
        </Grid>
      </Grid>
    </Layout>
  )
}
