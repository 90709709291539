import * as mui from '@mui/material'

const rainbow = {
  background: 'linear-gradient(282deg, #581BBB -0.5%, #0CA671 98.84%)',
  backgroundClip: 'text',
  lineHeight: '1.35',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
}

export function getTextStyleVariant(variant: string, inheritColor?: boolean, isLong?: boolean) {
  switch (variant) {
    case 'h1':
      return {
        ...(inheritColor ? {} : rainbow),
        fontFamily: 'Garnett-Bold, Roboto, "Helvetica Neue", Arial, sans-serif',
        fontSize: isLong ? { xs: '28px', md: '40px' } : { xs: '36px', md: '48px' },
        fontWeight: 750,
      }
    case 'h2':
      return {
        color: inheritColor ? undefined : (theme: mui.Theme) => theme.palette.primary.main,
        fontFamily: 'Garnett-Bold, Roboto, "Helvetica Neue", Arial, sans-serif',
        fontSize: '24px',
        fontWeight: 750,
        lineHeight: '1.35',
      }
    case 'h3':
      return {
        color: inheritColor ? undefined : (theme: mui.Theme) => theme.palette.primary.main,
        fontFamily: 'Garnett, Roboto, "Helvetica Neue", Arial, sans-serif',
        fontWeight: 700,
        fontSize: { xs: '24px', md: '24px' },
      }
    case 'subtitle1':
      return {
        color: inheritColor ? undefined : (theme: mui.Theme) => theme.palette.primary.main,
        fontFamily: '"Helvetica Neue", Arial, sans-serif',
        fontSize: { xs: '18px', md: '24px' },
        fontWeight: 700,
        lineHeight: '1.35',
      }
    case 'body2':
      return {
        color: inheritColor ? undefined : (theme: mui.Theme) => theme.palette.primary.main,
        fontFamily: '"Helvetica Neue", Arial, sans-serif',
        fontSize: { xs: '15px', md: '20px' },
        lineHeight: '1.25',
      }
    default: // body1
      return {
        color: inheritColor ? undefined : (theme: mui.Theme) => theme.palette.primary.main,
        fontFamily: '"Helvetica Neue", Arial, sans-serif',
        fontSize: { xs: '18px', md: '22px' },
        lineHeight: '1.25',
        fontWeight: 400,
        fontStyle: 'normal',
      }
  }
}

export function Typography(
  props: mui.TypographyProps & { inheritColor?: boolean; isLong?: boolean },
) {
  const { inheritColor, isLong, sx: sxOverrides, ...otherProps } = props
  return (
    <mui.Typography
      sx={{ ...getTextStyleVariant(props.variant, inheritColor, isLong), ...sxOverrides }}
      {...otherProps}
    />
  )
}
