import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import * as mui from '@mui/material'
import { Calendly } from 'Components/calendly'
import { Typography } from 'Components/Typography'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { graphqlClient } from 'utils/graphql'
import personImage from '../../media/images/homepage3/bgperson.png'
import WaitlistHeader from './components/header'

export const Waitlist = () => {
  const [isCalendlyOpen, setIsCalendlyOpen] = useState(false)
  const [email, setEmail] = useState('')
  const [showAlert, setShowAlert] = useState<boolean>(false)
  const [alertMessage, setAlertMessage] = useState<string>('')
  const [alertSeverity, setAlertSeverity] = useState<mui.AlertColor>('success')

  const navigate = useNavigate()

  const handleCalendlyOpen = () => {
    setIsCalendlyOpen(true)
  }

  const handleCalendlyClose = () => {
    setIsCalendlyOpen(false)
  }

  const handleJoin = () => {
    navigate('/apply')
  }

  const handleEmailChange = (event) => {
    setEmail(event.target.value)
  }

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setShowAlert(false)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (!email) return

    try {
      const response = await graphqlClient.chain.mutation
        .createWaitlist({ email })
        .execute({ __scalar: 1 })

      if (response.success) {
        setEmail('')
        setShowAlert(true)
        setAlertMessage('Your email has been successfully added to the waitlist.')
        setAlertSeverity('success')
        console.log('Email saved in the waitlist')
      } else {
        setShowAlert(true)
        setAlertMessage('Error saving the email in the waitlist.')
        setAlertSeverity('error')
        console.error('Error saving the email in the waitlist')
      }
    } catch (error) {
      if (error?.errors?.[0]?.extensions?.field_errors?.email) {
        setShowAlert(true)
        setAlertMessage('The email already exists in the waitlist.')
        setAlertSeverity('warning')
      } else {
        setShowAlert(true)
        setAlertMessage('Error saving the email in the waitlist.')
        setAlertSeverity('error')
        console.error('Error saving the email in the waitlist:', error)
      }
    }
  }

  return (
    <mui.Grid
      container
      direction='column'
      sx={{
        minHeight: '100vh',
        background: 'linear-gradient(282deg, #581BBB -0.5%, #0CA671 98.84%)',
      }}
    >
      <WaitlistHeader />

      <Calendly open={isCalendlyOpen} onModalClose={handleCalendlyClose} />

      <mui.Snackbar
        open={showAlert}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <mui.Alert onClose={handleClose} severity={alertSeverity} sx={{ width: '100%' }}>
          {alertMessage}
        </mui.Alert>
      </mui.Snackbar>

      <mui.Grid
        item
        xs
        container
        sx={{
          minHeight: '100vh',
        }}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <mui.Container maxWidth='lg'>
          <mui.Grid container spacing={{ xs: 2, md: 0 }}>
            <mui.Grid
              item
              order={{ xs: 1, md: 0 }}
              xs={12}
              md={5}
              sx={{
                display: 'flex',
                alignItems: 'flex-end',
                justifyContent: 'center',
                mt: 12,
              }}
            >
              <mui.Box
                sx={{
                  position: 'relative',
                  borderRadius: '20px',
                  backgroundColor: '#fff',
                  p: '20px',
                  minHeight: { xs: '230px', md: '230px' },
                  maxWidth: '400px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-end',
                }}
              >
                <mui.Stack direction='row' alignItems='center' spacing={2}>
                  <mui.Box>
                    <Typography variant='body2' sx={{ fontFamily: 'Garnett-semibold' }}>
                      Benny Maximizes Your ESPP: Funding & Expertise
                    </Typography>
                    <Typography variant='body2' sx={{ fontSize: '16px' }}>
                      Get all-inclusive ESPP management for $15/month
                    </Typography>
                  </mui.Box>

                  <mui.IconButton
                    onClick={handleJoin}
                    sx={{
                      backgroundColor: '#581BBB',
                      color: '#fff',
                      borderRadius: '50%',
                      width: '40px',
                      height: '40px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      '&:hover': {
                        backgroundColor: '#581BBB',
                        color: '#fff',
                      },
                    }}
                  >
                    <mui.Icon>
                      <ArrowForwardIosIcon />
                    </mui.Icon>
                  </mui.IconButton>
                </mui.Stack>

                <mui.Box
                  component='img'
                  src={personImage}
                  alt=''
                  sx={{
                    position: 'absolute',
                    top: { xs: '10%', md: '-20%' },
                    left: { xs: '50%', md: '50%' },
                    transform: 'translate(-50%, -50%)',
                    width: { xs: '75%', md: '90%' },
                    height: 'auto',
                    zIndex: 1,
                  }}
                />

                <mui.Paper
                  sx={{
                    position: 'absolute',
                    top: { xs: 0, md: -100 },
                    right: { xs: 0, md: -150 },
                    padding: '10px 20px 20px 20px',
                    borderRadius: '30px',
                    backgroundColor: '#fff',
                    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.06)',
                    zIndex: 100,
                  }}
                >
                  <Typography
                    sx={{
                      color: '#808080',
                      fontSize: '18px',
                      fontStyle: 'normal',
                      fontWeight: '300',
                      lineHeight: '24px',
                    }}
                  >
                    Jan 15
                  </Typography>
                  <Typography
                    sx={{
                      color: '#000',
                      fontSize: '18px',
                      fontStyle: 'normal',
                      fontWeight: '300',
                      lineHeight: '24px',
                      mb: '20px',
                    }}
                  >
                    <strong>ESPP Cash</strong>
                    <br />
                    Deposit
                  </Typography>
                  <Typography
                    component='span'
                    sx={{
                      color: (theme) => theme.palette.primary.light,
                      fontSize: '48px',
                      fontStyle: 'normal',
                      fontWeight: '700',
                      lineHeight: '36px',
                    }}
                  >
                    +
                  </Typography>
                  <Typography
                    component='span'
                    sx={{
                      fontSize: '48px',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      lineHeight: '36px',
                    }}
                  >
                    $513
                  </Typography>
                </mui.Paper>
              </mui.Box>
            </mui.Grid>

            <mui.Grid
              item
              order={{ xs: 0, md: 1 }}
              xs={12}
              md={7}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              <mui.Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-end',
                  gap: '16px',
                }}
              >
                <Typography
                  variant='h1'
                  inheritColor
                  sx={{
                    color: '#fff',
                    textAlign: { xs: 'center', md: 'right' },
                    fontFamily: 'Garnett-Regular',
                    fontSize: { xs: '30px', md: '50px' },
                  }}
                >
                  EARN MORE
                  <br />
                  FROM YOUR ESPP
                  <Typography
                    variant='h1'
                    inheritColor
                    sx={{
                      color: '#fff',
                      textAlign: { xs: 'center', md: 'right' },
                      fontFamily: 'Garnett-Bold',
                      fontSize: { xs: '40px', md: '65px' },
                    }}
                  >
                    WITH BENNY
                  </Typography>
                </Typography>

                <Typography
                  sx={{
                    display: { xs: 'none', md: 'block' },
                    color: '#fff',
                    textAlign: { xs: 'center', md: 'right' },
                  }}
                >
                  The Benny ESPP Management waitlist is open,
                  <br />
                  join today to reserve your spot
                </Typography>

                <Typography
                  sx={{
                    display: { xs: 'block', md: 'none' },
                    color: '#fff',
                    textAlign: { xs: 'center', md: 'right' },
                  }}
                >
                  The Benny ESPP Money Management waitlist is open, join today to reserve your spot
                </Typography>

                <mui.Box
                  sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    justifyContent: { xs: 'center', md: 'flex-end' },
                    gap: 2,
                  }}
                >
                  <mui.Box
                    sx={{
                      display: 'flex',
                      justifyContent: { xs: 'center', md: 'flex-end' },
                    }}
                  >
                    <mui.Paper
                      component='form'
                      sx={{
                        p: '0px 0px 0px 10px',
                        display: 'flex',
                        alignItems: 'center',
                        width: { xs: '100%', md: 400 },
                        borderRadius: '40px',
                        border: '1px solid rgba(255, 255, 255, 0.4)',
                        backdropFilter: 'blur(8px)',
                        backgroundColor: 'rgba(255, 255, 255, 0.15)',
                      }}
                    >
                      <mui.InputBase
                        sx={{
                          ml: 1,
                          flex: 1,
                          color: '#fff',
                          '& .MuiInputBase-input::placeholder': {
                            color: '#fff',
                            opacity: 1,
                          },
                        }}
                        placeholder='Enter your email'
                        inputProps={{ 'aria-label': 'enter your email' }}
                        value={email}
                        onChange={handleEmailChange}
                        type='email'
                      />
                      <mui.Button
                        onClick={handleSubmit}
                        variant='contained'
                        disableRipple
                        sx={{
                          backgroundColor: '#fff',
                          color: '#581BBB',
                          height: '100%',
                          padding: { xs: '16px' },
                          '&:hover': {
                            backgroundColor: '#fff',
                            color: '#581BBB',
                          },
                        }}
                      >
                        Join Waitlist
                      </mui.Button>
                    </mui.Paper>
                  </mui.Box>

                  <mui.Button
                    onClick={handleCalendlyOpen}
                    variant='contained'
                    disableRipple
                    sx={{
                      backgroundColor: '#fff',
                      color: '#581BBB',
                      padding: { xs: '16px' },
                      '&:hover': {
                        backgroundColor: '#fff',
                        color: '#581BBB',
                      },
                    }}
                  >
                    Talk to an ESPP Expert
                  </mui.Button>
                </mui.Box>
              </mui.Box>
            </mui.Grid>
          </mui.Grid>
        </mui.Container>
      </mui.Grid>

      <mui.Grid
        container
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          bgcolor: '#fff',
        }}
      >
        <mui.Grid
          container
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            bgcolor: '#fff',
          }}
        >
          <Typography
            variant='h3'
            textAlign={'center'}
            sx={{ py: '50px', fontWeight: 500, fontSize: { xs: '18px', md: '32px' } }}
          >
            Unlock Your ESPP's Potential with Benny
          </Typography>
        </mui.Grid>

        <mui.Grid container spacing={2} sx={{ pb: '50px', px: '32px' }}>
          <mui.Grid
            item
            xs={12}
            md={4}
            sx={{ display: 'flex', flexDirection: 'column', gap: '13px', textAlign: 'center' }}
          >
            <Typography sx={{ fontSize: '24px', fontWeight: 'bold' }}>ESPP Management</Typography>
            <Typography sx={{ fontSize: '18px' }}>
              Simplify your ESPP journey with streamlined decision-making tools from Benny. Once
              you're onboard, our team of ESPP experts will seamlessly manage your ongoing ESPP
              needs.
            </Typography>
          </mui.Grid>

          <mui.Grid
            item
            xs={12}
            md={4}
            sx={{ display: 'flex', flexDirection: 'column', gap: '13px', textAlign: 'center' }}
          >
            <Typography sx={{ fontSize: '24px', fontWeight: 'bold' }}>
              Line-of-Credit Management
            </Typography>
            <Typography sx={{ fontSize: '18px' }}>
              Access the money you need to max out your ESPP through Benny's platform while leaving
              the management complexities to us, for a 'set it and forget it' experience.
            </Typography>
          </mui.Grid>

          <mui.Grid
            item
            xs={12}
            md={4}
            sx={{ display: 'flex', flexDirection: 'column', gap: '13px', textAlign: 'center' }}
          >
            <Typography sx={{ fontSize: '24px', fontWeight: 'bold' }}>Gains Management</Typography>
            <Typography sx={{ fontSize: '18px' }}>
              Reach your financial goals with Benny's help in setting and managing your gains,
              unlocking funds for investment in IRAs, emergency savings, and more.
            </Typography>
          </mui.Grid>
        </mui.Grid>
      </mui.Grid>
    </mui.Grid>
  )
}
