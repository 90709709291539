import { PopupModal } from 'react-calendly'

interface CalendlyProps {
  open: boolean
  onModalClose: () => void
  url?: string
}

export const Calendly = ({
  open,
  onModalClose,
  url = 'https://calendly.com/rootedandy/15-minute-espp-consult',
}: CalendlyProps) => {
  return (
    <PopupModal
      url={url}
      rootElement={document.getElementById('root')}
      onModalClose={onModalClose}
      open={open}
    />
  )
}
