import Cookies from 'js-cookie'

export const setCookie = (name: string, value: string, days: number) => {
  const isProduction = window.location.hostname === 'heybenny.com'

  const options: Cookies.CookieAttributes = {
    expires: days,
    path: '/',
    domain: isProduction ? '.heybenny.com' : undefined,
    secure: isProduction,
    sameSite: 'Lax',
  }

  Cookies.set(name, value, options)
}

export const getCookie = (name: string): string | undefined => Cookies.get(name)

export const removeCookie = (name: string): void => {
  Cookies.remove(name)
}
