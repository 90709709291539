import { bennyApi } from './bennyApi'

interface SubmitContactFormParams {
  name: string
  email: string
  employer: string
  state: string
  income: number
  referralCode: string
  customerId: string
  phone?: number
}

interface SubmitContactFormResponse {
  success: boolean
  applicationId?: string
  error?: string
}

export function submitContactForm({
  name,
  email,
  employer,
  state,
  income,
  referralCode,
  customerId,
  phone,
}: SubmitContactFormParams): Promise<SubmitContactFormResponse> {
  return new Promise((resolve, reject) => {
    bennyApi
      .post(`/espp/contact/`, {
        name,
        email,
        employer,
        state,
        income,
        contribution_percentage: null,
        referral: referralCode,
        customer_id: customerId,
        phone,
      })
      .then((response) => {
        const { data } = response
        resolve({ success: true, applicationId: data.applicationId, error: data.error })
      })
      .catch((error) => {
        console.log(error)
        reject(error)
      })
  })
}
