import { Layout } from 'Components/Layout'
import { graphqlClient } from 'utils/graphql'
import { BennyLoan } from './components/bennyLoan'
import { Referral } from './components/referral'
import { Welcome } from './components/welcome'
import { ICustomerApplications } from './useGetCustomerApplications'

interface AccountProps {
  account: ICustomerApplications
  name: string
  email: string
}

export const Account = ({ account, name, email }: AccountProps) => {
  function handleLogout() {
    graphqlClient.chain.mutation.logout
      .execute({ __scalar: 1 })
      .then(() => {
        window.location.href = '/'
      })
      .catch((error) => {
        console.error(error)
      })
  }

  return (
    <Layout
      navList={[
        {
          label: 'Contact Us',
          to: 'mailto:support@heybenny.com',
        },
        { label: 'Edit Profile', to: '/change-password' },
      ]}
      customCta={{ label: 'Logout', onClick: handleLogout }}
    >
      <Welcome name={name} email={email} />

      <BennyLoan applications={account?.applications} />

      <Referral referralCode={account?.referral_code} />
    </Layout>
  )
}
