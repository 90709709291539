/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */

export enum Origin {
  HOME,
  LP,
  CLP,
}

export enum SectionTypes {
  HeroSection = 'heroSection',
  Calculator = 'calculator',
  Features = 'features',
  Testimonials = 'testimonials',
  HowDoesItWorkV2 = 'howDoesItWorkV2',
  Faqs = 'faqs',
  CTABanner = 'ctaBanner',
}

export type CtaAction = { type: 'route'; value: string } | { type: 'scroll'; value: string }

export type Cta = {
  text: string
  action: CtaAction
}

export type HeroConfig = {
  headline: string
  description: string
  ctaLeft: Cta
  ctaRight: Cta
  ctaEligibility: Cta
}

export type HeaderConfig = {
  cta?: Cta
}

export type VariantConfig = {
  sectionsOrder: SectionTypes[]
  header: HeaderConfig
  heroSection: HeroConfig
  origin: Origin
}

export const variant: VariantConfig = {
  origin: Origin.HOME,
  sectionsOrder: [
    SectionTypes.HeroSection,
    // SectionTypes.Calculator,
    SectionTypes.HowDoesItWorkV2,
    SectionTypes.Features,
    SectionTypes.Testimonials,
    SectionTypes.Faqs,
    SectionTypes.CTABanner,
  ],
  header: { cta: { text: 'Get Started', action: { type: 'route', value: '/apply' } } },
  heroSection: {
    headline: 'Benny Gives You The Money To Maximize Your ESPP.',
    description:
      'Benny funds your Employee Stock Purchase Plan (ESPP) contributions enabling you to max yours out without reducing your take-home pay.',
    ctaLeft: { text: 'Get Started', action: { type: 'route', value: '/apply?s=hero_get_started' } },
    ctaRight: { text: 'How Does It Work?', action: { type: 'scroll', value: 'howitworks' } },
    ctaEligibility: {
      text: 'Check Your Eligibility',
      action: { type: 'route', value: '/apply?s=hero_check_eligibility' },
    },
  },
}

export const variantA: VariantConfig = {
  origin: Origin.LP,
  sectionsOrder: [
    SectionTypes.HeroSection,
    SectionTypes.Calculator,
    SectionTypes.Features,
    SectionTypes.Testimonials,
    SectionTypes.HowDoesItWorkV2,
    SectionTypes.Faqs,
    SectionTypes.CTABanner,
  ],
  header: { cta: { text: 'Get Started', action: { type: 'route', value: '/apply' } } },
  heroSection: {
    headline: 'Use Your ESPP to Pay off Student Loans.',
    description:
      'Benny backs your ESPP, freeing up cash and boosting earnings to fast-track debt payoff.',
    ctaLeft: { text: 'Get Started', action: { type: 'route', value: '/apply?s=hero_get_started' } },
    ctaRight: { text: 'See how it works', action: { type: 'scroll', value: 'howitworks' } },
    ctaEligibility: {
      text: 'Check Your Eligibility',
      action: { type: 'route', value: '/apply?s=hero_check_eligibility' },
    },
  },
}

export const variantB: VariantConfig = {
  origin: Origin.LP,
  sectionsOrder: [
    SectionTypes.HeroSection,
    SectionTypes.Calculator,
    SectionTypes.Features,
    SectionTypes.Testimonials,
    SectionTypes.HowDoesItWorkV2,
    SectionTypes.Faqs,
    SectionTypes.CTABanner,
  ],
  header: { cta: { text: 'Get Started', action: { type: 'route', value: '/apply' } } },
  heroSection: {
    headline: 'Our gift to you - Benny funds your ESPP.',
    description:
      'Benny backs your ESPP, freeing up cash and boosting earnings to help the holidays go a little smoother.',
    ctaLeft: { text: 'Get Started', action: { type: 'route', value: '/apply?s=hero_get_started' } },
    ctaRight: { text: 'See how it works', action: { type: 'scroll', value: 'howitworks' } },
    ctaEligibility: {
      text: 'Check Your Eligibility',
      action: { type: 'route', value: '/apply?s=hero_check_eligibility' },
    },
  },
}

export const variantC: VariantConfig = {
  origin: Origin.LP,
  sectionsOrder: [
    SectionTypes.HeroSection,
    // SectionTypes.Calculator,
    SectionTypes.HowDoesItWorkV2,
    SectionTypes.Features,
    SectionTypes.Testimonials,
    SectionTypes.Faqs,
    SectionTypes.CTABanner,
  ],
  header: { cta: { text: 'Get Started', action: { type: 'route', value: '/apply' } } },
  heroSection: {
    headline: 'The smartest way to fund your ESPP.',
    description:
      'Let Benny fund your ESPP while still investing in your 401k. No more trade-offs, just smart finance.',
    ctaLeft: { text: 'Get Started', action: { type: 'route', value: '/apply?s=hero_get_started' } },
    ctaRight: { text: 'How Does It Work?', action: { type: 'scroll', value: 'howitworks' } },
    ctaEligibility: {
      text: 'Check Your Eligibility',
      action: { type: 'route', value: '/apply?s=hero_check_eligibility' },
    },
  },
}

export const variants = [variantA, variantB, variantC]
