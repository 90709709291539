import { Box, Grid, Tabs, Tooltip } from '@mui/material'
import { useEffect, useState } from 'react'
import { Layout } from '../../../Components/Layout'
import { Typography } from '../../../Components/Typography'
import { useCustomParams } from '../../../hooks/useCustomParams'
import { useEsppByEmployer } from '../../../hooks/useEsppByEmployer'
import { useStates } from '../../../hooks/useStates'
import { bennyApi } from '../../../utils/bennyApi'
import { calculateBennyFund } from '../../../utils/commons'
import { AboutStep } from './components/aboutStep'
import { ContactForm } from './components/contactForm'
import { DiscoverStep } from './components/discoverStep'
import { EsppPotential } from './components/esppPotential'

interface Step {
  label: string
  status: STATUS
  index: number
}

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: { xs: '12px', md: '24px' } }}>{children}</Box>}
    </div>
  )
}

type STATUS = 'active' | 'current' | 'next'

interface CustomTabProps {
  step: Step
  onChange: () => void
  isLast: boolean
}

const CustomTab = ({ step, onChange, isLast }: CustomTabProps) => {
  return (
    <Box onClick={() => onChange()} sx={{ cursor: step.status === 'next' ? 'default' : 'pointer' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
        {step.status === 'active' && (
          <Tooltip title={step.label} placement='top'>
            <svg
              width='18'
              height='18'
              viewBox='0 0 18 18'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <circle cx='9' cy='9' r='8.5' stroke='#194339' />
              <path
                d='M7.05469 13.8125L3.16406 9.92188C2.92969 9.6875 2.92969 9.28906 3.16406 9.05469L4.00781 8.21094C4.24219 7.97656 4.61719 7.97656 4.85156 8.21094L7.5 10.8359L13.125 5.21094C13.3594 4.97656 13.7344 4.97656 13.9688 5.21094L14.8125 6.05469C15.0469 6.28906 15.0469 6.6875 14.8125 6.92188L7.92188 13.8125C7.6875 14.0469 7.28906 14.0469 7.05469 13.8125Z'
                fill='#194339'
              />
            </svg>
          </Tooltip>
        )}

        {step.status === 'current' && (
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='18'
            height='18'
            viewBox='0 0 18 18'
            fill='none'
          >
            <circle cx='9' cy='9' r='6' stroke='#194339' stroke-width='6' />
          </svg>
        )}
        {step.status === 'current' && <Typography>{step.label}</Typography>}

        {step.status === 'next' && (
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='18'
            height='18'
            viewBox='0 0 18 18'
            fill='none'
          >
            <circle cx='9' cy='9' r='5' fill='#A7A7A7' />
          </svg>
        )}

        {!isLast && (
          <Box
            mx={'2px'}
            height={'1px'}
            width={'12px'}
            sx={{
              backgroundImage: 'linear-gradient(to right, #4B288E 50%, transparent 50%)',
              backgroundSize: '2px 1px',
            }}
          />
        )}
      </Box>
    </Box>
  )
}

export const WizardCalculator = () => {
  const [value, setValue] = useState<number>(0)
  const [companies, setCompanies] = useState<string[]>([])
  const [steps, setSteps] = useState<Step[]>([
    {
      label: 'Discover Your ESPP',
      status: 'current',
      index: 0,
    },
    {
      label: 'About Your ESPP',
      status: 'next',
      index: 1,
    },
    {
      label: 'Your ESPP Potential',
      status: 'next',
      index: 2,
    },
    {
      label: 'ESPP Funding Offer',
      status: 'next',
      index: 3,
    },
  ])

  const { income, referral, employer, contribution_percentage, setSearchParams, searchParams } =
    useCustomParams()
  const { espp, isLoading, error } = useEsppByEmployer(employer)
  const { statesList } = useStates()

  const bennyFund = calculateBennyFund({
    income: Number(income),
    contributionPercentage: contribution_percentage,
    espp,
  })

  const esppGain =
    bennyFund / (1 - parseFloat(`${(Number(espp?.discount) * 100) / 100}`)) - bennyFund

  const sharesWorth = bennyFund + esppGain

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const updateStepsStatus = (
    activeIndexes: number[],
    currentIndex: number,
    nextIndexes?: number[],
  ) => {
    setSteps((prevSteps) =>
      prevSteps.map((step, index) => {
        if (activeIndexes.includes(index)) {
          return { ...step, status: 'active' }
        } else if (index === currentIndex) {
          return { ...step, status: 'current' }
        } else if (nextIndexes?.includes(index)) {
          return { ...step, status: 'next' }
        }
        return step
      }),
    )
  }

  const setStepParam = (step: string) => {
    const newParams = new URLSearchParams(searchParams.toString())
    newParams.set('step', step)
    setSearchParams(newParams)
  }

  useEffect(() => {
    console.log(steps)
  }, [steps])

  useEffect(() => {
    const searchCompanies = async () => {
      try {
        const response = await bennyApi.get<{ companies: string[] }>(`/espp/company-names/`)
        const { companies } = response.data
        const sortedCompanies = companies.sort()
        setCompanies(sortedCompanies)
      } catch (error) {
        console.error(error)
      }
    }
    searchCompanies()
  }, [])

  useEffect(() => {
    if (espp) {
      const contributionPercentageNum = Number(contribution_percentage)

      if (
        contributionPercentageNum === 0 ||
        contributionPercentageNum > Number(espp.contr_percent_limit)
      ) {
        searchParams.set('contribution_percentage', espp.contr_percent_limit.toString())
        setSearchParams(searchParams)
      }
    }
  }, [contribution_percentage, espp, searchParams, setSearchParams])

  useEffect(() => {
    if (espp && employer && income) {
      updateStepsStatus([0, 1], 2)
      handleChange(undefined, 2)
      setStepParam('your_espp_potential')
    } else if (employer) {
      updateStepsStatus([0], 1)
      handleChange(undefined, 1)
      setStepParam('about_your_espp')
    } else {
      setStepParam('company_name')
    }
  }, [employer, income, espp])

  return (
    <Layout>
      <Grid container maxWidth={'970px'} mx={'auto'} p={{ xs: '24px', md: '48px' }}>
        <Box sx={{ width: '100%' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            TabIndicatorProps={{ style: { backgroundColor: 'transparent' } }}
            sx={{ borderBottom: '1px solid #60CA95' }}
          >
            <Box display={'flex'} alignItems={'center'}>
              {steps.map((step, index) => (
                <CustomTab
                  key={index}
                  step={step}
                  onChange={() => {
                    step.status === 'next' ? undefined : setValue(index)
                  }}
                  isLast={steps.length - 1 === index}
                />
              ))}
            </Box>
          </Tabs>
        </Box>

        <TabPanel value={value} index={0}>
          <DiscoverStep
            employer={employer}
            onHandled={(employer) => {
              updateStepsStatus([0], 1)
              handleChange(undefined, 1)
              searchParams.set('employer', employer)
              setSearchParams(searchParams)
              setStepParam('about_your_espp')
            }}
            companies={companies}
          />
        </TabPanel>

        <TabPanel value={value} index={1}>
          <AboutStep
            espp={espp}
            employer={employer}
            contributionPercentage={Number(espp?.contr_percent_limit)}
            income={Number(income)}
            onHandled={(income) => {
              searchParams.set('income', income)
              setSearchParams(searchParams)

              if (espp) {
                setStepParam('your_espp_potential')
                updateStepsStatus([0, 1], 2)
                handleChange(undefined, 2)
              } else {
                setStepParam('contact')
                updateStepsStatus([0, 1], 3, [2])
                handleChange(undefined, 3)
              }
            }}
          />
        </TabPanel>

        <TabPanel value={value} index={2}>
          <EsppPotential
            espp={espp}
            bennyFund={bennyFund}
            esppGain={esppGain}
            sharesWorth={sharesWorth}
            onHandled={() => {
              updateStepsStatus([2], 3)
              handleChange(undefined, 3)
              setStepParam('contact')
            }}
          />
        </TabPanel>

        <TabPanel value={value} index={3}>
          <ContactForm
            referral={referral}
            bennyFund={bennyFund}
            income={Number(income)}
            contributionPercentage={contribution_percentage}
            statesList={statesList}
            employer={espp?.company?.name ?? employer}
          />
        </TabPanel>
      </Grid>
    </Layout>
  )
}
