import {
  Button,
  CircularProgress,
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { graphqlClient } from 'utils/graphql'

import { MenuItem, OutlinedTextFieldProps, TextField } from '@mui/material'
import { Layout } from 'Components/Layout'
import { Typography } from 'Components/Typography'
import React from 'react'
import { formatToDollars } from 'utils/commons'

interface OptionType {
  label: string
  value: string | number
}

interface CustomSelectFieldProps extends OutlinedTextFieldProps {
  options: OptionType[]
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const CustomSelectField: React.FC<CustomSelectFieldProps> = ({
  options,
  onChange,
  ...otherProps
}) => {
  return (
    <TextField
      {...otherProps}
      select
      onChange={onChange}
      InputProps={{
        style: {
          display: 'flex',
          alignItems: 'center',
        },
      }}
      inputProps={{
        style: {
          height: '100%',
          display: 'flex',
          alignItems: 'center',
        },
      }}
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  )
}

export const Offers = () => {
  const [offers, setOffers] = useState([])
  const [offerTypes, setOfferTypes] = useState([])
  const [creditScoreRange, setCreditScoreRange] = useState([])
  const [providers, setProviders] = useState([])
  const [states, setStates] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [searchParams, setSearchParams] = useSearchParams()

  console.log({ states, providers, offers })

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    setLoading(true)
    setError(null)

    try {
      const [offerTypesRes, creditScoreRangeRes, providersRes, statesRes] = await Promise.all([
        graphqlClient.chain.query.offerTypes.execute({ __scalar: 1 }),
        graphqlClient.chain.query.creditScoreRange.execute({ __scalar: 1 }),
        graphqlClient.chain.query.providers.execute({ __scalar: 1 }),
        graphqlClient.chain.query.states.execute({ __scalar: 1 }),
      ])

      setOfferTypes(offerTypesRes)
      setCreditScoreRange(creditScoreRangeRes)
      setProviders(providersRes)
      setStates(statesRes)
    } catch (err) {
      setError(err)
    }

    setLoading(false)
  }

  const fetchOffers = async (filters = {}) => {
    setLoading(true)
    setError(null)

    try {
      const res = await graphqlClient.chain.query.offers(filters).execute({
        id: true,
        name: true,
        minAmount: true,
        maxAmount: true,
        minApr: true,
        maxApr: true,
        url: true,
        shortDescription: true,
        detailedDescription: true,
        disclaimer: true,
        provider: {
          id: true,
          name: true,
          logoUrl: true,
        },
        creditScoreRanges: {
          name: true,
        },
      })
      console.log({ res })
      setOffers(res)
    } catch (err) {
      setError(err)
    }

    setLoading(false)
  }

  useEffect(() => {
    const offerTypeValue = searchParams.get('offerType')
    const creditScoreRangeValue = searchParams.get('creditScoreRange')
    const stateValue = searchParams.get('states')
    const statesList = stateValue ? stateValue.split(',') : []
    const providerValue = searchParams.get('provider')

    fetchOffers({
      offerType: offerTypeValue,
      creditScoreRange: creditScoreRangeValue,
      states: statesList,
      provider: providerValue,
    })
  }, [searchParams])

  const handleFilterChange = (filterName, value) => {
    const params = new URLSearchParams(searchParams.toString())
    if (value) {
      params.set(filterName, value)
    } else {
      params.delete(filterName)
    }
    setSearchParams(params)
  }

  if (error) return <p>Error: {error.message}</p>

  return (
    <Layout>
      <Container sx={{ display: 'flex', flexDirection: 'column', gap: '32px', py: '32px' }}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant='h2' textAlign={'center'}>
              Ready to unlock the full potential of your ESPP?
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography textAlign={'center'}>
              Ready to unlock the full potential of your ESPP? Complete your funding journey by
              applying for a Personal Line of Credit in just a few easy steps. After approval, Benny
              will manage the funds to ensure you're making the most of your ESPP!
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <CustomSelectField
              name='offerType'
              variant='outlined'
              label='Offer Type'
              fullWidth
              value={searchParams.get('offerType') || ''}
              options={[
                { label: 'Any', value: '' },
                ...offerTypes.map((type) => ({
                  value: type.name,
                  label: type.name,
                })),
              ]}
              onChange={(event) => handleFilterChange('offerType', event.target.value)}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <CustomSelectField
              name='creditScoreRange'
              variant='outlined'
              label='Credit Score Range'
              fullWidth
              value={searchParams.get('creditScoreRange') || ''}
              options={[
                { label: 'Any', value: '' },
                ...creditScoreRange.map((type) => ({
                  value: type.name,
                  label: type.name,
                })),
              ]}
              onChange={(event) => handleFilterChange('creditScoreRange', event.target.value)}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <CustomSelectField
              name='states'
              variant='outlined'
              label='States'
              fullWidth
              value={searchParams.get('states') || ''}
              options={[
                { label: 'Any', value: '' },
                ...states.map((type) => ({
                  value: type.abbreviation,
                  label: type.fullName,
                })),
              ]}
              onChange={(event) => handleFilterChange('states', event.target.value)}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <CustomSelectField
              name='provider'
              variant='outlined'
              label='Provider'
              fullWidth
              value={searchParams.get('provider') || ''}
              options={[
                { label: 'Any', value: '' },
                ...providers.map((type) => ({
                  value: type.name,
                  label: type.name,
                })),
              ]}
              onChange={(event) => handleFilterChange('provider', event.target.value)}
            />
          </Grid>

          {/* <Grid item xs={12} sm={6}>
          <CustomTextField
            label='Min Amount'
            fullWidth
            value={searchParams.get('minAmount') || ''}
            onChange={(event) => handleFilterChange('minAmount', event.target.value)}
          />
        </Grid> */}

          {/* <Grid item xs={12} sm={6}>
          <CustomTextField
            label='Max Amount'
            fullWidth
            value={searchParams.get('maxAmount') || ''}
            onChange={(event) => handleFilterChange('maxAmount', event.target.value)}
          />
        </Grid> */}

          {/* <Grid item xs={12} sm={6}>
          <CustomTextField
            label='Min APR'
            fullWidth
            value={searchParams.get('minApr') || ''}
            onChange={(event) => handleFilterChange('minApr', event.target.value)}
          />
        </Grid> */}

          {/* <Grid item xs={12} sm={6}>
          <CustomTextField
            label='Max APR'
            fullWidth
            value={searchParams.get('maxApr') || ''}
            onChange={(event) => handleFilterChange('maxApr', event.target.value)}
          />
        </Grid> */}
        </Grid>

        {loading && (
          <Container
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: '50vh',
            }}
          >
            <CircularProgress />
          </Container>
        )}

        {offers.length ? (
          <Grid container spacing={2}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Offer</TableCell>
                    <TableCell>Provider</TableCell>
                    <TableCell>Loan Amount</TableCell>
                    <TableCell>APR Range</TableCell>
                    <TableCell>Credit Score Range</TableCell>
                    <TableCell>Apply Now</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {offers?.map((offer) => (
                    <TableRow key={offer.id}>
                      <TableCell>
                        <Typography>{offer?.name}</Typography>
                        <Typography variant='body2'>{offer?.shortDescription}</Typography>
                      </TableCell>
                      <TableCell>{offer?.provider?.name}</TableCell>
                      <TableCell>
                        {formatToDollars(offer?.minAmount)} - {formatToDollars(offer?.maxAmount)}
                      </TableCell>
                      <TableCell>
                        {offer?.minApr}% - {offer?.maxApr}%
                      </TableCell>
                      <TableCell>
                        {offer?.creditScoreRanges?.map((range) => range.name).join(', ')}
                      </TableCell>
                      <TableCell>
                        <Button variant='contained' color='primary' href={offer.url}>
                          Apply
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        ) : (
          <Container
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '32px',
              py: '32px',
            }}
          >
            <Typography variant='h5'>No offers found</Typography>
            <Typography variant='body1'>
              Try adjusting the filters to see available offers.
            </Typography>
          </Container>
        )}
      </Container>
    </Layout>
  )
}
