import * as mui from '@mui/material'
import 'animate.css'
import { useEventClick } from '../../../hooks/tracker'
import accentureLogo from '../../../media/images/homepage3/logos/accenture.svg'
import amdLogo from '../../../media/images/homepage3/logos/amd.svg'
import appleLogo from '../../../media/images/homepage3/logos/apple.svg'
import msftLogo from '../../../media/images/homepage3/logos/msft.svg'
import nvidiaLogo from '../../../media/images/homepage3/logos/nvidia.svg'
import personImage from '../../../media/images/homepage3/person.jpg'

import { TrustedByEmployees } from 'Components/TrustedByEmployees'
import { CtaAction, HeroConfig } from '../../../screens/home/config'
import { CustomButton } from '../../CustomButton'
import { ResponsiveImage } from '../../ResponsiveImage'
import { Typography } from '../../Typography'
import './styles.css'

const animateCard = mui.keyframes`
  0% {
    transform: rotate(0deg);
    left: 0%;
    bottom: 0px;
  }
  100% {
    transform: rotate(5deg);
    left: 70%;
    bottom: 20px;
  }
`

const animateMarginTop = mui.keyframes`
  0% {
    margin-top: 100px;
  }
  100% {
    margin-top: 10px;
  }
`

const logos = [
  { src: appleLogo, alt: 'Apple' },
  { src: accentureLogo, alt: 'Accenture' },
  { src: amdLogo, alt: 'AMD' },
  { src: msftLogo, alt: 'Microsoft' },
  { src: nvidiaLogo, alt: 'Nvidia' },
]

interface HeroSectionV3Props extends HeroConfig {
  // eslint-disable-next-line no-unused-vars
  onCtaClick: (action: CtaAction) => void
}

export function HeroSectionV3({
  headline,
  description,
  ctaLeft,
  ctaRight,
  ctaEligibility,
  onCtaClick,
}: HeroSectionV3Props) {
  const { createEvent } = useEventClick()
  const isDesktop = mui.useMediaQuery((theme: mui.Theme) => theme.breakpoints.up('md'))

  return (
    <mui.Container sx={{ maxWidth: '1072px', p: 0 }}>
      <mui.Grid
        container
        item
        spacing={{ xs: 0, md: 10 }}
        sx={{
          pt: { xs: 2, md: 12 },
          mb: { xs: 1, md: 8 },
          px: { xs: 3, md: 0 },
        }}
      >
        <mui.Grid container item xs={12} md={6} className='animate__animated animate__bounce'>
          <mui.Grid
            container
            item
            xs={12}
            sx={{ animation: `${animateMarginTop} 1s forwards`, alignItems: 'center' }}
          >
            <mui.Stack>
              <Typography variant='h1'>{headline}</Typography>

              <Typography mt={4} mb={{ xs: 3, md: 4 }}>
                {description}
              </Typography>

              <mui.Grid container justifyContent='center' gap={{ xs: 1, md: 2 }}>
                <CustomButton
                  buttonProps={{ fullWidth: !isDesktop }}
                  title={ctaLeft.text}
                  onClick={() => (window.location.href = 'https://app.heybenny.com/auth/sign-up')}
                  to={ctaLeft.action.type === 'scroll' ? ctaLeft.action.value : ''}
                  variant='primary'
                />

                <CustomButton
                  title={ctaRight.text}
                  onClick={() => {
                    onCtaClick(ctaRight.action)
                    createEvent({ name: 'HomeHeroSeeHowItWorks' })
                  }}
                  to={ctaRight.action.type === 'scroll' ? ctaRight.action.value : ''}
                  variant='secundary'
                />
              </mui.Grid>
            </mui.Stack>
          </mui.Grid>
        </mui.Grid>

        <mui.Grid
          item
          xs={12}
          md={6}
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            mt: { xs: 8, md: 0 },
            position: 'relative',
          }}
        >
          <mui.Box sx={{ position: 'relative', width: { xs: 'calc(100% - 74px)', md: '100%' } }}>
            <ResponsiveImage fallbackSrc={personImage} alt='' width='100%' />
          </mui.Box>

          <mui.Paper
            sx={{
              position: 'absolute',
              top: { xs: '70px', md: '220px' },
              left: { xs: 0, md: '20px' },
              padding: '10px 20px 20px 20px',
              borderRadius: '12px',
              backgroundColor: '#fff',
              boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.06)',
            }}
          >
            <Typography
              sx={{
                color: '#808080',
                fontSize: '18px',
                fontStyle: 'normal',
                fontWeight: '300',
                lineHeight: '24px',
              }}
            >
              Jan 15
            </Typography>
            <Typography
              sx={{
                color: '#000',
                fontSize: '18px',
                fontStyle: 'normal',
                fontWeight: '300',
                lineHeight: '24px',
                mb: '20px',
              }}
            >
              Deposit from <br /> Benny
            </Typography>
            <Typography
              component='span'
              sx={{
                color: (theme) => theme.palette.primary.light,
                fontSize: '48px',
                fontStyle: 'normal',
                fontWeight: '700',
                lineHeight: '36px',
              }}
            >
              +
            </Typography>
            <Typography
              component='span'
              sx={{
                fontSize: '48px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: '36px',
              }}
            >
              $513
            </Typography>
          </mui.Paper>
        </mui.Grid>
      </mui.Grid>

      <mui.Stack
        sx={{ backgroundColor: '#F6FBF9', justifyContent: 'center', alignItems: 'center' }}
        direction={{ xs: 'column', md: 'row' }}
        gap={{ xs: 0, md: 5 }}
        py={{ xs: 2, md: 0 }}
      >
        <mui.Typography color='#194339' py={{ xs: 0.5, md: 2 }}>
          Low and Fixed Interest Rate
        </mui.Typography>
        {isDesktop && (
          <mui.Box
            sx={{
              width: '4px',
              height: '58px',
              backgroundColor: 'white',
              transform: 'skewX(-15deg)',
            }}
          ></mui.Box>
        )}
        <mui.Typography color='#194339' py={{ xs: 0.5, md: 2 }}>
          No Hidden Fees
        </mui.Typography>
        {isDesktop && (
          <mui.Box
            sx={{
              width: '4px',
              height: '58px',
              backgroundColor: 'white',
              transform: 'skewX(-15deg)',
            }}
          ></mui.Box>
        )}
        <mui.Typography color='#194339' py={{ xs: 0.5, md: 2 }}>
          No Credit Checks
        </mui.Typography>
        {isDesktop && (
          <mui.Box
            sx={{
              width: '4px',
              height: '58px',
              backgroundColor: 'white',
              transform: 'skewX(-15deg)',
            }}
          ></mui.Box>
        )}
        <mui.Typography color='#194339' py={{ xs: 0.5, md: 2 }}>
          Apply in Less Than 3 Minutes
        </mui.Typography>
      </mui.Stack>

      <TrustedByEmployees />
    </mui.Container>
  )
}
