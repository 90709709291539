import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import EmailIcon from '@mui/icons-material/Email'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Slide,
} from '@mui/material'
import { TransitionProps } from '@mui/material/transitions'
import { CustomTextField } from 'Components/Fields'
import { getTextStyleVariant, Typography } from 'Components/Typography/Typography'
import { Field, Formik } from 'formik'
import { forwardRef, Fragment, useState } from 'react'
import { bennyApi } from 'utils/bennyApi'
import * as yup from 'yup'

const validationSchema = yup.object({
  email: yup.string().email('Invalid email').required('Required'),
})

type SchemaType = yup.InferType<typeof validationSchema>

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction='up' ref={ref} {...props} />
})

interface SendCopyProps {
  customOnSubmit: () => void
}

export function SendCopy({ customOnSubmit }: SendCopyProps) {
  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const onSubmit = async (values: SchemaType, { setSubmitting }) => {
    setSubmitting(true)
    try {
      await bennyApi.post('/espp/customer_espp_request/', {
        email: values.email,
        url: window.location.href,
      })
      setSubmitting(false)
      handleClose()
      customOnSubmit()
    } catch (error) {
      console.error('Error sending email and URL', error)
      setSubmitting(false)
    }
  }

  return (
    <Fragment>
      <Button
        variant='text'
        size='large'
        startIcon={<EmailIcon />}
        sx={{ ...getTextStyleVariant('body2'), color:'#9747FF'}}
        onClick={handleClickOpen}
      >
        Email me a copy
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby='alert-dialog-slide-description'
        sx={{
          '& .MuiDialog-paper': {
            padding: { xs: '', md: '48px' },
            bgcolor: '#FFF',
            m: 0,
          },
        }}  
      >
        <Formik<SchemaType>
          initialValues={{ email: '' }}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          isInitialValid={false}
        >
          {({
            values,
            handleChange,
            handleBlur,
            touched,
            errors,
            isValid,
            isSubmitting,
            handleSubmit,
          }) => (
            <>
              <DialogTitle sx={{ ...getTextStyleVariant('h2') }}>
                {'Receive copy of your ESPP Calculations'}
              </DialogTitle>
              <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '32px' }}>
                <Grid container gap={'8px'} pt='10px'>
                  <DialogContentText
                    sx={{ ...getTextStyleVariant(''), color: '#1D1D1F' }}
                    id='alert-dialog-slide-description'
                  >
                    Enter your email address to get a copy of your exclusive report. Don't let this opportunity slip away!
                  </DialogContentText>

                  <Grid container display={'flex'} justifyContent={'space-between'}>
                    <Grid container item xs={12} md={10}>
                      <Field
                        component={CustomTextField}
                        fullWidth
                        id='email'
                        name='email'
                        placeholder={'Please enter your email address'}
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.email && Boolean(errors.email)}
                        helperText={touched.email && errors.email}
                      />
                    </Grid>

                    <Grid container item xs={12} md={2} justifyContent={'flex-end'}>
                      <Button
                        size='large'
                        disabled={(!isValid || isSubmitting) && !!touched.email}
                        variant={'contained'}
                        onClick={() => handleSubmit()}
                      >
                        {!isSubmitting ? <ArrowRightAltIcon /> : <CircularProgress size={'24px'} />}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container justifyContent={'center'} alignItems={'center'} mt={'12px'}>
                  <Typography
                    textAlign={'center'}
                    sx={{ textWrap: 'pretty', color: '#1D1D1F', opacity: '0.6' }}
                  >
                    Your privacy is important to us.
                    <br />
                    See our{' '}
                    <a
                      href='https://heybenny.com/privacy'
                      target='_blank'
                      style={{ color: '#9747FF', cursor: 'pointer', textDecoration: 'none' }}
                    >
                      Privacy Policy.
                    </a>
                  </Typography>
                </Grid>
              </DialogContent>
            </>
          )}
        </Formik>
      </Dialog>
    </Fragment>
  )
}
