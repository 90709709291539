import { createTheme } from '@mui/material'


declare module '@mui/material/styles' {
  interface PaletteColor {
    lighter?: string
  }
  interface SimplePaletteColorOptions {
    lighter?: string
  }
}


export const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          // 12px of margin + text height. Font size 0.875rem results in 24.5px height. 28 comes from 24.5 / 0.875
          borderRadius: 'calc(12px + 0.875rem * 28)',
          textTransform: 'none', // Disable uppercase
        },
        contained: {
          backgroundColor: '#9747FF',
          '&:hover': {
            backgroundColor: '#4B288E',
          },
        },
        outlined: {
          borderColor: '#9747FF',
          color: '#9747FF',
          '&:hover': {
            backgroundColor: '#9747FF',
            color: 'white',
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: '#9747FF',
          '&:hover': {
            color: '#4B288E',
          },
          textDecoration: 'none',
          fontFamily: '"Helvetica Neue", Arial, sans-serif',
          fontSize: '22px',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        InputProps: {
          size: 'small',
        },
      },
    },
  },
  palette: {
    background: {
      paper: '#F6FBF9',
    },
    primary: {
      main: '#194339',
      light: '#60CA95',
      lighter: '#F6FBF9',
    },
    secondary: {
      main: '#4B288E',
      light: '#9747FF',
      lighter: '#F2EFF7',
    },
  },
  typography: {
    button: {
      fontWeight: 'bold',
    },
    fontFamily: [
      'Basis Grotesque Pro',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
})
