import axios from 'axios' // Asegúrate de tener axios importado
import { useEffect, useState } from 'react'
import { Application } from '../types'
import { bennyApi } from '../utils/bennyApi'

export const useGetApplicationById = (applicationId: string) => {
  const [application, setApplication] = useState<Application | undefined>()
  const [mostRecentVerification, setMostRecentVerification] = useState<{ id: string }>()

  useEffect(() => {
    const cancelTokenSource = axios.CancelToken.source()

    const fetchApplication = async () => {
      try {
        const { data } = await bennyApi.get<{
          application: Application
          most_recent_verification: { id: string }
        }>(`/espp/application/${applicationId}/`, {
          cancelToken: cancelTokenSource.token,
        })
        console.log(data.most_recent_verification)
        setApplication(data.application)
        if (data.most_recent_verification) {
          setMostRecentVerification(data.most_recent_verification)
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('fetchApplication fue cancelado')
        } else {
          console.log('fetchApplication:', error)
        }
      }
    }

    fetchApplication()

    return () => {
      cancelTokenSource.cancel()
    }
  }, [applicationId])

  return { application, mostRecentVerification }
}
