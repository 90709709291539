/* eslint-disable react/no-unescaped-entities */
import { Grid } from '@mui/material'
import axios from 'axios'
import React from 'react'
import { useParams, useSearchParams } from 'react-router-dom'

import { encrypt_api_secret } from '../../../utils/commons'
import './styles.css'

interface ReferralBannerProps {
  employer?: string
  income?: string
}

export default function ReferralBanner({ employer, income }: ReferralBannerProps) {
  const [searchParams] = useSearchParams()
  const [referralCode, setReferralCode] = React.useState('')
  const [codeFound, setCodeFound] = React.useState('')
  const [codeBenefit, setCodeBenefit] = React.useState(null)
  const [codePercentageBenefit, setCodePercentageBenefit] = React.useState(null)
  const [code, setCode] = React.useState('')
  const { prereferral } = useParams()

  const secret_key = encrypt_api_secret(process.env.REACT_APP_CORE_API_SECRET)

  const checkReferralCode = (_referralCode: string) => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/espp/referralCode/${_referralCode}/`, {
        headers: {
          'Content-Type': 'application/json',
          'benny-api-key': process.env.REACT_APP_CORE_API_KEY,
          'benny-api-secret': secret_key,
        },
      })
      .then((response) => {
        console.log('DATA RESPONSE FOR REFERRAL', response.data.referralCode)
        const data = response.data.referralCode

        if (data?.active && (data?.receiver_percentage_benefit || data.receiver_benefit)) {
          setCode(referralCode)
          setCodeFound('Valid')
          if (!!data?.receiver_percentage_benefit) {
            setCodePercentageBenefit(data.receiver_percentage_benefit)
          } else {
            setCodeBenefit(data.receiver_benefit)
          }
        } else {
          setCodeFound('Invalid')
        }
      })
      .catch((error) => {
        console.log(error.response.data.referralCode)
        if (error.response.data.referralCode === 'Not found') {
          setCodeFound('Not found')
        }
      })
  }

  if (prereferral) {
    console.log('Referral', prereferral)
    if (prereferral.length > 1) {
      checkReferralCode(prereferral)
    }
  }

  if (searchParams.get('referral') !== null) {
    if (searchParams.get('referral') !== '') {
      if (referralCode !== null) {
        if (referralCode.length === 0) {
          const preref = searchParams.get('referral')
          setReferralCode(preref)
          checkReferralCode(preref)
        }
      }
    }
  }

  React.useEffect(() => {}, [codeFound])

  return (
    <Grid
      className='referral-banner-container'
      sx={{ display: codeFound === 'Valid' ? 'Block' : 'none' }}
    >
      {!!codePercentageBenefit ? (
        <p>
          You have unlocked a <strong>{codePercentageBenefit}%</strong> discount once you've
          partnered with Benny.
        </p>
      ) : (
        <p>
          You have unlocked a <strong>${codeBenefit}</strong> gift card once you've partnered with
          Benny.
        </p>
      )}
    </Grid>
  )
}

ReferralBanner.defaultProps = {
  employer: '',
  income: '',
}

/*  */
