import { Grid } from '@mui/material'
import { ReactNode } from 'react'
import Footer from '../common/Footer'
import { WrapperHeader, WrapperHeaderProps } from '../header'
//import Header from '../common/websiteheader'
interface LayoutProps extends WrapperHeaderProps {
  children: ReactNode
}

export const Layout = ({ children, ...props }: LayoutProps) => {
  return (
    <Grid container direction='column' sx={{ minHeight: '100vh' }}>
      <WrapperHeader {...props} />
      <Grid item xs>
        {children}
      </Grid>
      <Footer />
    </Grid>
  )
}
