import * as mui from '@mui/material'

import { Layout } from '../../Components/Layout'
import { CustomText } from '../../Components/Text'
import { Typography } from '../../Components/Typography'

import correlation from '../../media/images/aboutus/correlation.png'
import ffvc from '../../media/images/aboutus/ffvc.png'
import match from '../../media/images/aboutus/match.png'
import tech from '../../media/images/aboutus/tech.png'
import andy from '../../media/images/andy.png'

const list: { title: string; subtitle: string }[] = [
  { title: '2021', subtitle: 'Year we launched' },
  { title: '$1M+', subtitle: 'Earnings Unlocked in 2024' },
  { title: '$3M+', subtitle: 'Total Funding' },
]

export const AboutUs = () => {
  return (
    <Layout extraQueryParams={{ s: 'nav_get_started' }}>
      <mui.Grid container>
        <mui.Grid
          container
          py={{ xs: 0, md: '64px' }}
          px={{ xs: '20px', md: 0 }}
          maxWidth={'1200px'}
          mx={'auto'}
        >
          <mui.Box py={{ xs: '20px', md: '32px' }}>
            <Typography variant='h1'>
              At Benny, we help employees use their Employee Stock Purchase Plans (ESPPs) to improve
              their financial lives.
            </Typography>
          </mui.Box>

          <mui.Box
            display={'flex'}
            flexDirection={'column'}
            gap={'16px'}
            py={{ xs: '20px', md: '32px' }}
          >
            <CustomText customVariant='headding' sx={{ textAlign: 'left', color: '#194339' }}>
              What we do
            </CustomText>

            <CustomText
              customVariant='body'
              sx={{
                textAlign: 'left',
                fontFamily: 'Helvetica Neue',
                fontSize: { xs: '18px', md: '24px' },
                lineHeight: '125%',
                fontStyle: 'normal',
              }}
            >
              Benny is on a mission to empower employees of public companies to unlock the value
              in their employee stock purchase plans (ESPP).
              We provide financial solutions that enable employees to maximize their ESPP benefits
              without affecting their take-home pay.
            </CustomText>
          </mui.Box>

          <mui.Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'space-between'}
            py={{ xs: '20px', md: '32px' }}
            width={'100%'}
            gap={'16px'}
          >
            {list.map((item, idx) => (
              <mui.Box key={idx} display={'flex'} flexDirection={'column'} gap={'8px'}>
                <mui.Typography
                  fontSize={{ xs: '24px', md: '48px' }}
                  color={'#194339'}
                  fontWeight={'700'}
                  lineHeight={'normal'}
                  fontStyle={'normal'}
                >
                  {item.title}
                </mui.Typography>
                <mui.Typography
                  fontSize={{ xs: '18px', md: '24px' }}
                  fontWeight={'400'}
                  lineHeight={'normal'}
                  fontStyle={'normal'}
                >
                  {item.subtitle}
                </mui.Typography>
              </mui.Box>
            ))}
          </mui.Box>
        </mui.Grid>

        <mui.Grid
          container
          bgcolor={'#F6FBF9'}
          py={{ xs: '20px', md: '80px' }}
          px={{ xs: '20px', md: 0 }}
        >
          <mui.Grid container maxWidth={'1200px'} mx={'auto'}>
            <mui.Grid item xs={12} md={5} pr={{ xs: 0, md: '64px' }}>
              <img
                src={andy}
                loading='lazy'
                style={{ maxWidth: '100%', height: 'auto', display: 'block' }}
              />

              <mui.Box my={{ xs: 0, md: '58px' }}>
                <CustomText customVariant='headding' sx={{ textAlign: 'left' }}>
                  Andy Kalmon
                </CustomText>
                <CustomText
                  customVariant='headding'
                  sx={{ textAlign: 'left', fontFamily: 'Garnett-Regular' }}
                >
                  CEO
                </CustomText>
              </mui.Box>
            </mui.Grid>

            <mui.Grid
              container
              item
              xs={12}
              md={7}
              gap={'32px'}
              display={'flex'}
              flexDirection={'column'}
              mt={{ xs: '64px', md: 0 }}
            >
              <CustomText customVariant={'headding'} sx={{ color: '#194339', textAlign: 'left' }}>
                Our Story
              </CustomText>

              <mui.Box display={'flex'} flexDirection={'column'} gap={'8px'}>
                <CustomText
                  customVariant='body'
                  sx={{
                    textAlign: 'left',
                    fontFamily: 'Helvetica Neue',
                    fontSize: { xs: '18px', md: '24px' },
                    lineHeight: 'normal',
                    fontStyle: 'normal',
                    fontWeight: 400,
                  }}
                >
                  Hi, I’m Andy, Co-Founder and CEO of Benny.
                  <mui.Box style={{ height: '16px' }} />
                  Benny was born out of personal experience feeling the pain of not being able to
                  take advantage of an Employee Stock Purchase Plan (ESPP) provided by the public
                  company I worked for before starting Benny.
                  <mui.Box style={{ height: '16px' }} />
                  When I started at that company, the ESPP benefit was new to me. I was unsure how
                  it worked, why it mattered, or how to make the most of it.
                  <mui.Box style={{ height: '16px' }} />
                  I’m quite the nerd when it comes down to it, so I pushed up my glasses, and got to
                  work crunching the numbers. After I modeled out different scenarios, the
                  opportunity was clear - the discount on company shares makes ESPP basically free
                  money!
                  <mui.Box style={{ height: '16px' }} />
                  Well, not totally free.
                  <mui.Box style={{ height: '16px' }} />I realized that to maximize the ESPP benefit did
                  have a cost. I’d have to forgo a part of my paycheck each pay period. For me, this
                  wasn’t a deduction I could stomach. Life’s demands - rent, student loans, daycare
                  costs - kept this “free money” out of reach.
                  <mui.Box style={{ height: '16px' }} />I realized I wasn’t alone in this challenge of
                  wanting to maximize this benefit but being unable to due to needing cash for
                  day-to-day life and started Benny to help support others in participating in their
                  ESPPs. I even funded the first few ESPPs straight from my own bank account which
                  laid the foundation for Benny today.
                  <mui.Box style={{ height: '16px' }} />
                  We’re a venture backed company, made with love in Madison, WI, on a mission to
                  make ESPP benefits accessible to all.
                </CustomText>
              </mui.Box>
            </mui.Grid>
          </mui.Grid>
        </mui.Grid>

        <mui.Grid container py={{ xs: '40px', md: '80px' }} px={{ xs: '20px', md: 0 }}>
          <mui.Grid
            container
            maxWidth={'1200px'}
            mx={'auto'}
            justifyContent={'center'}
            alignItems={'center'}
            display={'flex'}
            flexDirection={'column'}
          >
            <CustomText customVariant='headding' sx={{ color: '#194339' }}>
              Backed by
            </CustomText>

            <mui.Box
              display={'flex'}
              flexDirection={{ xs: 'column', md: 'row' }}
              justifyContent={'space-between'}
              alignItems={'center'}
              width={'100%'}
              flexWrap={'nowrap'}
            >
              <img src={match} loading='lazy' />
              <img src={ffvc} loading='lazy' />
              <img src={correlation} loading='lazy' />
              <img src={tech} loading='lazy' />
            </mui.Box>
          </mui.Grid>
        </mui.Grid>
      </mui.Grid>
    </Layout>
  )
}
