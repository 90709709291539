export const getChromeVersion = () => {
  const userAgent = navigator.userAgent
  if (/chrome/i.test(userAgent) && !/edg|opr|brave/i.test(userAgent)) {
    const match = userAgent.match(/chrome\/([\d.]+)/i)
    return match ? match[1] : null
  }
  return null
}

export const needsChromeUpdate = () => {
  const version = getChromeVersion()
  return version ? parseInt(version.split('.')[0], 10) < 123 : false
}
