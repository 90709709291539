import {
  Autocomplete,
  MenuItem,
  OutlinedTextFieldProps,
  Slider,
  SliderProps,
  styled,
  TextField,
} from '@mui/material'
import { useField, useFormikContext } from 'formik'

export const CustomAutocomplete = styled(Autocomplete)(() => ({
  '& .MuiOutlinedInput-root': { background: 'white' },
  '& .MuiInputBase-input': {
    backgroundColor: '#ffffff',
    height: '0.5em',
  },
}))

export const CustomTextField = styled(TextField)(() => ({
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: '#4B288E',
    },
    '&.Mui-error fieldset': {
      borderColor: 'red',
    },
  },
  '& .Mui-focused .MuiInputBase-input::placeholder': {
    opacity: 0,
  },
  '& .MuiInputBase-input': {
    backgroundColor: '#ffffff',
    height: '1.5em',
  },
}))

export const CustomTextAreaField = styled(({ ...props }) => (
  <TextField multiline variant='outlined' rows={8} maxRows={10} {...props} />
))(() => ({
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: '#4B288E',
    },
    '&.Mui-error fieldset': {
      borderColor: 'red',
    },
  },
  '& .Mui-focused .MuiInputBase-input::placeholder': {
    opacity: 0,
  },
  '& .MuiInputBase-input': {
    backgroundColor: '#ffffff',
    height: '0.5em',
  },
}))

const BennySlider = styled(Slider)({
  color: '#1BCD90',
  height: 8,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-rail': {
    backgroundColor: '#88c7ff66',
  },
  '& .MuiSlider-thumb': {
    height: 26,
    width: 26,
    backgroundColor: '#fff',
    boxShadow: '0px 2px 3px rgba(83, 64, 100, 0.2), 0px 1px 2px rgba(83, 64, 100, 0.55)',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: '0px 2px 3px rgba(83, 64, 100, 0.2), 0px 1px 2px rgba(83, 64, 100, 0.85)',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.8,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: '#52af77',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
})

interface CustomSliderFieldProps extends SliderProps {
  name: string
  customHandleChange?: (value: number | number[]) => void
}

export const CustomSliderField: React.FC<CustomSliderFieldProps> = ({
  name,
  customHandleChange,
  ...otherProps
}) => {
  const { setFieldValue } = useFormikContext()
  const [field, meta] = useField(name)

  const handleChange = (event: Event, newValue: number | number[]) => {
    setFieldValue(name, newValue)
    customHandleChange?.(newValue)
  }

  return (
    <>
      <BennySlider
        {...field}
        {...otherProps}
        value={typeof field.value === 'number' ? field.value : 0}
        onChange={handleChange}
        // Omitido el prop de error de MUI Slider ya que no lo soporta directamente, gestionar visualización de errores de forma personalizada si es necesario
      />
      {meta.touched && meta.error ? <div style={{ color: 'red' }}>{meta.error}</div> : null}
    </>
  )
}

interface OptionType {
  label: string
  value: string | number
}

interface CustomSelectFieldProps extends OutlinedTextFieldProps {
  name: string
  options: OptionType[]
  customHandleChange?: (value: string) => void
}

export const CustomSelectField = ({
  name,
  options,
  customHandleChange,
  ...otherProps
}: CustomSelectFieldProps) => {
  const { setFieldValue } = useFormikContext()
  const [field, meta] = useField(name)

  return (
    <CustomTextField
      {...field}
      {...otherProps}
      select
      value={field.value}
      onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
        setFieldValue(field.name, e.target.value as string)
        customHandleChange?.(e.target.value as string)
      }}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </CustomTextField>
  )
}


export function DecimalLimitedTextField({ field, form, decimalPlaces=2, ...props }) {
  return <TextField
    {...field}
    {...props}
    onChange={e => {
      const { value } = e.target;
      const regex = decimalPlaces
        ? new RegExp(`^\\d*\\.?\\d{0,${decimalPlaces}}$`)
        : /^\d*$/

      if (value === '' || regex.test(value)) {
        form.setFieldValue(field.name, value);
      }
    }}
  />
}
