import { Crypt } from 'hybrid-crypto-js'

var publicKey = `-----BEGIN PUBLIC KEY-----
MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCfm2uMTvb+gqXRFxWHnfCKcHfH
v7aMN6oiEqTJj0BixtTYBXH89N+xuYgoIBnfMXPXPIg/UNWEOZtAETsOVvya+YBo
ZZTquYJ2I0PaxtpUKkpCiEQ/bTCQIDAeUwHr0l4vUn/fmslD0rZ3+jo4Dsl8nX0O
pwZQ2grQaXLb347RQwIDAQAB
-----END PUBLIC KEY-----`

export const encrypt_api_secret = (secretKey) => {
  var crypt = new Crypt()

  // Perform our encryption based on our public key - only private key can read it!
  var encrypted = crypt.encrypt(publicKey, secretKey)

  // Return encrypted string
  return encrypted
}

export const secret_key = encrypt_api_secret(process.env.REACT_APP_CORE_API_SECRET)
