import { createSelectorHooks } from 'auto-zustand-selectors-hook'
import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'

interface ClientState {
  id: string
  email: string
  name: string
  email_verified: boolean
  customer_number: string
}

interface State {
  client: ClientState | null
  setClient: (newUser: ClientState | null) => void
  clearClient: () => void
}

export const useClientStore = create<State>()(
  devtools(
    persist(
      (set) => ({
        client: null,
        setClient: (newUser) => set({ client: newUser }),
        clearClient: () => set({ client: null }),
      }),
      {
        name: 'client-store',
      },
    ),
  ),
)

export const useClientSelector = createSelectorHooks(useClientStore)
