import * as mui from '@mui/material'

import { Typography } from 'Components/Typography'
import { formatToDollars } from 'utils/commons'


export function Gains( props: {
  afterPriceDiscount: number
  bennyFundAmount: number
  companyName: string
  currentPeriod: { start_date: string; end_date: string}
  endDateFormatted: string
  esppGain: number
  estimatedPrice: number
  formatDate: (date: Date) => string
  lookback: Boolean
  marketValue: number
  sharePrice: number
  sharesPurchased: number
  startDateFormatted: string
}) {
  return (<>
    <mui.Grid
      container
      display={'flex'}
      justifyContent={'space-between'}
      borderBottom={'1px solid #E9E9E9'}
    >
      <mui.Box
        display={'flex'}
        flex={1}
        borderBottom={'2px solid #194339'}
        justifyContent={'center'}
        padding={'10px'}
      >
        <Typography
          variant={'body1'}
          sx={{ width: '100%', textAlign: 'center' }}
          inheritColor
        >
          {props.startDateFormatted} - {props.endDateFormatted}
        </Typography>
      </mui.Box>
    </mui.Grid>

    <mui.Grid
      container
      py={'32px'}
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <mui.Box display={'flex'} gap={'20px'}>
        <mui.Box display={'flex'} flexDirection={'column'} gap={'8px'}>
          <mui.Box sx={{
            backgroundColor: '#60CA95',
            borderRadius: '4px',
            height: Math.round(props.esppGain / (props.esppGain + props.bennyFundAmount) * 250),
            transition: 'height 0.5s',
            width: '100px',
          }} />
          <mui.Box sx={{
            backgroundColor: '#194339',
            borderRadius: '4px',
            height: Math.round(props.bennyFundAmount / (props.esppGain + props.bennyFundAmount) * 250),
            transition: 'height 0.5s',
            width: '100px',
          }} />
        </mui.Box>

        <mui.Box
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'space-between'}
          pb={'64px'}
          pt={'10px'}
        >
          <mui.Box display={'flex'} alignItems={'center'} gap={'8px'}>
            <mui.Divider
              sx={{
                width: '46px',
                height: '1px',
                bgcolor: '#194339',
                alignSelf: 'flex-start',
                mt: '10px',
              }}
              orientation='horizontal'
            />
            <Typography
              variant='body1'
              sx={{ alignSelf: 'flex-start', color: '#194339' }}
            >
              Your ESPP gain
              <br />
              {formatToDollars(props.esppGain)}
            </Typography>
          </mui.Box>

          <mui.Box display={'flex'} alignItems={'center'} gap={'8px'}>
            <mui.Divider
              sx={{
                width: '46px',
                height: '1px',
                bgcolor: '#194339',
                alignSelf: 'flex-start',
                mt: '10px',
              }}
              orientation='horizontal'
            />
            <Typography
              variant='body1'
              sx={{ alignSelf: 'flex-start', color: '#194339' }}
            >
              Your Contribution
              <br />
              {formatToDollars(props.bennyFundAmount)}
            </Typography>
          </mui.Box>
        </mui.Box>
      </mui.Box>
    </mui.Grid>

    <mui.Paper
      sx={{
        backgroundColor: 'transparent',
        borderRadius: '12px',
        border: '1px solid #60CA95',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        mb: 4,
        px: 3,
        py: 4,
        width: '100%',
      }}
    >
      <Typography sx={{color: 'black', pb: 4}}>
        Benny <b>funds your contributions</b>, allowing you to maximize your ESPP without reducing your take-home pay.
      </Typography>
      <mui.Link href={`/apply?step=about_your_espp&employer=${props.companyName}&s=calculator_page_chart`}>
        Start your application today!
      </mui.Link>
    </mui.Paper>

    <mui.Divider
      sx={{
        height: '0.5px',
        bgcolor: '#F6FBF9',
        width: '100%',
      }}
    />

    <mui.Grid container display={'flex'} flexDirection={'column'} py={'32px'}>
      <Typography variant='h3' inheritColor>
        Summary
      </Typography>
      <Typography variant='body1' fontStyle={'italic'} mb={'24px'} inheritColor>
        {props.sharesPurchased} {props.companyName} shares purchased at{' '}
        {formatToDollars(props.afterPriceDiscount, 2)}.
      </Typography>

      <mui.Box
        display={'flex'}
        flexDirection={'row'}
        maxWidth={{ xs: '100%', md: '75%' }}
        justifyContent={'space-between'}
      >
        <mui.Box display={'flex'} flexDirection={'column'} gap={'10px'}>
          <Typography variant='body1' inheritColor>
            Invested
          </Typography>
          <Typography variant='body1' inheritColor>
            {formatToDollars(props.bennyFundAmount)}
          </Typography>
        </mui.Box>

        <mui.Box display={'flex'} flexDirection={'column'} gap={'10px'}>
          <Typography variant='body1' inheritColor>
            Market Value
          </Typography>
          <Typography variant='body1' inheritColor>
            {formatToDollars(props.marketValue)}
          </Typography>
        </mui.Box>

        <mui.Box display={'flex'} flexDirection={'column'} gap={'10px'}>
          <Typography variant='body1' inheritColor>
            ESPP Gain
          </Typography>
          <Typography variant='body1' inheritColor>
            {formatToDollars(props.esppGain)}
          </Typography>
        </mui.Box>
      </mui.Box>
    </mui.Grid>

    <mui.Divider
      sx={{
        height: '0.5px',
        bgcolor: '#F6FBF9',
        width: '100%',
      }}
    />

    <mui.Grid
      container
      py={'32px'}
      display={'flex'}
      flexDirection={'column'}
      maxWidth={{ xs: '100%', md: '75%' }}
    >
      <Typography variant='h3' mb={'4px'} inheritColor>
        Share Prices *
      </Typography>
      <mui.Box display={'flex'} justifyContent={'space-between'}>
        <Typography>
          {props.formatDate(new Date(props.currentPeriod?.start_date))}
          <br />
          {props.lookback
            ? formatToDollars(props.sharePrice, 2)
            : formatToDollars(props.estimatedPrice, 2)}
        </Typography>

        <Typography>
          {props.formatDate(new Date(props.currentPeriod.end_date))}
          <br />
          {formatToDollars(props.estimatedPrice, 2)}
        </Typography>
      </mui.Box>
    </mui.Grid>

    <mui.Grid
      container
      py={'32px'}
      display={'flex'}
      flexDirection={'column'}
      maxWidth={{ xs: '100%', md: '75%' }}
    >
      <Typography variant='h3' mb={'4px'} inheritColor>
        Purchase price
      </Typography>
      <mui.Box display={'flex'} justifyContent={'space-between'}>
        <Typography inheritColor>
          Before Discount
          <br />
          {props.lookback
            ? props.sharePrice < props.estimatedPrice
              ? formatToDollars(props.sharePrice, 2)
              : formatToDollars(props.estimatedPrice, 2)
            : formatToDollars(props.estimatedPrice, 2)}
        </Typography>

        <Typography inheritColor>
          After Discount
          <br />
          {formatToDollars(props.afterPriceDiscount, 2)}
        </Typography>
      </mui.Box>
    </mui.Grid>

    <mui.Divider
      sx={{
        height: '0.5px',
        bgcolor: '#F6FBF9',
        width: '100%',
      }}
    />

    <mui.Grid pt={'32px'}>
      <Typography inheritColor fontStyle={'italic'} style={{ opacity: 0.6 }}>
        * First and last trading day of the participation period.
      </Typography>
    </mui.Grid>
  </>)
}
