import { useParams, useSearchParams } from 'react-router-dom'

export const useCustomParams = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const prereferral = useParams().prereferral ?? searchParams.get('referral') ?? ''
  const employer = searchParams.get('employer') ?? ''
  const income = searchParams.get('income') ?? ''
  const contribution_percentage = Number(searchParams.get('contribution_percentage')) ?? 0

  return {
    referral: prereferral,
    employer,
    income,
    contribution_percentage,
    searchParams,
    setSearchParams,
  }
}
