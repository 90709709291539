import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import CloseIcon from '@mui/icons-material/Close'
import { Box, Button, CircularProgress, Dialog, DialogTitle, Grid, IconButton } from '@mui/material'
import { Field, Formik } from 'formik'
import { useState } from 'react'
import * as yup from 'yup'
import { CustomAutocomplete, CustomTextField } from '../../../../Components/Fields'
import { GradientTypography } from '../../../../Components/gradientTypography'
import YoutubeEmbed from '../../../../Components/landingPage/videoEmbed'
import { Typography } from '../../../../Components/Typography'

const schema = yup.object({
  employer: yup.string().required('Employer is required'),
})

type SchemaType = yup.InferType<typeof schema>

interface DiscoverStepProps {
  employer: string
  onHandled: (employer: string) => void
  companies: string[]
}

export const DiscoverStep = ({ employer, onHandled, companies = [] }: DiscoverStepProps) => {
  const [open, setOpen] = useState<boolean>(false)

  const onSubmit = (values: SchemaType) => {
    onHandled(values.employer)
  }

  function handleClose() {
    setOpen(false)
  }

  return (
    <Box>
      <Box borderBottom={'1px solid #E9E9E9'} pb={{ xs: '24px', md: '48px' }}>
        <GradientTypography>Discover Your ESPP’s Full Potential</GradientTypography>
        <Typography pt={'16px'} inheritColor mb={{ xs: '32px', md: '64px' }}>
          <strong>Get started by answering three questions</strong> to see your Employee Stock
          Purchase Plan's (ESPP's) key details and how much you can earn.
        </Typography>

        <Grid
          container
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Formik<SchemaType>
            validationSchema={schema}
            onSubmit={onSubmit}
            initialValues={{ employer }}
            validateOnMount
            isInitialValid
          >
            {({
              values,
              handleChange,
              handleBlur,
              touched,
              errors,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              setFieldTouched,
            }) => {
              return (
                <Box display={'flex'}>
                  <Box
                    display={'flex'}
                    gap={'20px'}
                    justifyContent={'center'}
                    alignItems={'flex-end'}
                    flexDirection={{ xs: 'column', md: 'row' }}
                  >
                    <Box display={'flex'} flexDirection={'column'} gap={'20px'}>
                      <Typography inheritColor variant='h3'>
                        Who is your current employer?
                      </Typography>

                      <Field
                        fullWidth
                        name='employer'
                        component={CustomAutocomplete}
                        options={companies}
                        getOptionLabel={(option: string) => option}
                        onChange={(_, value, reason) => {
                          if (reason === 'selectOption') setFieldValue('employer', value)
                        }}
                        onInputChange={(_, value, reason) => {
                          if (reason === 'input') setFieldValue('employer', value)
                        }}
                        onBlur={() => setFieldTouched('employer', true)}
                        disableClearable
                        value={values.employer ?? ''}
                        renderInput={(params) => (
                          <CustomTextField
                            {...params}
                            error={touched.employer && Boolean(errors.employer)}
                            //helperText={touched.employer && errors.employer}
                            placeholder='Employer Name'
                          />
                        )}
                      />
                    </Box>

                    <Box display={'flex'}>
                      <Button
                        variant='contained'
                        onClick={() => handleSubmit()}
                        disabled={!!isSubmitting}
                        sx={{ padding: '10px 32px' }}
                      >
                        {!!isSubmitting ? (
                          <CircularProgress size={16} sx={{ color: 'white' }} />
                        ) : (
                          <ArrowForwardIcon />
                        )}
                      </Button>
                    </Box>
                  </Box>
                </Box>
              )
            }}
          </Formik>
        </Grid>
      </Box>

      <Box display={'flex'} justifyContent={'center'} alignItems={'center'} pt={'10px'}>
        <Dialog open={open} onClose={handleClose} maxWidth='sm'>
          <DialogTitle mb={2}>
            <IconButton sx={{ position: 'absolute', right: 8, top: 8 }} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <YoutubeEmbed embedId='tewdnwdwElQ' />
        </Dialog>

        <Typography onClick={() => setOpen(true)} sx={{ color: '#4B288E', cursor: 'pointer' }}>
          What is an ESPP?
        </Typography>
      </Box>
    </Box>
  )
}
