interface ImageProps {
  src1x?: string
  src2x?: string
  src3x?: string
  src4x?: string
  src5x?: string
  fallbackSrc: string
  alt: string
  width?: number | string
  height?: number
  className?: string
}

export function ResponsiveImage({
  src1x,
  src2x,
  src3x,
  src4x,
  src5x,
  fallbackSrc,
  alt,
  width,
  height,
  className,
}: ImageProps) {
  const checkWebPFormat = (src: string | undefined) => {
    if (src && !src.endsWith('.webp')) {
      throw new Error(`Image source ${src} is not in .webp format`)
    }
  }

  checkWebPFormat(src1x)
  checkWebPFormat(src2x)
  checkWebPFormat(src3x)
  checkWebPFormat(src4x)
  checkWebPFormat(src5x)

  const srcSet = [
    src1x && `${src1x} 1x`,
    src2x && `${src2x} 2x`,
    src3x && `${src3x} 3x`,
    src4x && `${src4x} 4x`,
    src5x && `${src5x} 5x`,
  ]
    .filter(Boolean)
    .join(', ')

  return (
    <picture>
      {srcSet && <source className={className} srcSet={srcSet} type='image/webp' />}
      <img className={className} src={fallbackSrc} alt={alt} width={width} height={height} />
    </picture>
  )
}

ResponsiveImage.defaultProps = {
  src1x: undefined,
  src2x: undefined,
  src3x: undefined,
  src4x: undefined,
  src5x: undefined,
  width: undefined,
  height: undefined,
  className: undefined,
}
