import { AppBar, Grid, Link, Toolbar } from '@mui/material'
import React from 'react'
import { useSearchParams } from 'react-router-dom'
import logo from '../../media/images/logos/newlogo.svg'
import phoneIcon from '../../media/images/logos/phonecontact.svg'

function MainHeader(props) {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [searchParams, setSearchParams] = useSearchParams()
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  let referralParam = null

  if (searchParams.get('referral') !== null) {
    if (searchParams.get('referral') !== '') {
      const prereferral = searchParams.get('referral')
      referralParam = prereferral
    }
  }

  return (
    <AppBar
      position='static'
      color='default'
      elevation={0}
      sx={{ background: '#fff', borderBottom: '1px solid #e2dbec' }}
      id='header'
    >
      <Toolbar>
        <Grid container justifyContent='space-between' direction='row' py='1.2em'>
          <Grid item xs={3} md={6} lg={5} ml='1.2em'>
            <Link
              href={referralParam === null ? '/' : `/?referral=${referralParam}`}
              target='_blank'
            >
              <img loading='lazy' src={logo} width='114px' height='40px' alt='Rooted' />
            </Link>
          </Grid>
          <Grid item xs={6} md={6} lg={6} textAlign='right' my='auto'>
            <Grid container alignSelf='center' justifyContent='end'>
              <Grid item my='auto' mx='1em'>
                <Link
                  sx={{
                    color: '#194339',
                    textDecoration: 'none',
                    textTransform: 'uppercase',
                    fontWeight: 'bold',
                    fontSize: '13px',
                    mx: '1em',
                  }}
                  href='https://heybenny.com/contact-us'
                >
                  Contact Us
                </Link>
              </Grid>
              <Grid item my='auto' mx='1em' display={{ xs: 'none', md: 'block', lg: 'block' }}>
                <Grid
                  container
                  direction='row'
                  alignItems='center'
                  fontWeight='bold'
                  sx={{ color: '#194339' }}
                >
                  <img src={phoneIcon} width='15px' loading='lazy' />
                  <Link
                    sx={{
                      color: '#194339',
                      textDecoration: 'none',
                      textTransform: 'uppercase',
                      fontWeight: 'bold',
                      fontSize: '13px',
                    }}
                    href='tel:6083026665'
                  >
                    (608) 302-6665
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  )
}

export default function Header() {
  return <MainHeader />
}
