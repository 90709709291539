import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import { Box, Button, CircularProgress, Collapse, Container, Grid } from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { Field, Formik } from 'formik'
import { useEffect, useState } from 'react'
import NumberFormat from 'react-number-format'
import { useSearchParams } from 'react-router-dom'
import * as yuo from 'yup'

import { CustomTextField } from 'Components/Fields'
import { GradientTypography } from 'Components/gradientTypography'
import { LabelVertical } from 'Components/LabelVertical'
import { Layout } from 'Components/Layout'
import { Typography } from 'Components/Typography'
import { bennyApi } from 'utils/bennyApi'

import { Contributions } from './Contributions'
import { IESPP } from './types'

function fetchQuotes(ticker: string) {
  return bennyApi.get<{ price: number | null }>(`/espp/getStockPrice/${ticker}/`)
}

function fetchEspp(ticker: string) {
  return bennyApi.get<IESPP[]>(`/espp/espps_by_tickers/?tickers=${ticker}`)
}

const msg = 'Required field'

export const validationSchema = yuo.object({
  symbol: yuo.string().required(msg),
  income: yuo.number().required(msg).positive(),
})

type SchemaType = yuo.InferType<typeof validationSchema>

export const EsppCalculator = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const initialTicker = searchParams.get('ticker') ?? ''
  const [showContributions, setShowContributions] = useState<boolean>(false)
  const [quotesPrice, setQuotesPrice] = useState<number | null>(null)
  const [espp, setEspp] = useState<IESPP | undefined>(undefined)
  const [currentSymbol, setCurrentSymbol] = useState<string>(initialTicker)

  const [show, setShow] = useState<boolean>(false)

  const income = searchParams.get('income') ?? 65000

  const onSubmit = async (values: SchemaType) => {
    try {
      searchParams.set('ticker', values.symbol)
      searchParams.set('income', `${values.income}`)
      setSearchParams(searchParams)
    } catch (error) {
      console.error(error)
    }
  }

  const _onSubmit = async (values: SchemaType) => {
    setShowContributions(false)
    setShow(false)
    try {
      const [quotesResponse, esppResponse] = await Promise.all([
        fetchQuotes(values.symbol),
        fetchEspp(values.symbol),
      ])
      setCurrentSymbol(values.symbol)
      setQuotesPrice(quotesResponse.data.price)
      setEspp(esppResponse.data[0])
      setShowContributions(true)
      setShow(true)
    } catch (error) {
      console.log('Error: ', error)
    }
  }

  useEffect(() => {
    if (!initialTicker && !income) return

    _onSubmit({ income: Number(income), symbol: initialTicker })
  }, [initialTicker, income])

  return (
    <Layout>
      <Grid container>
        <Formik<SchemaType>
          initialValues={{ symbol: initialTicker, income: Number(income) }}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          isInitialValid={false}
        >
          {({
            values,
            handleChange,
            handleBlur,
            touched,
            errors,
            handleSubmit,
            isValid,
            isSubmitting,
            setFieldValue,
          }) => {
            const verified = espp?.verified ?? false

            return (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Container maxWidth={'lg'} sx={{ py: { xs: '16px', md: '128px' } }}>
                  <Grid container display={'flex'} flexDirection={'column'} gap={'24px'}>
                    <GradientTypography sx={{ fontSize: { xs: '28px', md: '48px' } }}>
                      ESPP Calculator
                    </GradientTypography>

                    <Grid container display={'flex'} spacing={'16px'}>
                      <Grid item xs={12} md={4}>
                        <Typography inheritColor>
                          Let’s find your ESPP details. Please enter your company’s stock symbol and
                          income to calculate your gains.
                        </Typography>
                      </Grid>

                      <Grid
                        container
                        item
                        xs={12}
                        md={8}
                        display={'flex'}
                        spacing={'16px'}
                        alignItems={'flex-end'}
                      >
                        <Grid item xs={12} md={4}>
                          <LabelVertical
                            label='Company Stock Symbol'
                            field={
                              <Field
                                component={CustomTextField}
                                fullWidth
                                id='symbol'
                                name='symbol'
                                placeholder='e.g. AAPL'
                                value={values.symbol}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.symbol && Boolean(errors.symbol)}
                              />
                            }
                          />
                        </Grid>

                        <Grid item xs={12} md={4}>
                          <LabelVertical
                            label='Your Annual Income'
                            field={
                              <Field
                                component={NumberFormat}
                                fullWidth
                                id='income'
                                name='income'
                                placeholder='Your Annual Income'
                                value={values.income}
                                onValueChange={(value) => {
                                  setFieldValue('income', value.floatValue ?? '')
                                }}
                                onBlur={handleBlur}
                                error={touched.income && Boolean(errors.income)}
                                customInput={CustomTextField}
                                prefix={'$ '}
                                thousandSeparator
                                autoComplete='off'
                              />
                            }
                          />
                        </Grid>

                        <Grid item xs={12} md={4}>
                          <Button
                            sx={{
                              display: { xs: 'flex', md: 'none' },
                            }}
                            disabled={
                              (!isValid || isSubmitting) && !(!touched.symbol && !!initialTicker)
                            }
                            variant={'contained'}
                            onClick={() => handleSubmit()}
                            endIcon={
                              !isSubmitting ? (
                                <ArrowRightAltIcon />
                              ) : (
                                <CircularProgress size={'24px'} />
                              )
                            }
                          >
                            Find ESPP Details
                          </Button>

                          <Button
                            sx={{ display: { xs: 'none', md: 'flex' } }}
                            disabled={
                              (!isValid || isSubmitting) && !(!touched.symbol && !!initialTicker)
                            }
                            variant={'contained'}
                            onClick={() => handleSubmit()}
                          >
                            {!isSubmitting ? (
                              <ArrowRightAltIcon />
                            ) : (
                              <CircularProgress size={'24px'} />
                            )}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Container>

                {showContributions && !verified && (
                  <Grid
                    container
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    flexDirection={'column'}
                    bgcolor={'#F2EFF7'}
                    p={'32px'}
                    maxWidth={'740px'}
                    mx={'auto'}
                    borderRadius={'8px'}
                  >
                    <Typography variant='body1' textAlign={'center'}>
                      <strong>We currently to do not have ESPP details for {currentSymbol}.</strong>
                      <br />
                      We are always enhancing our data. Please check back in a few days.
                      <br />
                      <br />
                      Thank you for your patience.
                    </Typography>
                  </Grid>
                )}

                <Container maxWidth={'xl'}>
                  {!showContributions && !initialTicker && (
                    <Typography inheritColor sx={{ color: '#A7A7A7' }} textAlign={'center'}>
                      To view ESPP details, please enter the company stock symbol above.
                    </Typography>
                  )}
                  <Collapse in={show} timeout={900} unmountOnExit>
                    {showContributions && !!verified && (
                      <Contributions price={quotesPrice} espp={espp} income={values.income} />
                    )}
                  </Collapse>

                  <Box height={'128px'} />
                </Container>
              </LocalizationProvider>
            )
          }}
        </Formik>
      </Grid>
    </Layout>
  )
}
