import { Grid } from '@mui/material'
import { useEffect } from 'react'
import Footer from '../../Components/common/Footer'
import Header from '../../Components/common/websiteheader'

export function Fincon() {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://wwwrootedlendingcom.involve.me/embed'
    script.async = true

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <Grid container direction='column'>
      <Header />
      <div
        className='involveme_embed'
        data-project='fincon-swag'
        style={{ flex: 1, overflow: 'auto' }}
      />
      <Footer />
    </Grid>
  )
}
