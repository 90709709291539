import { Home } from '..'
import { variant, variantA, variantB, variantC } from '../config'

export function $Home({ forceTrack }: { forceTrack?: boolean }) {
  return <Home variant={variant} forceTrack={forceTrack} />
}

$Home.defaultProps = {
  forceTrack: false,
}

export function HomeV1() {
  return <Home variant={variantA} />
}

export function HomeV2() {
  return <Home variant={variantB} />
}

export function HomeV3() {
  return <Home variant={variantC} />
}
