import axios, { CancelTokenSource } from 'axios'
import { Field, useFormikContext } from 'formik'
import { TextField } from 'formik-mui'
import React from 'react'

import { secret_key } from '../../../Components/newAdmin/benny_secret'

function fetchQuotes(ticker: string, cancelToken: CancelTokenSource) {
  return axios.get(`${process.env.REACT_APP_SERVER_URL}/espp/getStockPrice/${ticker}/`, {
    cancelToken: cancelToken.token,
    headers: {
      'Content-Type': 'application/json',
      'benny-api-key': process.env.REACT_APP_CORE_API_KEY,
      'benny-api-secret': secret_key,
    },
  })
}

function fetchEspp(ticker: string, cancelToken: CancelTokenSource) {
  return axios.get(`${process.env.REACT_APP_SERVER_URL}/espp/espps_by_tickers/?tickers=${ticker}`, {
    cancelToken: cancelToken.token,
    headers: {
      'Content-Type': 'application/json',
      'benny-api-key': process.env.REACT_APP_CORE_API_KEY,
      'benny-api-secret': secret_key,
    },
  })
}

export function SymbolField(props: {
  discountFields?: string[]
  discountedPriceFields?: string[]
  error: boolean
  helperText: string
  lookbackPeriodFields?: string[]
  priceFields: string[]
  purchasePeriodFields?: string[]
}) {
  const [typingTimeout, setTypingTimeout] = React.useState(null)
  const [cancelEsppToken, setCancelEsppToken] = React.useState(axios.CancelToken.source())
  const [cancelQuoteToken, setCancelQuoteToken] = React.useState(axios.CancelToken.source())

  const { setFieldValue, values } = useFormikContext()

  function handleSymbolChange(event: React.ChangeEvent<HTMLInputElement>) {
    const newValue = event.target.value
    setFieldValue('symbol', newValue)

    if (typingTimeout) {
      clearTimeout(typingTimeout)
      cancelEsppToken.cancel()
      cancelQuoteToken.cancel()
    }

    const newQuoteCancelToken = axios.CancelToken.source()
    setCancelQuoteToken(newQuoteCancelToken)

    const newCancelEsppToken = axios.CancelToken.source()
    setCancelEsppToken(newCancelEsppToken)

    if (!newValue.length) {
      props.priceFields.forEach((f) => setFieldValue(f, ''))
      return
    }

    setTypingTimeout(
      setTimeout(() => {
        Promise.all([
          fetchQuotes(event.target.value, newQuoteCancelToken),
          fetchEspp(event.target.value, newCancelEsppToken),
        ])
          .then(([quotesResponse, esppResponse]) => {
            props.priceFields.forEach((f) => setFieldValue(f, quotesResponse.data.price))

            if (!esppResponse.data.length) return

            const espp = esppResponse.data[0]
            const discount = parseFloat(espp.discount)
            if (props.discountedPriceFields) {
              props.discountedPriceFields.forEach((f) =>
                setFieldValue(f, (quotesResponse.data.price * (1 - discount)).toFixed(2)),
              )
            }
            if (props.discountFields) {
              props.discountFields.forEach((f) => setFieldValue(f, discount * 100))
            }
            if (props.lookbackPeriodFields) {
              props.lookbackPeriodFields.forEach((f) => setFieldValue(f, espp.lookback || ''))
            }
            if (props.purchasePeriodFields) {
              props.purchasePeriodFields.forEach((f) =>
                setFieldValue(f, espp.purchase_period_length || ''),
              )
            }
          })
          .catch((error) => {
            if (!axios.isCancel(error)) throw error
          })
      }, 200),
    )
  }
  return (
    <Field
      component={TextField}
      error={props.error}
      helperText={props.helperText}
      name='symbol'
      onChange={handleSymbolChange}
      type='text'
    />
  )
}
