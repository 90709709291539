import { Visibility, VisibilityOff } from '@mui/icons-material'
import {
  Alert,
  AlertColor,
  Box,
  Button,
  IconButton,
  InputAdornment,
  Modal,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material'
import { useState } from 'react'
import { authenticateClient, createClient } from '../../api/authService'
import { useClientSelector } from '../../store/client'

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '8px',
  p: 4,
}

const useAuthClient = () => {
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [emailError, setEmailError] = useState<boolean>(false)
  const [passwordError, setPasswordError] = useState<boolean>(false)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false)
  const [snackbarMessage, setSnackbarMessage] = useState<string>('')
  const [alertSeverity, setAlertSeverity] = useState<AlertColor>('success')
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const setClient = useClientSelector.useSetClient()
  const client = useClientSelector((state) => state.client)
  const clearClient = useClientSelector.useClearClient()

  const reload = () => window.location.reload()

  const handleOpen = () => setOpenModal(true)

  const handleClose = () => {
    setOpenModal(false)
    setEmail('')
    setPassword('')
    setShowPassword(false)
  }

  const handleCloseSnackbar = () => setOpenSnackbar(false)

  const handleClickShowPassword = () => setShowPassword((s) => !s)
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  const validateEmail = () => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
    if (!email || !emailRegex.test(email)) {
      setEmailError(true)
      return false
    }
    setEmailError(false)
    return true
  }

  const validatePassword = () => {
    if (!password || password.length < 6) {
      setPasswordError(true)
      return false
    }
    setPasswordError(false)
    return true
  }

  const handleLogout = () => {
    clearClient()
    reload()
  }

  const handleCreateClient = async () => {
    setIsLoading(true)

    const isEmailValid = validateEmail()
    const isPasswordValid = validatePassword()
    if (isEmailValid && isPasswordValid) {
      try {
        const responseData = await createClient({ email, password })

        if (responseData.client) {
          setClient(responseData.client)

          setAlertSeverity('success')
          setSnackbarMessage('Account created successfully!')
        }

        setOpenSnackbar(true)
        handleClose()
        setTimeout(() => {
          reload()
        }, 3000)
      } catch (error) {
        const errorMessage =
          error.response?.data?.email[0] || error.message || 'Failed to create account'
        setAlertSeverity('error')
        setSnackbarMessage(errorMessage)
        setOpenSnackbar(true)
      }
    }
    setIsLoading(false)
  }

  const handleLoginClient = async () => {
    setIsLoading(true)
    const isEmailValid = validateEmail()
    const isPasswordValid = validatePassword()

    if (isEmailValid && isPasswordValid) {
      try {
        const responseData = await authenticateClient({ email, password })
        if (responseData.client) {
          setClient(responseData.client)

          setAlertSeverity('success')
          setSnackbarMessage('Login successful! Welcome back.')
        }

        setOpenSnackbar(true)
        handleClose()
        setTimeout(() => {
          reload()
        }, 3000)
      } catch (error) {
        const errorMessage = 'Failed to login. Please check your credentials and try again.'
        setAlertSeverity('error')
        setSnackbarMessage(errorMessage)
        setOpenSnackbar(true)
      }
      setIsLoading(false)
    }
  }

  return {
    email,
    setEmail,
    password,
    setPassword,
    emailError,
    setEmailError,
    passwordError,
    setPasswordError,
    openModal,
    setOpenModal,
    showPassword,
    setShowPassword,
    handleCreateClient,
    handleLoginClient,
    handleOpen,
    handleClose,
    handleClickShowPassword,
    handleMouseDownPassword,
    client,
    isLoading,
    openSnackbar,
    handleCloseSnackbar,
    alertSeverity,
    snackbarMessage,
    handleLogout,
  }
}

interface AuthClientFormProps {
  asLink?: boolean
  label?: string
}

export const AuthClientForm = ({ asLink, label = 'Login' }: AuthClientFormProps) => {
  const {
    email,
    setEmail,
    password,
    setPassword,
    emailError,
    passwordError,
    openModal,
    showPassword,
    handleCreateClient,
    handleLoginClient,
    handleOpen,
    handleClose,
    handleClickShowPassword,
    handleMouseDownPassword,
    client,
    isLoading,
    openSnackbar,
    handleCloseSnackbar,
    alertSeverity,
    snackbarMessage,
    handleLogout,
  } = useAuthClient()

  return null

  return (
    <div>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={alertSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      {!client ? (
        <Button
          onClick={handleOpen}
          sx={{
            borderColor: '#502694',
            color: '#502694',
            textTransform: 'none',
            borderRadius: '8px',
            textDecoration: asLink ? 'underline' : 'none',
          }}
          variant={asLink ? 'text' : 'outlined'}
        >
          {label}
        </Button>
      ) : (
        <Button
          onClick={handleLogout}
          sx={{
            borderColor: '#502694',
            color: '#502694',
            textTransform: 'none',
            borderRadius: '8px',
          }}
          variant='outlined'
        >
          Logout
        </Button>
      )}

      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        keepMounted
      >
        <Box sx={modalStyle}>
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            Authentication
          </Typography>
          <Box mt={2} display={'flex'} flexDirection={'column'} gap={2}>
            <TextField
              label='Email'
              fullWidth
              margin='normal'
              error={emailError}
              helperText={emailError ? 'Email is required' : ''}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />

            <TextField
              label='Password'
              fullWidth
              margin='normal'
              error={passwordError}
              helperText={passwordError ? 'Password must be at least 6 characters' : ''}
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge='end'
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <Button
              disabled={isLoading}
              onClick={() => handleLoginClient()}
              sx={{
                borderColor: '#502694',
                bgcolor: '#502694',
                color: '#fff',
                textTransform: 'none',
                borderRadius: '8px',
                '&:hover': { background: '#8746FE' },
              }}
              variant='contained'
            >
              Login
            </Button>

            <Button
              disabled={isLoading}
              onClick={() => handleCreateClient()}
              sx={{
                borderColor: '#502694',
                color: '#502694',
                textTransform: 'none',
                borderRadius: '8px',
              }}
              variant='outlined'
            >
              Create Account
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  )
}
