import * as mui from '@mui/material'
import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined'
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined'
import React from 'react'

import { Typography } from 'Components/Typography'


export function EstimatedTaxSection(props: {
  title: string
  costBasis: number
  isShortTerm: boolean
  ordinaryIncome: number
  ordinaryIncomeTax: number
  longTermGains: number
  longTermGainsTax: number
  profit: number
  sharesAmount: number
}) {
  const [showDetails, setShowDetails] = React.useState(false)

  const ordinaryIncomeTaxAbs = props.ordinaryIncome * props.ordinaryIncomeTax / 100
  const longTermGainsTaxAbs = props.longTermGains * props.longTermGainsTax / 100
  const totalTax = ordinaryIncomeTaxAbs + longTermGainsTaxAbs
  const netProfit = props.profit - totalTax
  return (
    <>
      <mui.Stack direction='row' spacing={1} sx={{borderBottom: 1, borderColor: 'primary.light'}}>
        <mui.Box>
          <mui.IconButton onClick={() => setShowDetails(!showDetails)}>
            {showDetails
              ? <RemoveCircleOutlineOutlinedIcon />
              : <ControlPointOutlinedIcon sx={{color: 'secondary.light'}} />
            }
          </mui.IconButton>
        </mui.Box>
        <mui.Stack sx={{width: '100%', pb: 3}}>
          <Typography sx={{color: 'black', mt: '7px', mb: 2}}><b>{props.title}</b></Typography>
          <mui.Grid container>
            <mui.Grid item xs={6}>
              <Typography sx={{color: 'black'}}>Net Gains After Tax</Typography>
            </mui.Grid>
            <mui.Grid item xs={6}>
              <Typography sx={{color: 'black'}}>Total Tax</Typography>
            </mui.Grid>
          </mui.Grid>
          <mui.Grid container>
            <mui.Grid item xs={6}>
              <Typography sx={{color: 'black'}}>
                {netProfit.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
              </Typography>
            </mui.Grid>
            <mui.Grid item xs={6}>
              <Typography sx={{color: 'black'}}>
                {totalTax.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
              </Typography>
            </mui.Grid>
          </mui.Grid>

          <mui.Collapse in={showDetails}>
            <mui.Stack sx={{
              background: 'rgba(25, 67, 57, 0.20)',
              borderRadius: '12px',
              mb: 1,
              mt: 3,
              px: 3,
              py: 4,
            }}>
              <mui.Grid container>
                <mui.Grid item xs={6}>
                  <Typography sx={{ color: 'black' }}>Cost Basis</Typography>
                </mui.Grid>
                <mui.Grid item xs={6}>
                  <Typography sx={{ color: 'black' }}>
                    {props.costBasis.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                  </Typography>
                </mui.Grid>
                {/*<Typography variant='body2' sx={{ color: '#1D1D1F', opacity: .5 }}>*/}
                {/*  <i>{props.sharesAmount} shares * share price on the offering date</i>*/}
                {/*</Typography>*/}
              </mui.Grid>

              <Typography sx={{color: 'black', mt: 3}}><b>Ordinary Income</b></Typography>
              <mui.Grid container>
                <mui.Grid item xs={6}>
                  <Typography sx={{color: 'black'}}>Income</Typography>
                </mui.Grid>
                <mui.Grid item xs={6}>
                  <Typography sx={{color: 'black'}}>
                    {props.ordinaryIncome.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                  </Typography>
                </mui.Grid>
              </mui.Grid>
              <mui.Grid container>
                <mui.Grid item xs={6}>
                  <Typography sx={{color: 'black'}}>Tax ({props.ordinaryIncomeTax}%)</Typography>
                </mui.Grid>
                <mui.Grid item xs={6}>
                  <Typography sx={{color: 'black'}}>
                    {ordinaryIncomeTaxAbs.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                  </Typography>
                </mui.Grid>
              </mui.Grid>

              <Typography sx={{color: 'black', mt: 3}}>
                <b>
                  {props.isShortTerm ? 'Short Term' : 'Long Term'} Capital Gains
                </b>
              </Typography>
              <mui.Grid container>
                <mui.Grid item xs={6}>
                  <Typography sx={{color: 'black'}}>Gains</Typography>
                </mui.Grid>
                <mui.Grid item xs={6}>
                  <Typography sx={{color: 'black'}}>
                    {props.longTermGains.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                  </Typography>
                </mui.Grid>
              </mui.Grid>
              <mui.Grid container>
                <mui.Grid item xs={6}>
                  <Typography sx={{color: 'black'}}>
                    Tax ({props.isShortTerm ? props.ordinaryIncomeTax : props.longTermGainsTax}%)
                  </Typography>
                </mui.Grid>
                <mui.Grid item xs={6}>
                  <Typography sx={{color: 'black'}}>
                    {longTermGainsTaxAbs.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                  </Typography>
                </mui.Grid>
              </mui.Grid>
            </mui.Stack>
          </mui.Collapse>
        </mui.Stack>
      </mui.Stack>
    </>
  )
}