import { Crypt } from 'hybrid-crypto-js'
import { Espp } from '../types'

const publicKey = `-----BEGIN PUBLIC KEY-----
MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCfm2uMTvb+gqXRFxWHnfCKcHfH
v7aMN6oiEqTJj0BixtTYBXH89N+xuYgoIBnfMXPXPIg/UNWEOZtAETsOVvya+YBo
ZZTquYJ2I0PaxtpUKkpCiEQ/bTCQIDAeUwHr0l4vUn/fmslD0rZ3+jo4Dsl8nX0O
pwZQ2grQaXLb347RQwIDAQAB
-----END PUBLIC KEY-----`

export const encrypt_api_secret = (secretKey: string) => {
  const crypt = new Crypt()

  // Perform our encryption based on our public key - only private key can read it!
  const encrypted = crypt.encrypt(publicKey, secretKey)

  // Return encrypted string
  return encrypted
}

export const secret_key = encrypt_api_secret(process.env.REACT_APP_CORE_API_SECRET)

export function formatToDollars(amount: number, minimumFractionDigits?: number): string {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: minimumFractionDigits ?? 0,
    maximumFractionDigits: minimumFractionDigits ?? 0,
  }).format(amount)
}

export const monthDescriptions = {
  1: 'one',
  2: 'two',
  3: 'three',
  4: 'four',
  5: 'five',
  6: 'six',
  7: 'seven',
  8: 'eight',
  9: 'nine',
  10: 'ten',
  11: 'eleven',
  12: 'twelve',
}

export function calculateBennyFund({
  income,
  contributionPercentage,
  espp,
}: {
  income: number
  contributionPercentage: number
  espp: Espp
}) {
  const numericAnnualIncome = income
  const numericContributionPercentage = contributionPercentage

  let estLoanAmount: number
  let loanAmount: number

  if (
    !numericContributionPercentage ||
    numericContributionPercentage > parseFloat(espp?.contr_percent_limit)
  ) {
    estLoanAmount =
      (numericAnnualIncome * parseFloat(espp?.contr_percent_limit) * espp?.purchase_period_length) /
      12
  } else {
    estLoanAmount =
      (numericAnnualIncome * numericContributionPercentage * espp?.purchase_period_length) / 12
  }

  if (!espp?.period_contr_dollar_limit) {
    if (estLoanAmount > 25000 * (1 - parseFloat(espp?.discount))) {
      loanAmount = 25000 * (1 - parseFloat(espp?.discount))
    } else {
      loanAmount = estLoanAmount
    }
  } else {
    if (estLoanAmount > parseFloat(espp?.period_contr_dollar_limit)) {
      loanAmount = parseFloat(espp?.period_contr_dollar_limit)
    } else {
      loanAmount = estLoanAmount
    }
  }

  return Math.round(loanAmount)
}
