import { SxProps, Typography, TypographyProps } from '@mui/material'
import React from 'react'

type CustomVariant = 'title' | 'subtitle' | 'body' | 'headding'

interface CustomTextProps extends Omit<TypographyProps, 'sx'> {
  customVariant?: CustomVariant
  sx?: SxProps
}

export const textVariants: Record<CustomVariant, SxProps> = {
  title: {
    fontFamily: 'Garnett-Semibold',
    fontSize: { xs: '30px', md: '46px' },
    lineHeight: '130%',
    textAlign: 'center',
  },
  subtitle: {
    fontFamily: 'Garnett-Regular',
    fontSize: { xs: '18px', md: '24px' },
    lineHeight: '150%',
    textAlign: 'center',
  },
  body: {
    fontFamily: 'Helvetica Neue',
    fontSize: { xs: '20px', md: '20px' },
    textAlign: 'center',
  },
  headding: {
    fontFamily: 'Garnett-Bold',
    fontSize: { xs: '24px', md: '24px' },
    lineHeight: 'normal',
    textAlign: 'center',
  },
}

export const CustomText: React.FC<CustomTextProps> = ({
  children,
  customVariant,
  sx: additionalSx,
  ...otherProps
}) => {
  const combinedSx = {
    ...textVariants[customVariant],
    ...additionalSx,
  }

  return (
    <Typography sx={combinedSx} {...otherProps}>
      {children}
    </Typography>
  )
}
