import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import * as mui from '@mui/material'
import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-mui'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'

import { LabelVertical } from 'Components/LabelVertical'
import { Typography } from 'Components/Typography'
import { userContext } from 'context/user'
import { graphqlClient } from 'utils/graphql'

import { ForgotPassword } from '../ForgotPassword'
import { Layout } from '../Layout'

const requiredMsg = 'Required'
const validationSchema = yup.object().shape({
  email: yup.string().email().required(requiredMsg),
  password: yup.string().min(8, '8 characters minimum').required(requiredMsg),
})

export function Login() {
  const user = React.useContext(userContext)

  const [commonErrors, setCommonErrors] = React.useState<string[]>([])
  const [showForgotPassword, setShowForgotPassword] = React.useState(false)

  let navigate = useNavigate()

  function handleLogin(values: { email: string; password: string }, setFieldError: (f: string, e: string) => void) {
    setCommonErrors([])
    graphqlClient.chain.mutation
      .login({ email: values.email, password: values.password })
      .execute({ __scalar: 1 })
      .then(() => user.refetch())
      .catch((e) => {
        if (e.errors[0].extensions.common_errors) {
          setCommonErrors(e.errors[0].extensions.common_errors)
        } else if (e.errors[0].extensions.field_errors) {
          for (const [field, error] of Object.entries(e.errors[0].extensions.field_errors)) {
            setFieldError(field, error as string)
          }
        } else {
          throw e
        }
      })
  }

  if (user.spinner) return user.spinner
  if (user.data) {
    if (user.data.isStaff) {
      navigate('/admin')
    } else {
      navigate('/customer/applications')
    }
  }

  if (showForgotPassword) return <ForgotPassword goBack={() => setShowForgotPassword(false)} />

  return (
    <Layout>
      <Typography variant='h3' sx={{ color: 'black' }}>
        Welcome Back
      </Typography>
      <Typography sx={{ color: 'black', mb: 3 }}>
        Please enter your email and password to access your account.
      </Typography>
      <Formik
        initialValues={{ email: '', password: '' }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, setFieldError }) => {
          handleLogin(values, setFieldError)
          setSubmitting(false)
        }}
      >
        {({ submitForm, isSubmitting, touched, errors }) => (
          <Form>
            {commonErrors.map((error, i) => (
              <mui.Alert key={i} severity='error'>
                {error}
              </mui.Alert>
            ))}
            <LabelVertical
              field={
                <Field
                  component={TextField}
                  error={touched.email && !!errors.email}
                  helperText={touched.email && errors.email}
                  InputProps={{
                    autoComplete: 'email',
                    autoFocus: true,
                    fullWidth: true,
                    size: 'small',
                    type: 'email',
                  }}
                  name='email'
                  fullWidth
                  required
                />
              }
              label='Email address'
              sx={{ mt: 2 }}
            />
            <LabelVertical
              field={
                <Field
                  component={TextField}
                  error={touched.password && !!errors.password}
                  fullWidth
                  helperText={touched.password && errors.password}
                  InputProps={{ fullWidth: true, size: 'small', type: 'password' }}
                  name='password'
                  required
                />
              }
              label='Password'
              sx={{ mt: 2 }}
            />
            <mui.Stack
              sx={{
                mt: 5,
              }}
            >
              <mui.Box textAlign='center'>
                <mui.Button
                  disabled={isSubmitting}
                  endIcon={<ArrowForwardIcon />}
                  onClick={submitForm}
                  type='submit'
                  variant='contained'
                >
                  Login
                </mui.Button>
              </mui.Box>
              <mui.Link
                href='#'
                onClick={(e) => {
                  e.preventDefault()
                  setShowForgotPassword(true)
                }}
                sx={{
                  mt: 4,
                  textAlign: 'center',
                  textDecoration: 'none',
                }}
              >
                <Typography
                  variant='body2'
                  sx={{ color: (theme) => theme.palette.secondary.light }}
                >
                  Forgot Password
                </Typography>
              </mui.Link>
            </mui.Stack>
          </Form>
        )}
      </Formik>
    </Layout>
  )
}
