import * as mui from '@mui/material'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { addYears, format } from 'date-fns'
import { Formik, Form, Field } from 'formik'
import { TextField } from 'formik-mui'
import { DatePicker } from 'formik-mui-x-date-pickers'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import React from 'react'
import * as Yup from 'yup'

import { LabelVertical } from '../../../Components/LabelVertical'
import { useEventClick } from '../../../hooks/tracker'
import { SymbolField } from '../SymbolField'


const integerMsg = 'Must be an integer'
const nonNegativeMsg = 'Must be non-negative'
const positiveMsg = 'Must be positive'
const requiredMsg = 'Required'


const validationSchema = Yup.object().shape({
  symbol:             Yup.string().required(requiredMsg),
  offeringDate:       Yup.date().required(requiredMsg),
  purchaseDate:       Yup.date().required(requiredMsg),
  offeringDateFmv:    Yup.number().required(requiredMsg).positive(positiveMsg),
  purchaseDateFmv:    Yup.number().required(requiredMsg).positive(positiveMsg),
  purchasePrice:      Yup.number().required(requiredMsg).positive(positiveMsg),
  totalShareCount:    Yup.number().required(requiredMsg).positive(positiveMsg).integer(integerMsg),
  incomeTaxRate:      Yup.number().min(0, nonNegativeMsg).integer(integerMsg),
  capitalGainTaxRate: Yup.number().min(0, nonNegativeMsg).integer(integerMsg),

  sellingPrice:      Yup.number().required(requiredMsg).positive(positiveMsg),
  sharesToSell:      Yup.number().required(requiredMsg).positive(positiveMsg),
})


function ResultsRow(props: {
  border?: boolean
  label: string
  tooltip?: string
  values: string[]
}) {
  const sx = props.border ? {} : {border: 0}
  return (
    <mui.TableRow hover>
      <mui.TableCell sx={sx}>
        {props.label}
        {props.tooltip && (
          <mui.Tooltip title={props.tooltip}>
            <mui.IconButton size="small" sx={{p: 0, ml: .5, fontSize: '0.9rem'}}>
              <HelpOutlineIcon fontSize="inherit"/>
            </mui.IconButton>
          </mui.Tooltip>
        )}
      </mui.TableCell>
      {props.values.map((value, i) => (
        <mui.TableCell key={i} align="center" sx={sx} colSpan={4-props.values.length}>{value}</mui.TableCell>
      ))}
    </mui.TableRow>
  )
}


export function TaxesCalculator() {
  const [isExpanded, setIsExpanded] = React.useState(false)
  const [isSubmitted, setIsSubmitted] = React.useState(false)

  const [incomeTaxRate, setIncomeTaxRate] = React.useState(0)
  const [dates, setDates] = React.useState([new Date(), new Date(), new Date()])
  const [proceeds, setProceeds] = React.useState(0)
  const [investedAmount, setInvestedAmount] = React.useState(0)
  const [grossProfit, setGrossProfit] = React.useState(0)
  const [totalTax, setTotalTax] = React.useState([0, 0, 0])
  const [ordinaryIncome, setOrdinaryIncome] = React.useState([0, 0, 0])
  const [tax, setTax] = React.useState([0, 0, 0])
  const [costBasis, setCostBasis] = React.useState([0, 0, 0])
  const [capitalGains, setCapitalGains] = React.useState([0, 0, 0])
  const [capitalGainsTaxRate, setCapitalGainsTaxRate] = React.useState([0, 0, 0])
  const [capitalGainsTax, setCapitalGainsTax] = React.useState([0, 0, 0])
  const [taxRateOfProfit, setTaxRateOfProfit] = React.useState([0, 0, 0])
  const [netProceeds, setNetProceeds] = React.useState([0, 0, 0])
  const [netProfit, setNetProfit] = React.useState([0, 0, 0])

  const { createEvent } = useEventClick()

  const barChartConfig = {
    chart: {type: 'column'},
    title: {text: ''},
    xAxis: {
      categories: [
        `Before ${format(dates[0], 'M/dd/yyyy')}`,
        `Before ${format(dates[1], 'M/dd/yyyy')}`,
        `After ${format(dates[2], 'M/dd/yyyy')}`,
      ]
    },
    yAxis: {
      title: {text: ''}
    },
    legend: {reversed: true},
    plotOptions: {
      series: {stacking: 'normal'}
    },
    series: [{
      name: 'Profit',
      data: netProfit,
    }, {
      name: 'Taxes',
      data: tax,
    }, {
      name: 'Invested',
      data: [investedAmount, investedAmount, investedAmount],
    }],
    credits: {enabled: false},
  }

  return (
    <Formik
      initialValues={{
        symbol: '',
        offeringDate: new Date(),
        purchaseDate: new Date(),
        offeringDateFmv: 0,
        purchaseDateFmv: 0,
        purchasePrice: 0,
        totalShareCount: 100,
        incomeTaxRate: 22,
        capitalGainTaxRate: 15,

        sellingPrice: 0,
        sharesToSell: 100,
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        const _proceeds = values.purchaseDateFmv * values.totalShareCount
        const _investedAmount = values.purchasePrice * values.totalShareCount
        const _grossProfit = _proceeds - _investedAmount
        const _costBasis = [
          values.purchaseDateFmv * values.totalShareCount,
          values.purchaseDateFmv * values.totalShareCount,
          values.offeringDateFmv * values.totalShareCount,
        ]
        const _ordinaryIncome = [
          (values.purchaseDateFmv - values.purchasePrice) * values.totalShareCount,
          (values.purchaseDateFmv - values.purchasePrice) * values.totalShareCount,
          _costBasis[2] - _investedAmount,
        ]
        const _capitalGainsTaxRate = [
          values.incomeTaxRate,
          values.capitalGainTaxRate,
          values.capitalGainTaxRate,
        ]
        const _tax = [
          _ordinaryIncome[0] * values.incomeTaxRate / 100,
          _ordinaryIncome[1] * values.incomeTaxRate / 100,
          _ordinaryIncome[2] * values.incomeTaxRate / 100,
        ]
        const _capitalGains = [
          _proceeds - _costBasis[0],
          _proceeds - _costBasis[1],
          _proceeds - _costBasis[2],
        ]
        const _capitalGainsTax = [
          _capitalGains[0] * values.capitalGainTaxRate / 100,
          _capitalGains[1] * values.capitalGainTaxRate / 100,
          _capitalGains[2] * values.capitalGainTaxRate / 100,
        ]
        const _totalTax = [
          _tax[0] + _capitalGainsTax[0],
          _tax[1] + _capitalGainsTax[1],
          _tax[2] + _capitalGainsTax[2],
        ]
        const _taxRateForProfit = [
          _totalTax[0] / _grossProfit * 100,
          _totalTax[1] / _grossProfit * 100,
          _totalTax[2] / _grossProfit * 100,
        ]
        const _netProceeds = [
          _proceeds - _totalTax[0],
          _proceeds - _totalTax[1],
          _proceeds - _totalTax[2],
        ]
        const _netProfit = [
          _netProceeds[0] - _investedAmount,
          _netProceeds[1] - _investedAmount,
          _netProceeds[2] - _investedAmount,
        ]

        setIncomeTaxRate(values.incomeTaxRate)
        setDates([
          addYears(new Date(values.purchaseDate), 1),
          addYears(new Date(values.offeringDate), 2),
          addYears(new Date(values.offeringDate), 2),
        ])
        setProceeds(_proceeds)
        setInvestedAmount(_investedAmount)
        setGrossProfit(_grossProfit)
        setTotalTax(_totalTax)
        setOrdinaryIncome(_ordinaryIncome)
        setTax(_tax)
        setCostBasis(_costBasis)
        setCapitalGains(_capitalGains)
        setCapitalGainsTaxRate(_capitalGainsTaxRate)
        setCapitalGainsTax(_capitalGainsTax)
        setTaxRateOfProfit(_taxRateForProfit)
        setNetProceeds(_netProceeds)
        setNetProfit(_netProfit)

        setIsSubmitted(true)
        setSubmitting(false)
      }}
    >
      {({ submitForm, isSubmitting, touched, errors }) => (
        <Form style={{width: '100%'}}>
          <mui.Grid item container xs={12}>
            <mui.Grid item xs={12} md={4}>
              <mui.Stack spacing={3}>
                <LabelVertical
                  field={
                    <SymbolField
                      discountedPriceFields={['purchasePrice']}
                      error={touched.symbol && !!errors.symbol}
                      helperText={touched.symbol && errors.symbol}
                      priceFields={['offeringDateFmv', 'purchaseDateFmv', 'sellingPrice']}
                    />
                  }
                  label='Stock symbol'
                />
                <LabelVertical
                  field={
                    <Field
                      component={DatePicker}
                      error={touched.offeringDate && !!errors.offeringDate}
                      helperText={touched.offeringDate && errors.offeringDate}
                      InputProps={{size: 'small'}}
                      name="offeringDate"
                    />
                  }
                  label="Offering date"
                />
                <LabelVertical
                  field={
                    <Field
                      component={DatePicker}
                      error={touched.purchaseDate && !!errors.purchaseDate}
                      helperText={touched.purchaseDate && errors.purchaseDate}
                      InputProps={{size: 'small'}}
                      name="purchaseDate"
                    />
                  }
                  label="Purchase date"
                />
                <LabelVertical
                  field={
                    <Field
                      component={TextField}
                      error={touched.offeringDateFmv && !!errors.offeringDateFmv}
                      helperText={touched.offeringDateFmv && errors.offeringDateFmv}
                      InputProps={{
                        size: 'small',
                        startAdornment: <mui.InputAdornment position="start">$</mui.InputAdornment>,
                      }}
                      name="offeringDateFmv"
                      type="number"
                    />
                  }
                  label="Offering date FMV"
                />
                <LabelVertical
                  field={
                    <Field
                      component={TextField}
                      error={touched.purchaseDateFmv && !!errors.purchaseDateFmv}
                      helperText={touched.purchaseDateFmv && errors.purchaseDateFmv}
                      InputProps={{
                        size: 'small',
                        startAdornment: <mui.InputAdornment position="start">$</mui.InputAdornment>,
                      }}
                      name="purchaseDateFmv"
                      type="number"
                    />
                  }
                  label="Purchase date FMV"
                />
                <LabelVertical
                  field={
                    <Field
                      component={TextField}
                      error={touched.purchasePrice && !!errors.purchasePrice}
                      helperText={touched.purchasePrice && errors.purchasePrice}
                      InputProps={{
                        size: 'small',
                        startAdornment: <mui.InputAdornment position="start">$</mui.InputAdornment>,
                      }}
                      name="purchasePrice"
                      type="number"
                    />
                  }
                  label="Purchase price"
                />
                <LabelVertical
                  field={
                    <Field
                      component={TextField}
                      error={touched.totalShareCount && !!errors.totalShareCount}
                      helperText={touched.totalShareCount && errors.totalShareCount}
                      name="totalShareCount"
                      type="number"
                    />
                  }
                  label="Total share count"
                />
                <LabelVertical
                  field={
                    <Field
                      component={TextField}
                      error={touched.incomeTaxRate && !!errors.incomeTaxRate}
                      helperText={touched.incomeTaxRate && errors.incomeTaxRate}
                      InputProps={{
                        size: 'small',
                        endAdornment: <mui.InputAdornment position="end">%</mui.InputAdornment>,
                      }}
                      name="incomeTaxRate"
                      type="number"
                    />
                  }
                  label="Ordinary income tax rate (%)"
                />
                <LabelVertical
                  field={
                    <Field
                      component={TextField}
                      error={touched.capitalGainTaxRate && !!errors.capitalGainTaxRate}
                      helperText={touched.capitalGainTaxRate && errors.capitalGainTaxRate}
                      InputProps={{
                        size: 'small',
                        endAdornment: <mui.InputAdornment position="end">%</mui.InputAdornment>,
                      }}
                      name="capitalGainTaxRate"
                      type="number"
                    />
                  }
                  label="Long term capital gains tax rate (%)"
                />
                <mui.Button
                  disabled={isSubmitting}
                  onClick={() => {createEvent({name: 'TaxesCalculatorSubmit'}); return submitForm()}}
                  variant="contained"
                >
                  Submit
                </mui.Button>
              </mui.Stack>
            </mui.Grid>
            <mui.Grid item xs={12} md={8}>
            {isSubmitted && (
              <mui.Stack spacing={3} pl={8} mt={-1}>
                <HighchartsReact highcharts={Highcharts} options={barChartConfig}/>
                <mui.TableContainer>
                  <mui.Table size="small">
                    <mui.TableHead>
                      <mui.TableRow>
                        <mui.TableCell sx={{border: 0, fontWeight: 'bold', width: 190, py: 0}}></mui.TableCell>
                        <mui.TableCell sx={{border: 0, fontWeight: 'bold', py: 0}} colSpan={2} align="center">
                          Disqualifying Sale
                        </mui.TableCell>
                        <mui.TableCell sx={{border: 0, fontWeight: 'bold', p: 0}} align="center">
                          Qualifying Sale
                        </mui.TableCell>
                      </mui.TableRow>
                      <mui.TableRow>
                        <mui.TableCell sx={{border: 0}}></mui.TableCell>
                        <mui.TableCell align="center" sx={{border: 0, fontWeight: 'bold'}}>
                          Before {format(dates[0], 'M/dd/yyyy')}
                        </mui.TableCell>
                        <mui.TableCell align="center" sx={{border: 0, fontWeight: 'bold'}}>
                          Before {format(dates[1], 'M/dd/yyyy')}
                        </mui.TableCell>
                        <mui.TableCell align="center" sx={{border: 0, fontWeight: 'bold'}}>
                          After {format(dates[2], 'M/dd/yyyy')}
                        </mui.TableCell>
                      </mui.TableRow>
                    </mui.TableHead>
                    <mui.TableBody>
                      <mui.TableRow>
                        <mui.TableCell sx={{border: 0}}>Selling Price</mui.TableCell>
                        <mui.TableCell align="center" colSpan={3} sx={{border: 0}}>
                          <Field
                            component={TextField}
                            error={touched.sellingPrice && !!errors.sellingPrice}
                            helperText={touched.sellingPrice && errors.sellingPrice}
                            InputLabelProps={{shrink: true}}
                            InputProps={{
                              inputProps: {style: {textAlign: 'right' }},
                              sx: {width: 120},
                              size: 'small',
                              startAdornment: <mui.InputAdornment position="start">$</mui.InputAdornment>,
                            }}
                            name="sellingPrice"
                            type="number"
                          />
                        </mui.TableCell>
                      </mui.TableRow>
                      <mui.TableRow>
                        <mui.TableCell sx={{border: 0}}>Shares To Sell</mui.TableCell>
                        <mui.TableCell align="center" colSpan={3} sx={{border: 0}}>
                          <Field
                            component={TextField}
                            error={touched.sharesToSell && !!errors.sharesToSell}
                            helperText={touched.sharesToSell && errors.sharesToSell}
                            InputLabelProps={{shrink: true}}
                            InputProps={{sx: {width: 120}, size: 'small', inputProps: {style: {textAlign: 'right'}}}}
                            name="sharesToSell"
                            type="number"
                          />
                        </mui.TableCell>
                      </mui.TableRow>
                      <ResultsRow
                        label="Proceeds"
                        tooltip="The entire amount of money you receive from the sale of your stock position."
                        values={[`$${proceeds.toLocaleString()}`]}
                      />
                      <ResultsRow
                        label="Invested Amount"
                        tooltip="The amount of money you invested in the stock position with the ESPP discount."
                        values={[`$${investedAmount.toLocaleString()}`]}
                      />
                      <ResultsRow
                        border
                        label="Gross Profit"
                        tooltip="The amount of money you earned from the apprecitation of the stock position before taxes."
                        values={[`$${grossProfit.toLocaleString()}`]}
                      />

                      <ResultsRow
                        border
                        label="Total Tax"
                        tooltip="The potential amount of taxes you may owe from the sale of your stock position.
                                 Expand the table to see the breakdown of tax calculation."
                        values={totalTax.map(e => `$${e.toLocaleString()}`)}
                      />

                      <mui.TableRow hover>
                        <mui.TableCell
                          colSpan={4}
                          sx={{textAlign: 'center', cursor: 'pointer', p: 1}}
                          onClick={() => setIsExpanded(!isExpanded)}
                        >
                          {isExpanded ? 'Hide' : 'Show'} Tax Breakdown
                        </mui.TableCell>
                      </mui.TableRow>

                      {isExpanded && <>
                        <ResultsRow
                          label="Ordinary Income"
                          tooltip="The amount of income that is taxed at the ordinary income tax rate provided."
                          values={ordinaryIncome.map(e => `$${e.toLocaleString()}`)}
                        />
                        <ResultsRow
                          border
                          label={`Tax (${incomeTaxRate}%)`}
                          tooltip="The calculated amount of tax on the oridinary income amount."
                          values={tax.map(e => `$${e.toLocaleString()}`)}
                        />

                        <ResultsRow
                          label="Cost Basis"
                          tooltip="The base cost amount of the stock that is used to calculate the capital gains.
                                   Capital gains are calculated by subtracting the cost basis from the proceeds."
                          values={costBasis.map(e => `$${e.toLocaleString()}`)}
                        />
                        <ResultsRow
                          label="Capital Gains"
                          tooltip="The amount of capital gains that is taxed at the capital gains tax rate."
                          values={capitalGains.map(e => `$${e.toLocaleString()}`)}
                        />
                        <ResultsRow
                          label="Capital Gains Tax Rate"
                          tooltip="The tax rate applied to the capital gains amount. If the capital gains tax rate
                                   is negative the short term tax rate is used because you may be able to
                                   write off losses from your ordinary income."
                          values={capitalGainsTaxRate.map(e => `${e.toFixed(2)}%`)}
                        />
                        <ResultsRow
                          label="Capital Gains Tax"
                          tooltip="The calculated amount of tax on the capital gains amount."
                          values={capitalGainsTax.map(e => `$${e.toLocaleString()}`)}
                        />
                        <ResultsRow
                          border
                          label="Tax Rate of Profit"
                          tooltip="The percentage of potential tax owed on the gross profit amount."
                          values={taxRateOfProfit.map(e => `${e.toFixed(2)}%`)}
                        />
                      </>}
                      <ResultsRow
                        label="Net Proceeds"
                        tooltip="The amount of money you received from the sale of your stock position after deducting
                                 potential taxes that may be owed."
                        values={netProceeds.map(e => `$${e.toLocaleString()}`)}
                      />
                      <ResultsRow
                        label="Net Profit"
                        tooltip="The amount of money you earned from the sale of your stock position after deducting
                                 potential taxes that may be owed."
                        values={netProfit.map(e => `$${e.toLocaleString()}`)}
                      />
                    </mui.TableBody>
                  </mui.Table>
                </mui.TableContainer>
              </mui.Stack>
            )}
            </mui.Grid>
          </mui.Grid>
        </Form>
      )}
    </Formik>
  )
}