import { createSelectorHooks } from 'auto-zustand-selectors-hook'
import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'

interface StateStore {
  statesList: string[]
  addStates: (newStates: string[]) => void
  clearStates: () => void
}

export const useStatesStore = create<StateStore>()(
  devtools(
    persist(
      (set) => ({
        statesList: [],
        addStates: (newStates) =>
          set((state) => ({
            statesList: [...state.statesList, ...newStates],
          })),
        clearStates: () => set({ statesList: [] }),
      }),
      {
        name: 'states-store',
      },
    ),
  ),
)

export const useStatesSelector = createSelectorHooks(useStatesStore)
