import { Grid, Link, Typography } from '@mui/material'

function MainFooter() {
  return (
    <Grid sx={{ background: '#fff' }}>
      <Grid
        container
        sx={{ p: { xs: '1em', md: '4em', lg: '4em' }, background: '#4F4F4F', bottom: '0' }}
      >
        <Grid item md={6}>
          <Typography color='#ccc' fontWeight='200' fontSize='1em'>
            © Rooted Lending Inc. dba Benny
          </Typography>
          <Link href='mailto:support@heybenny.com' sx={{ textDecoration: 'none' }}>
            <Typography color='white' fontWeight='200' fontSize='1em'>
              support@heybenny.com
            </Typography>
          </Link>
          <br />
          <br />
          <Typography color='#ccc' fontWeight='100' fontSize='1em'>
            The information provided on this website is intended for informational purposes only and
            does not represent an offer or solicitation to buy or sell any security. Further, the
            illustrative examples provided herein are not intended to guarantee future performance
            of any specific investment. Rooted Lending, Inc. does not provide financial advisory
            services and all prospective clients are encouraged to consult with their independent
            financial adviser before deciding whether ESPP participation is right for them.
          </Typography>
          <br />
          <Typography color='#ccc' fontWeight='100' fontSize='1em'>
            All product and company names are trademarks ™ or registered ® trademarks of their
            respective holders. Use of them does not imply any affiliation with or endorsement by
            them.
          </Typography>
        </Grid>
        <Grid
          item
          md={6}
          sx={{
            textAlign: { xs: 'left', md: 'right', lg: 'right' },
            my: { xs: '1em', md: 'auto', lg: 'auto' },
          }}
          my='auto'
          color='white'
        >
          <Link
            href='https://www.instagram.com/earnbenny/'
            target='_blank'
            sx={{ textDecoration: 'none' }}
          >
            <Typography color='white' fontWeight='200' fontSize='1em'>
              Instagram
            </Typography>
          </Link>
          <Link
            href='https://twitter.com/EarnBenny'
            target='_blank'
            sx={{ textDecoration: 'none' }}
          >
            <Typography color='white' fontWeight='200' fontSize='1em'>
              Twitter
            </Typography>
          </Link>
          <Link href='https://heybenny.com/terms' target='_blank' sx={{ textDecoration: 'none' }}>
            <Typography color='white' fontWeight='200' fontSize='1em'>
              Terms of Use
            </Typography>
          </Link>
          <Link href='https://heybenny.com/privacy' target='_blank' sx={{ textDecoration: 'none' }}>
            <Typography color='white' fontWeight='200' fontSize='1em'>
              Privacy Policy
            </Typography>
          </Link>
          <br />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default function Footer() {
  return <MainFooter />
}
