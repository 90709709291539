import { Grid, Typography } from '@mui/material'
import { Logos } from '../../../../Components/Logos'

export const TrustedByEmployees = () => {
  return (
    <Grid
      container
      flexDirection={'column'}
      bgcolor={'#F6FBF9'}
      py={'40px'}
      justifyContent={'center'}
      alignItems={'center'}
      gap={'48px'}
    >
      <Grid>
        <Typography
          color={'#194339'}
          fontSize={{ xs: '21px', md: '40px' }}
          letterSpacing={-1}
          fontFamily={'Garnett-Medium'}
          textAlign={'center'}
        >
          Trusted by employees of
        </Typography>
      </Grid>

      <Grid container maxWidth={'1000px'} className='images-container'>
        <Logos />
      </Grid>

      <Grid>
        <Typography textAlign={'center'} fontSize={{ xs: '18px', md: '21px' }} color={'#242424'}>
          + 30 more companies.
        </Typography>
      </Grid>
    </Grid>
  )
}
