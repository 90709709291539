import MenuIcon from '@mui/icons-material/Menu'
import * as mui from '@mui/material'
import { useState } from 'react'
import logo from '../../../media/images/logos/whitelogo.svg'

interface NavLinkProps {
  to: string
  label: string
}

const navList: NavLinkProps[] = [
  { to: '/', label: 'HOME' },
  { to: '/about-us', label: 'ABOUT' },
  { to: '/espp-calculator', label: 'ESPP CALCULATOR' },
]

export default function WaitlistHeader() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return
    }

    setIsDrawerOpen(open)
  }

  return (
    <mui.Box>
      <mui.AppBar position='static' color='transparent' elevation={0}>
        <mui.Container maxWidth='lg'>
          <mui.Toolbar
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
          >
            <mui.IconButton
              edge='start'
              aria-label='menu'
              onClick={toggleDrawer(true)}
              sx={{ mr: 2, color: '#fff' }}
            >
              <MenuIcon fontSize='large' />
            </mui.IconButton>

            <img src={logo} width='auto' height='auto' alt='Rooted' loading='lazy' />
          </mui.Toolbar>
        </mui.Container>
      </mui.AppBar>
      <mui.Drawer anchor='left' open={isDrawerOpen} onClose={toggleDrawer(false)}>
        <mui.Box sx={{ width: 250 }} role='presentation' onClick={toggleDrawer(false)}>
          <mui.List>
            {navList.map((item, index) => (
              <mui.ListItem key={index} disablePadding>
                <mui.ListItemButton component={mui.Link} href={item.to}>
                  <mui.ListItemText primary={item.label} />
                </mui.ListItemButton>
              </mui.ListItem>
            ))}
          </mui.List>
        </mui.Box>
      </mui.Drawer>
    </mui.Box>
  )
}
