import { Alert, Box } from '@mui/material'
import { useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { LoadingDots } from '../../Components/LoadingDots'
import { bennyApi } from '../../utils/bennyApi'

export const VerifyApplication = () => {
  const { applicationId } = useParams()
  const [searchParams] = useSearchParams()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [success, setSuccess] = useState(false)
  const code = searchParams.get('code')

  useEffect(() => {
    const verifyCode = async () => {
      if (code && applicationId) {
        setLoading(true)
        setError('')

        try {
          const response = await bennyApi.put(`/espp/verification_email/${applicationId}/`, {
            code,
          })
          console.log('Verification success:', response.data)
          setSuccess(true)

          setTimeout(() => {
            window.close()
          }, 2000)
        } catch (err) {
          console.error('Verification error:', err)
          setError('Invalid verification code.')
        } finally {
          setLoading(false)
        }
      }
    }

    verifyCode()
  }, [code, applicationId])

  if (loading) {
    return (
      <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
        <LoadingDots />
      </Box>
    )
  }

  if (error) {
    return <Alert severity='error'>{error}</Alert>
  }

  if (success) {
    return <Alert severity='success'>Email verified successfully!</Alert>
  }

  return null
}
