import * as mui from '@mui/material'
import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-mui'
import { DatePicker } from 'formik-mui-x-date-pickers'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import React from 'react'
import * as Yup from 'yup'

import { LabelVertical } from '../../../Components/LabelVertical'
import { useEventClick } from '../../../hooks/tracker'
import { ResultsItem } from '../ResultsItem'
import { SymbolField } from '../SymbolField'

const integerMsg = 'Must be an integer'
const nonNegativeMsg = 'Must be non-negative'
const positiveMsg = 'Must be positive'
const requiredMsg = 'Required'

const validationSchema = Yup.object().shape({
  symbol: Yup.string().required(requiredMsg),
  offeringDate: Yup.date().required(requiredMsg),
  purchaseDate: Yup.date().required(requiredMsg),
  offeringDateFmv: Yup.number().required(requiredMsg).positive(positiveMsg),
  contributionAmount: Yup.number().required(requiredMsg).positive(positiveMsg).integer(integerMsg),
  irsLimit: Yup.number().required(requiredMsg).positive(positiveMsg).integer(integerMsg),
  discount: Yup.number().required(requiredMsg).positive(positiveMsg).integer(integerMsg),
  sharesLimit: Yup.number().required(requiredMsg).positive(positiveMsg).integer(integerMsg),
  lookbackPeriod: Yup.number().min(0, nonNegativeMsg).integer(integerMsg),
  purchasePeriod: Yup.number().min(0, nonNegativeMsg).integer(integerMsg),
  sharePrice: Yup.number().required(requiredMsg).positive(positiveMsg),
})

export function EsppCalculator() {
  const [isSubmitted, setIsSubmitted] = React.useState(false)
  const [sharesPurchased, setSharesPurchased] = React.useState(0)
  const [marketValue, setMarketValue] = React.useState(0)
  const [investedAmount, setInvestedAmount] = React.useState(0)
  const [cashAmount, setCashAmount] = React.useState(0)
  const [investedGain, setInvestedGain] = React.useState(0)
  // const [totalGain, setTotalGain] = React.useState(0)
  // const [annualRate, setAnnualRate] = React.useState(0)
  // const [remainingIrsLimit, setRemainingIrsLimit] = React.useState(0)

  const { createEvent } = useEventClick()

  const chartConfig = {
    chart: {
      type: 'spline',
    },
    title: { text: '' },
    xAxis: {
      type: 'datetime',
      labels: {
        formatter: function () {
          const date = Highcharts.dateFormat('%Y-%m-%d', this.value)
          // Custom label mapping
          const customLabels = {
            '2022-01-30': 'Start',
            '2022-01-31': '',
            '2023-02-05': '',
            '2023-02-06': 'End',
          }
          return customLabels[date]
        },
      },
    },
    yAxis: {
      title: { text: '' },
      labels: {
        format: '${value}',
        style: { color: '#000' },
      },
    },
    series: [
      {
        name: 'Purple Line',
        data: [
          [Date.UTC(2022, 0, 30), 100],
          [Date.UTC(2022, 0, 31), 100],
          [Date.UTC(2023, 1, 5), 100],
          [Date.UTC(2023, 1, 6), 100],
        ],
        color: 'purple',
        lineWidth: 2,
      },
      {
        name: 'Blue Line',
        data: [
          [Date.UTC(2022, 0, 30), 50],
          [Date.UTC(2022, 0, 31), 50],
          [Date.UTC(2023, 1, 5), 50],
          [Date.UTC(2023, 1, 6), 50],
        ],
        color: 'blue',
        lineWidth: 2,
      },
      {
        name: 'Orange Line',
        data: [
          [Date.UTC(2022, 0, 30), 50],
          [Date.UTC(2022, 0, 31), 50], // First midpoint
          [Date.UTC(2023, 1, 5), 100], // Second midpoint
          [Date.UTC(2023, 1, 6), 100],
        ],
        color: 'orange',
        lineWidth: 2,
      },
      {
        name: 'Green Line',
        data: [
          [Date.UTC(2022, 0, 30), 200],
          [Date.UTC(2022, 0, 31), 200], // First midpoint
          [Date.UTC(2023, 1, 5), 100], // Second midpoint
          [Date.UTC(2023, 1, 6), 100],
        ],
        color: 'green',
        lineWidth: 2,
      },
    ],
    legend: { enabled: false },
    credits: { enabled: false },
    plotOptions: {
      series: {
        marker: {
          states: {
            hover: { enabled: false }, // Disables markers on hover
          },
        },
        states: {
          hover: { enabled: false },
          inactive: { opacity: 1 }, // Ensures the opacity is not reduced for inactive series.
        },
      },
    },
    tooltip: { enabled: false },
  }

  const pieChartConfig = {
    chart: {
      type: 'pie',
    },
    title: {
      text: '',
    },
    tooltip: {
      pointFormat: '${point.y:,.0f}',
    },
    accessibility: {
      point: {
        // valueSuffix: '%',
        valuePrefix: '$',
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '{point.name}: {point.percentage:.1f}%',
        },
        showInLegend: false,
      },
    },
    series: [
      {
        name: 'Share',
        colorByPoint: true,
        data: [
          {
            name: 'Invested amount',
            y: investedAmount,
            sliced: true,
            selected: true,
          },
          {
            name: 'Cash amount',
            y: cashAmount,
          },
          {
            name: 'Invested Gain',
            y: investedGain,
          },
        ],
      },
    ],
    credits: { enabled: false },
  }

  return (
    <Formik
      initialValues={{
        symbol: '',
        offeringDate: new Date(),
        purchaseDate: new Date(),
        offeringDateFmv: 0,
        contributionAmount: 10000,
        irsLimit: 25000,
        discount: 0,
        sharesLimit: 2000,
        lookbackPeriod: 0,
        purchasePeriod: 0,
        sharePrice: 1,
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        // TODO
        const _latestFmv = 100

        const _discountedPrice = values.offeringDateFmv * (1 - values.discount / 100)

        const _sharesPurchased = Math.min(
          Math.floor(values.contributionAmount / _discountedPrice),
          values.sharesLimit,
        )
        const _marketValue = _sharesPurchased * values.sharePrice
        const _investedAmount = _sharesPurchased * _discountedPrice
        const _cashAmount = values.contributionAmount - _investedAmount
        const _investedGain = _marketValue - _investedAmount

        setSharesPurchased(_sharesPurchased)
        setMarketValue(_marketValue)
        setInvestedAmount(_investedAmount)
        setCashAmount(_cashAmount)
        setInvestedGain(_investedGain)
        // setTotalGain()
        // setAnnualRate()
        // setRemainingIrsLimit()
        setIsSubmitted(true)
        setSubmitting(false)
      }}
    >
      {({ submitForm, isSubmitting, touched, errors }) => (
        <Form style={{ width: '100%' }}>
          <mui.Grid item container xs={12}>
            <mui.Grid item xs={12} md={4}>
              <mui.Stack spacing={3}>
                <LabelVertical
                  field={
                    <SymbolField
                      discountFields={['discount']}
                      error={touched.symbol && !!errors.symbol}
                      helperText={touched.symbol && errors.symbol}
                      lookbackPeriodFields={['lookbackPeriod']}
                      priceFields={['offeringDateFmv', 'sharePrice']}
                      purchasePeriodFields={['purchasePeriod']}
                    />
                  }
                  label='Stock symbol'
                />
                <LabelVertical
                  field={
                    <Field
                      component={DatePicker}
                      error={touched.offeringDate && !!errors.offeringDate}
                      helperText={touched.offeringDate && errors.offeringDate}
                      InputProps={{ size: 'small' }}
                      name='offeringDate'
                    />
                  }
                  label='Offering date'
                />
                <LabelVertical
                  field={
                    <Field
                      component={DatePicker}
                      error={touched.purchaseDate && !!errors.purchaseDate}
                      helperText={touched.purchaseDate && errors.purchaseDate}
                      InputProps={{ size: 'small' }}
                      name='purchaseDate'
                    />
                  }
                  label='Purchase date'
                />
                <LabelVertical
                  field={
                    <Field
                      component={TextField}
                      error={touched.offeringDateFmv && !!errors.offeringDateFmv}
                      helperText={touched.offeringDateFmv && errors.offeringDateFmv}
                      InputProps={{
                        size: 'small',
                        startAdornment: <mui.InputAdornment position='start'>$</mui.InputAdornment>,
                      }}
                      name='offeringDateFmv'
                      type='number'
                    />
                  }
                  label='Offering date FMV'
                />
                <LabelVertical
                  field={
                    <Field
                      component={TextField}
                      error={touched.contributionAmount && !!errors.contributionAmount}
                      helperText={touched.contributionAmount && errors.contributionAmount}
                      InputProps={{
                        size: 'small',
                        startAdornment: <mui.InputAdornment position='start'>$</mui.InputAdornment>,
                      }}
                      name='contributionAmount'
                      type='number'
                    />
                  }
                  label='Contribution amount'
                />
                <LabelVertical
                  field={
                    <Field
                      component={TextField}
                      error={touched.irsLimit && !!errors.irsLimit}
                      helperText={touched.irsLimit && errors.irsLimit}
                      InputProps={{
                        size: 'small',
                        startAdornment: <mui.InputAdornment position='start'>$</mui.InputAdornment>,
                      }}
                      name='irsLimit'
                      type='number'
                    />
                  }
                  label='IRS limit'
                />
                <LabelVertical
                  field={
                    <Field
                      component={TextField}
                      error={touched.discount && !!errors.discount}
                      helperText={touched.discount && errors.discount}
                      InputProps={{
                        size: 'small',
                        endAdornment: <mui.InputAdornment position='end'>%</mui.InputAdornment>,
                      }}
                      name='discount'
                      type='number'
                    />
                  }
                  label='Discount (%)'
                />
                <LabelVertical
                  field={
                    <Field
                      component={TextField}
                      error={touched.sharesLimit && !!errors.sharesLimit}
                      helperText={touched.sharesLimit && errors.sharesLimit}
                      name='sharesLimit'
                      type='number'
                    />
                  }
                  label='Shares limit'
                />
                <LabelVertical
                  field={
                    <Field
                      component={TextField}
                      error={touched.lookbackPeriod && !!errors.lookbackPeriod}
                      helperText={touched.lookbackPeriod && errors.lookbackPeriod}
                      name='lookbackPeriod'
                      placeholder='6'
                      type='number'
                    />
                  }
                  label='Lookback period (months)'
                />
                <LabelVertical
                  field={
                    <Field
                      component={TextField}
                      error={touched.purchasePeriod && !!errors.purchasePeriod}
                      helperText={touched.purchasePeriod && errors.purchasePeriod}
                      name='purchasePeriod'
                      placeholder='6'
                      type='number'
                    />
                  }
                  label='Purchase period (months)'
                />
                <mui.Button
                  disabled={isSubmitting}
                  onClick={() => {
                    createEvent({ name: 'EsppCalculatorSubmit' })
                    return submitForm()
                  }}
                  variant='contained'
                >
                  Submit
                </mui.Button>
              </mui.Stack>
            </mui.Grid>
            <mui.Grid item xs={12} md={8}>
              {isSubmitted && (
                <mui.Stack spacing={3} pl={10}>
                  <HighchartsReact highcharts={Highcharts} options={chartConfig} />
                  <HighchartsReact highcharts={Highcharts} options={pieChartConfig} />
                  <LabelVertical
                    field={
                      <Field
                        component={TextField}
                        error={touched.sharePrice && !!errors.sharePrice}
                        helperText={touched.sharePrice && errors.sharePrice}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          size: 'small',
                          startAdornment: (
                            <mui.InputAdornment position='start'>$</mui.InputAdornment>
                          ),
                        }}
                        name='sharePrice'
                        type='number'
                      />
                    }
                    label='Share price'
                  />
                  <ResultsItem label='Shares Purchased' value={sharesPurchased.toLocaleString()} />
                  <ResultsItem
                    label='Market Value'
                    value={`$${marketValue.toLocaleString()}`}
                    tooltip='The value of shares after being purchased on the purchase date.'
                  />
                  <ResultsItem
                    label='Invested Amount'
                    value={`$${investedAmount.toLocaleString()}`}
                    tooltip='The amount of the contribution that purchased shares.'
                  />
                  <ResultsItem
                    label='Cash Amount'
                    value={`$${cashAmount.toLocaleString()}`}
                    tooltip='The amount of the contribution leftover after purchasing shares.'
                  />
                  <ResultsItem
                    label='Invested Gain'
                    value={`$${investedGain.toLocaleString()} (${(
                      (investedGain / investedAmount) *
                      100
                    ).toFixed(2)}%)`}
                    tooltip='The amount of gain of purchased shares over the invested amount.'
                  />
                  {/*<ResultsItem label="Total Gain" value="$0.00 (0.00%)" tooltip="Total gain including cash." />*/}
                  {/*<ResultsItem label="Annual Rate Approximation" value="N/A" tooltip="Estimated annual return rate." />*/}
                  {/*<ResultsItem label="Remaining IRS Limit" value="$25,000.00" tooltip="Remaining limit for tax purposes." />*/}
                </mui.Stack>
              )}
            </mui.Grid>
          </mui.Grid>
        </Form>
      )}
    </Formik>
  )
}
