import React from 'react'

import { userContext } from 'context/user'
import { Login } from 'screens/auth/Login'
import { User } from 'types/graphql/schema'
import { Account } from '../account'
import { useGetCustomerApplications } from '../account/useGetCustomerApplications'

export function Applications() {
  const user = React.useContext(userContext)

  if (user.spinner) return user.spinner
  if (!user.data) return <Login />

  return <$Account user={user.data} />
}

const $Account = ({ user }: { user: User }) => {
  const { account, isLoading } = useGetCustomerApplications(user?.email, user?.client?.id)

  if (isLoading) return null

  return <Account account={account} name={user?.client?.name} email={user?.email} />
}
