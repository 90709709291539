import { Alert, Box } from '@mui/material'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { verifyClientEmail } from '../../api/authService'
import { LoadingDots } from '../../Components/LoadingDots'
import { useClientSelector } from '../../store/client'

export const VerifyClient = () => {
  const [searchParams] = useSearchParams()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [success, setSuccess] = useState(false)
  const code = searchParams.get('code')
  const email = searchParams.get('email')

  const setClient = useClientSelector.useSetClient()
  const client = useClientSelector((state) => state.client)

  useEffect(() => {
    const verifyCode = async () => {
      if (code && email) {
        setLoading(true)
        setError('')

        try {
          const response = await verifyClientEmail({ email, code })
          console.log('Verification success:', response.client)
          setSuccess(true)
          if (response.client) setClient(response.client)

          setTimeout(() => {
            window.close()
          }, 2000)
        } catch (err) {
          console.error('Verification error:', JSON.stringify(err))
          setError('Invalid verification code.')
        } finally {
          setLoading(false)
        }
      }
    }

    if (client && client.email === email) {
      verifyCode()
    }
  }, [code, email, client?.email])

  if (loading) {
    return (
      <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
        <LoadingDots />
      </Box>
    )
  }

  if (!client) {
    return <Alert severity='warning'>Make sure you are authenticated</Alert>
  }

  if (client.email !== email) {
    return (
      <Alert severity='warning'>
        Authentication error: The email in the URL does not match your current session. Please
        verify that you are accessing the correct link or log in with the account corresponding to
        the email.
      </Alert>
    )
  }

  if (error) {
    return <Alert severity='error'>{error}</Alert>
  }

  if (success) {
    return <Alert severity='success'>Email verified successfully!</Alert>
  }

  return null
}
