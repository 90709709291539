import React from 'react'
import { Route, Routes } from 'react-router-dom'

import { userContext } from 'context/user'
import { Login } from 'screens/auth/Login'

import { Applications } from './Applications'

export function CustomerPortal() {
  const user = React.useContext(userContext)

  if (user.spinner) return user.spinner
  if (!user.data) return <Login />

  return (
    <Routes>
      <Route path='applications' element={<Applications />} />
    </Routes>
  )
}
