import PropTypes from 'prop-types'
import './styles.css'

function YoutubeEmbed({ embedId }) {
  return (
    <div className='video-responsive' style={{ textAlign: 'center' }}>
      <iframe
        src={`https://www.youtube.com/embed/${embedId}`}
        frameBorder='0'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
        allowFullScreen
        title='ESPP'
      />
    </div>
  )
}

YoutubeEmbed.propTypes = {
  embedId: PropTypes.string.isRequired,
}

export default YoutubeEmbed
