import CloseIcon from '@mui/icons-material/Close'
import {
  Box,
  Button,
  Collapse,
  Container,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { useEffect, useState } from 'react'

import TocIcon from '@mui/icons-material/Toc'
import { GradientTypography } from 'Components/gradientTypography'
import { Typography } from 'Components/Typography'
import { formatToDollars } from 'utils/commons'
import { ICustomerApplications } from '../useGetCustomerApplications'

function formatDate(date: string) {
  return new Date(date).toLocaleDateString('en-US', {
    timeZone: 'UTC',
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  })
}

function createData(date: string, amount: string) {
  return {
    date: formatDate(date),
    amount: formatToDollars(Number(amount), 2),
  }
}

function createOptionsSelect(aplications: ICustomerApplications['applications']) {
  return aplications.map((item) => {
    const name = item?.application?.espp?.company?.name
    const startDAte = formatDate(item?.application?.start_date)
    const endDAte = formatDate(item?.application?.end_date)
    const option = `${name} ESPP (${startDAte} - ${endDAte})`
    return { key: option, value: item?.application?.id }
  })
}

interface BennyLoanProps {
  applications: ICustomerApplications['applications']
}

export const BennyLoan = ({ applications = [] }: BennyLoanProps) => {
  const [currentApplication, setCurrentApplication] =
    useState<ICustomerApplications['applications'][0]>()

  const [showSchedule, setShowSchedule] = useState(false)

  const _handleChange = () => {
    setShowSchedule((prev) => !prev)
  }

  const rows = (currentApplication?.disbursements ?? [])?.map((disbursement) => ({
    ...createData(disbursement.disbursement_date, disbursement.disbursement_amount),
  }))

  const loanAmount = Number(currentApplication?.application?.loan_amount)
  const apr = Number(currentApplication?.application?.interest_rate)
  const aprPorcent = apr * 100

  const financeCharges = currentApplication?.bennyFee
    ? currentApplication?.bennyFee
    : ((loanAmount / 2) * apr) /
      (12 / currentApplication?.application?.espp?.purchase_period_length)

  const Repayment = loanAmount + financeCharges

  const dueDate = new Date(currentApplication?.application?.due_date).toLocaleDateString('en-US', {
    timeZone: 'UTC',
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  })

  useEffect(() => {
    if (applications.length > 0) {
      setCurrentApplication(applications[0])
    }
  }, [applications])

  const handleChange = (event: SelectChangeEvent) => {
    const applicationId = event.target.value
    const selectedApplication = applications.find((app) => app?.application?.id === applicationId)
    setCurrentApplication(selectedApplication || applications[0])
  }

  return (
    <Grid container position={'relative'}>
      <Container
        maxWidth='xl'
        sx={{
          transform: { xs: 'none', md: 'translateY(-40px)' },
        }}
      >
        <Grid
          container
          bgcolor={'#ffffff'}
          borderRadius={{ xs: 0, md: '8px' }}
          px={{ xs: 0, md: '50px' }}
          py={{ xs: '20px', md: '64px' }}
          sx={{
            boxShadow: { md: '0px 0px 24px 0px rgba(0, 0, 0, 0.08)' },
            borderBottom: { md: '1px solid #E9E9E9' },
          }}
        >
          <Grid container borderBottom={'1px solid #A7A7A7'} justifyContent={'space-between'}>
            <GradientTypography
              sx={{ fontSize: '24px', pb: '24px' }}
              borderBottom={'2px solid #1D1D1F'}
            >
              Your Benny Loan
            </GradientTypography>

            {currentApplication?.application?.id && (
              <Box sx={{ minWidth: { xs: '100%', md: '200px' } }}>
                <FormControl fullWidth variant='standard'>
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    value={currentApplication?.application?.id}
                    label={''}
                    onChange={handleChange}
                    sx={{
                      '& .MuiInput-underline:before': {
                        borderBottom: 'none',
                      },
                      '&:hover .MuiInput-underline:before': {
                        borderBottom: 'none',
                      },
                      '& .MuiInput-underline:after': {
                        borderBottom: 'none',
                      },
                    }}
                  >
                    {createOptionsSelect(applications).map((item, idx) => (
                      <MenuItem key={idx} value={item.value}>
                        {item.key}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            )}
          </Grid>

          <Grid container>
            {applications.length > 0 && (
              <>
                <Box sx={{ flexGrow: 1, my: '24px' }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                      <Box bgcolor={'#F6FBF9'} p={'32px'}>
                        <Typography variant='h2' inheritColor>
                          {formatToDollars(loanAmount, 2)}
                        </Typography>
                        <Typography inheritColor variant='body2'>
                          Loan Amount
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <Box bgcolor={'#F6FBF9'} p={'32px'}>
                        <Typography variant='h2' inheritColor>
                          {formatToDollars(financeCharges, 2)}
                        </Typography>
                        <Typography inheritColor variant='body2'>
                          Finance Charges ({aprPorcent.toFixed(2)}% APR)
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <Box bgcolor={'#F6FBF9'} p={'32px'}>
                        <Typography variant='h2' inheritColor>
                          {formatToDollars(Repayment, 2)}
                        </Typography>
                        <Typography inheritColor variant='body2'>
                          Repayment Due on {dueDate}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>

                <Grid container>
                  {!showSchedule && (
                    <Typography
                      inheritColor
                      variant='body2'
                      sx={{ color: '#9747FF' }}
                      onClick={_handleChange}
                    >
                      View Disbursement Schedule
                    </Typography>
                  )}

                  <Box width={'100%'}>
                    <Collapse in={showSchedule}>
                      <Grid container justifyContent={'space-between'}>
                        <Typography inheritColor>Disbursement Schedule</Typography>
                        <Button onClick={_handleChange}>
                          <CloseIcon sx={{ color: '#9747FF' }} />
                        </Button>
                      </Grid>

                      <TableContainer component={Paper}>
                        <Table aria-label='simple table' sx={{ bgcolor: '#FFFFFF' }}>
                          <TableHead sx={{ bgcolor: '#F7F6FB' }}>
                            <TableRow>
                              <TableCell>
                                <TocIcon />
                              </TableCell>
                              <TableCell align='center'>Date</TableCell>
                              <TableCell align='center'>Deposit</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {rows.map((row, idx) => (
                              <TableRow
                                key={idx}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                              >
                                <TableCell component='th' scope='row'>
                                  {idx + 1}
                                </TableCell>
                                <TableCell align='center'>{row.date}</TableCell>
                                <TableCell align='center'>{row.amount}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Collapse>
                  </Box>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Container>
    </Grid>
  )
}
