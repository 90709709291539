import * as mui from '@mui/material'
import { Field, Form, Formik } from 'formik'
import { useState } from 'react'
import * as yup from 'yup'

import { Calendly } from '../../Components/calendly'
import { CustomButton } from '../../Components/CustomButton'
import { CustomTextAreaField, CustomTextField } from '../../Components/Fields'
import { Layout } from '../../Components/Layout'
import { CustomText } from '../../Components/Text'
import { bennyApi } from '../../utils/bennyApi'
import { Typography } from '../../Components/Typography'

const schema = yup.object({
  name: yup.string().required('Name is required'),
  email: yup.string().email('Enter a valid email').required('Email is required'),
  message: yup.string().required('Message is required'),
})

type SchemaType = yup.InferType<typeof schema>

export const ContactUs = () => {
  const [sent, setSent] = useState<boolean>(false)

  const onSubmit = async (values: SchemaType, { resetForm }) => {
    try {
      const response = await bennyApi.post<{ contact_form_status: 'succeed' }>(
        '/espp/contactform/',
        { ...values },
      )

      if (response.data.contact_form_status === 'succeed') {
        setSent(true)
        resetForm()
      }
    } catch (error) {
      console.error(`[Contact us]:`, error)
    }
  }

  return (
    <Layout extraQueryParams={{ s: 'nav_get_started' }}>
      <mui.Grid
        container
        p={'20px'}
        bgcolor={'#60CA95'}
        justifyContent={'center'}
        alignItems={'center'}
        display={sent ? 'block' : 'none'}
      >
        <mui.Typography
          textAlign={'center'}
          fontSize={'20px'}
          fontStyle={'normal'}
          lineHeight={'normal'}
          fontWeight={'400'}
          color={'#194339'}
        >
          <strong>Awesome!</strong>
          <br />
          Thanks for contacting us. Our team will get back soon.
        </mui.Typography>
      </mui.Grid>
      <mui.Grid
        container
        item
        xs={12}
        bgcolor={'#F6FBF9'}
        py={{ xs: '32px', md: '64px' }}
        pb={{ xs: 0 }}
      >
        <mui.Grid container maxWidth={'1200px'} mx={'auto'}>
          <mui.Grid
            item
            xs={12}
            md={6}
            display={'flex'}
            flexDirection={'column'}
            justifyContent={{ xs: 'normal', md: 'center' }}
            px={{ xs: '20px', md: 0 }}
            pb={{ xs: '32px', md: 0 }}
          >
            <Typography variant='h1'>
              Contact Us
            </Typography>

            <mui.Typography
              color={'#194339'}
              fontSize={{ xs: '18px', md: '24px' }}
              fontStyle={'normal'}
              lineHeight={'normal'}
              fontWeight={'400'}
            >
              Got questions or feedback? Our support team is available to address any questions or
              feedback you may have.
            </mui.Typography>

            <mui.Box height={'8px'} />

            <mui.Typography
              color={'#194339'}
              fontSize={{ xs: '18px', md: '24px' }}
              fontStyle={'normal'}
              lineHeight={'normal'}
              fontWeight={'400'}
              sx={{ textWrap: 'balance' }}
            >
              <a
                href={`mailto:support@heybenny.com`}
                style={{ textDecoration: 'none', cursor: 'pointer', color: '#194339' }}
              >
                support@heybenny.com
              </a>
              <br />
              (608) 302-6665
            </mui.Typography>

            <mui.Box
              height={'1px'}
              bgcolor={'#60CA95'}
              my={'16px'}
              display={{ xs: 'none', md: 'block' }}
              marginRight={'32px'}
            />

            <mui.Box display={{ xs: 'none', md: 'block' }}>
              <ContactOptions />
            </mui.Box>
          </mui.Grid>

          <mui.Grid item xs={12} md={6}>
            <mui.Box
              bgcolor={'#FFFFFF'}
              p={{ xs: '20px', md: '48px' }}
              gap={'32px'}
              display={'flex'}
              flexDirection={'column'}
              alignItems={'center'}
              sx={{ boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.08)' }}
              borderRadius={{ xs: 0, md: '12px' }}
            >
              <CustomText
                customVariant='subtitle'
                sx={{ color: '#194339', fontFamily: 'Garnett-bold', fontSize: '24px' }}
              >
                Get In Touch
              </CustomText>

              <mui.Box width={'100%'}>
                <Formik<SchemaType>
                  validationSchema={schema}
                  onSubmit={onSubmit}
                  initialValues={{
                    name: '',
                    email: '',
                    message: '',
                  }}
                  validateOnMount
                  isInitialValid
                >
                  {({
                    values,
                    touched,
                    handleBlur,
                    handleChange,
                    errors,
                    isSubmitting,
                    handleSubmit,
                  }) => (
                    <mui.Box display={'flex'} flexDirection={'column'} gap={'32px'}>
                      <Form style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                        <mui.Box display={'flex'} flexDirection={'column'} gap={'16px'}>
                          <mui.Typography color={'#194339'} fontSize={'20px'}>
                            Name
                          </mui.Typography>
                          <Field
                            component={CustomTextField}
                            fullWidth
                            id='name'
                            name='name'
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.name && Boolean(errors.name)}
                            helperText={touched.name && errors.name}
                          />
                        </mui.Box>

                        <mui.Box display={'flex'} flexDirection={'column'} gap={'16px'}>
                          <mui.Typography color={'#194339'} fontSize={'20px'}>
                            Email
                          </mui.Typography>
                          <Field
                            component={CustomTextField}
                            fullWidth
                            id='email'
                            name='email'
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.email && Boolean(errors.email)}
                            helperText={touched.email && errors.email}
                          />
                        </mui.Box>

                        <mui.Box display={'flex'} flexDirection={'column'} gap={'16px'}>
                          <mui.Typography color={'#194339'} fontSize={'20px'}>
                            Message
                          </mui.Typography>
                          <Field
                            component={CustomTextAreaField}
                            fullWidth
                            id='message'
                            name='message'
                            value={values.message}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.message && Boolean(errors.message)}
                            helperText={touched.message && errors.message}
                          />
                        </mui.Box>
                      </Form>

                      <mui.Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                        <CustomButton
                          title='Submit'
                          variant={!!isSubmitting ? 'disabled' : 'primary'}
                          buttonProps={{
                            type: 'submit',
                            endIcon: !!isSubmitting && (
                              <mui.CircularProgress size={10} sx={{ color: 'white' }} />
                            ),
                            disabled: !!isSubmitting,
                          }}
                          onClick={handleSubmit}
                        />
                      </mui.Box>
                    </mui.Box>
                  )}
                </Formik>
              </mui.Box>

              <CustomText customVariant='body' sx={{ color: '#4F4F4F' }}>
                We do not spam.{' '}
                <a
                  href='https://heybenny.com/privacy'
                  target='_blanck'
                  style={{ textDecoration: 'underline', cursor: 'pointer', color: '#4F4F4F' }}
                >
                  See our privacy policy.
                </a>
              </CustomText>
            </mui.Box>
          </mui.Grid>
        </mui.Grid>
      </mui.Grid>

      <mui.Grid
        container
        item
        xs={12}
        py={'32px'}
        px={'20px'}
        bgcolor={'#F6FBF9'}
        display={{ xs: 'block', md: 'none' }}
      >
        <ContactOptions />
      </mui.Grid>

      <mui.Grid container item xs={12} py={{ xs: '32px', md: '64px' }}>
        <mui.Grid container maxWidth={'1200px'} mx={'auto'}>
          <mui.Grid container item xs={12} md={6} justifyContent={'end'}>
            <mui.Box
              borderRadius={{ xs: 0, md: '24px' }}
              overflow={'hidden'}
              width={{ xs: '100%', md: '500px' }}
              height={'350px'}
            >
              {/* <img src={map} loading='lazy' style={{ width: '100%', height: '100%' }} /> */}

              <iframe
                src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d385.26800933216407!2d-89.37482147306186!3d43.08097037514606!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880653217fccd725%3A0xe78107c9a7af1709!2sBenny!5e0!3m2!1sen!2sco!4v1708615436602!5m2!1sen!2sco'
                width='100%'
                height='100%'
                style={{ border: 0 }}
                loading='lazy'
              ></iframe>
            </mui.Box>
          </mui.Grid>

          <mui.Grid
            container
            item
            xs={12}
            md={6}
            justifyContent={'center'}
            px={{ xs: '20px', md: '32px' }}
            display={'flex'}
            flexDirection={'column'}
            gap={'32px'}
          >
            <mui.Box display={'flex'} flexDirection={'column'} gap={'8px'} mt={{ xs: '32px', md: 0 }}>
              <mui.Typography
                fontSize={{ xs: '18px', md: '24px' }}
                color={'#194339'}
                fontStyle={'normal'}
                lineHeight={'135%'}
                fontWeight={'700'}
              >
                Our Location
              </mui.Typography>

              <mui.Typography
                fontSize={{ xs: '18px', md: '24px' }}
                color={'#194339'}
                fontStyle={'normal'}
                lineHeight={'135%'}
                fontWeight={'400'}
                sx={{ textWrap: 'balance' }}
              >
                We are headquartered in lovely Madison, Wisconsin. Drop us a note if you are here.
                We would love to meet.
              </mui.Typography>
            </mui.Box>

            <mui.Typography
              fontSize={{ xs: '18px', md: '24px' }}
              color={'#194339'}
              fontStyle={'normal'}
              lineHeight={'135%'}
              fontWeight={'400'}
              sx={{ textWrap: 'balance' }}
            >
              811 E Washington Ave
              <br />
              Suite 405,
              <br />
              Madison, WI 53703
            </mui.Typography>
          </mui.Grid>
        </mui.Grid>
      </mui.Grid>
    </Layout>
  )
}

const ContactOptions = () => {
  const [open, setOpen] = useState<boolean>(false)

  const onModalClose = () => setOpen(false)

  const onModalOpen = () => setOpen(true)

  return (
    <mui.Box display={'flex'} flexDirection={'column'} gap={'32px'} marginRight={'32px'}>
      <Calendly open={open} onModalClose={onModalClose} />
      <mui.Box display={'flex'} flexDirection={'column'} gap={'8px'}>
        <mui.Typography
          fontSize={{ xs: '18px', md: '24px' }}
          fontWeight={'700'}
          fontStyle={'normal'}
          lineHeight={'normal'}
          color={'#194339'}
        >
          Schedule A Free Consult
        </mui.Typography>
        <mui.Typography
          fontSize={{ xs: '18px', md: '24px' }}
          fontWeight={'400'}
          fontStyle={'normal'}
          lineHeight={'normal'}
          color={'#194339'}
        >
          Interested in chatting about your ESPP with an expert?{' '}
          <a style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={onModalOpen}>
            Schedule a free consult today
          </a>
          .
        </mui.Typography>
      </mui.Box>

      <mui.Box display={'flex'} flexDirection={'column'} gap={'8px'}>
        <mui.Typography
          fontSize={{ xs: '18px', md: '24px' }}
          fontWeight={'700'}
          fontStyle={'normal'}
          lineHeight={'normal'}
          color={'#194339'}
        >
          Media Inquiries
        </mui.Typography>
        <mui.Typography
          fontSize={{ xs: '18px', md: '24px' }}
          fontWeight={'400'}
          fontStyle={'normal'}
          lineHeight={'normal'}
          color={'#194339'}
        >
          For media-related or press inquiries, please contact us at{' '}
          <a
            href={`mailto:media@heybenny.com`}
            style={{ textDecoration: 'underline', cursor: 'pointer', color: '#194339' }}
          >
            media@heybenny.com
          </a>
        </mui.Typography>
      </mui.Box>
    </mui.Box>
  )
}
