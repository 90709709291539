import { ReactNode } from 'react'
import { useEventNavigation } from '../../hooks/tracker'
import { Intercom } from '../Intercom'

interface VisitTrackerProps {
  children: ReactNode
}

export const VisitTracker = ({ children }: VisitTrackerProps) => {
  //useTracker()
  useEventNavigation()
  return (
    <>
      {children}
      <Intercom appID={'oe21f2m1'} />
    </>
  )
}
