import EastIcon from '@mui/icons-material/East'
import { Box, Button, Grid } from '@mui/material'
import { useState } from 'react'
import { PopupModal } from 'react-calendly'
import { GradientTypography } from '../../../../Components/gradientTypography'
import { Typography } from '../../../../Components/Typography'
import { Espp } from '../../../../types'
import { formatToDollars, monthDescriptions } from '../../../../utils/commons'

interface EsppPotentialProps {
  espp: Espp
  bennyFund: number
  esppGain: number
  sharesWorth: number
  onHandled: () => void
}

export const EsppPotential = ({
  espp,
  bennyFund,
  esppGain,
  sharesWorth,
  onHandled,
}: EsppPotentialProps) => {
  const [showCalendly, setShowcalendly] = useState<boolean>(false)

  const purchasePeriodLength = espp?.purchase_period_length
  const discount = Number(espp?.discount) * 100
  const companyNme = espp?.company?.name

  return (
    <Grid container>
      <Grid container pb={'32px'} borderBottom={'1px solid #E9E9E9'}>
        <Grid container flexDirection={'column'}>
          <GradientTypography>Your ESPP Potential</GradientTypography>

          <Grid container>
            <Grid
              container
              item
              xs={12}
              md={6}
              py={'12px'}
              gap={'24px'}
              pr={'16px'}
              alignItems={'center'}
            >
              <Typography inheritColor variant='h1'>
                You can gain {formatToDollars(esppGain)} every {purchasePeriodLength} months.
              </Typography>

              <Typography inheritColor>
                This is from the {discount}% discount alone. This doesn't include any gain from the
                lookback, if applicable.
              </Typography>
            </Grid>

            <Grid
              container
              item
              xs={12}
              md={6}
              pl={{ xs: 0, md: '16px' }}
              gap={{ xs: '10px', md: 0 }}
              alignItems={'center'}
            >
              <Typography inheritColor>
                {companyNme} purchases the discounted shares every{' '}
                <strong>{monthDescriptions[purchasePeriodLength]} months.</strong>
              </Typography>

              <Typography inheritColor>
                To maximize your ESPP, you can{' '}
                <strong>contribute {formatToDollars(bennyFund)}</strong> over this {monthDescriptions[purchasePeriodLength]} month period.
              </Typography>

              <Typography inheritColor>
                With those contributions, you’ll receive{' '}
                <strong>shares worth {formatToDollars(sharesWorth)}</strong>, resulting in a{' '}
                <strong>gain of {formatToDollars(esppGain)}.</strong>
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        {/* <Grid
          container
          item
          flexDirection={'column'}
          mt={'16px'}
          justifyContent={'center'}
          alignItems={'center'}
          sx={{ maxWidth: '500px', marginX: 'auto' }}
          p={{ xs: '0px', md: '16px' }}
        >
          <Typography fontSize={'17px'} textAlign={'center'}>
            Have questions?{' '}
            <a
              style={{ color: '#6F44B7', cursor: 'pointer' }}
              onClick={() => setShowcalendly(true)}
            >
              Schedule a free consult.
            </a>
          </Typography>
        </Grid> */}
      </Grid>

      <PopupModal
        url='https://calendly.com/rootedandy/15-minute-espp-consult?month=2024-01'
        rootElement={document.getElementById('root')}
        onModalClose={() => setShowcalendly(false)}
        open={showCalendly}
      />

      <Grid
        container
        py={{ xs: '32px', md: '64px' }}
        justifyContent={'center'}
        alignItems={'center'}
        maxWidth={'590px'}
        mx={'auto'}
        gap={'32px'}
      >
        <Box display={'flex'} flexDirection={'column'} gap={'12px'}>
          <Typography inheritColor>
            {formatToDollars(bennyFund)} of ESPP contributions are made via{' '}
            <strong>payroll deductions</strong>, reducing your take-home pay.
          </Typography>
          <Typography inheritColor sx={{ textWrap: 'balance' }}>
            <strong>Benny funds your contributions</strong>, allowing you to maximize your ESPP
            without reducing your take-home pay.
          </Typography>
        </Box>

        <Box
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
          gap={'32px'}
        >
          <Box>
            <Button
              variant='contained'
              endIcon={<EastIcon />}
              onClick={onHandled}
              sx={{ padding: '16px' }}
            >
              Review Benny Offer
            </Button>
          </Box>

          <Typography inheritColor sx={{ color: '#A7A7A7' }} variant='body2'>
            No Credit Checks. 30-Day Free Cancellation.
          </Typography>
        </Box>
      </Grid>
    </Grid>
  )
}
